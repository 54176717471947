$block: "react-common";

.#{$block} {

  // react pagination
  &__pagination {
    width: 100%;
    display: flex;
    justify-content: center;

    &__item {
      display: flex;
      width: pixelToRem(44);
      height: pixelToRem(44);
      border: pixelToRem(1) solid $gray-30;
      color: $grey;

      &:first-child,
      &:last-child {
        border: none;
      }

      &:nth-child(n + 1) {
        border-left: none;
      }

      &:nth-child(2) {
        border-left: pixelToRem(1) solid $gray-30;
      }

      &:hover:not(.#{$block}__pagination-item--break) {
        color: $white;
        background: $blue_hover;
        cursor: pointer;

        &:first-child,
        &:last-child {
          background: none;
        }

        &:nth-child(n + 1) {
          border-color: $blue_hover;
        }
      }

      &--active {
        color: $white;
        background: $blue;

        &:nth-child(n + 1) {
          border-color: $blue;
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      font-weight: 500;
      font-size: pixelToRem(16);
      line-height: pixelToRem(24);

      &--prev-label,
      &--next-label {

        &:before {
          display: none !important;
        }

        &:after {
          color: $grey;
          font-weight: 400;
          font-size: pixelToRem(24);
          line-height: pixelToRem(24);
        }
      }

      &--prev-label {
        transform: rotate(180deg);
      }

      &--break-label {
        color: $grey-40;
      }
    }
  }

  // multiselect
  &__multiselect {
    position: relative;

    &__form-control {
      margin: 0 !important;
      width: 100% !important;

      &--text {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: space-between !important;
        gap: pixelToRem(12) !important;

        svg path {
          fill: $grey-90;
        }
      }
    }

    &__label {
      font-weight: 700 !important;
      color: $grey !important;
    }

    &__text-input {
      flex-grow: 1 !important;

      &:before,
      &:after {
        display: none !important;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: $grey-30 !important;
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $blue !important;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: $blue !important;
    }

    &__checkbox:not(.Mui-checked) {
      svg path {
        color: $grey-30 !important;
      }
    }

    &__menu-item {
      background-color: $white !important;

      &:not(:last-child) {
        border-bottom: 1px solid $grey-30 !important;
      }

      &:hover:not(:first-child),
      &:focus:not(:first-child) {
        background-color: $blue_10 !important;
      }

      &--no-items {
        min-height: pixelToRem(55) !important;
      }
    }
  }

  // toggle element with icons
  &__toggle-switch {
    display: flex;
    align-items: center;
    height: 100%;

    &__label {
      font-size: pixelToRem(16);
      font-weight: 500;
      margin-bottom: pixelToRem(10);
      color: $grey-90;
    }

    &__wrapper {
      position: relative;
      display: inline-block;
      width: pixelToRem(130);
      height: pixelToRem(40);
    }

    &__checkbox {
      opacity: 0;
      width: pixelToRem(1);
      height: pixelToRem(1);
    }

    &__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: pixelToRem(1) solid $grey-30;
      background-color: $white;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: "";
        height: pixelToRem(26);
        width: pixelToRem(52);
        bottom: pixelToRem(6);
        left: pixelToRem(12);
        transition: .4s;
        border-radius: pixelToRem(34);
        background-color: $blue;
      }
    }

    &__icon {
      position: absolute;
      width: pixelToRem(14);
      height: pixelToRem(14);
      pointer-events: none;

      &--1 {
        top: pixelToRem(13);
        left: pixelToRem(32);
        transition: 0.3s;

        svg path {
          fill: $white;
        }
      }

      &--2 {
        top: pixelToRem(13);
        right: pixelToRem(32);
        transition: 0.3s;

        svg path {
          fill: $black;
        }
      }
    }

    &__checkbox:checked + &__slider:before {
      transform: translateX(52px);
    }

    &--checked &__icon--1 {
      svg path {
        fill: $black;
      }
    }

    &--checked &__icon--2 {
      svg path {
        fill: $white;
      }
    }

    &:hover {
      .#{$block}__toggle-switch__slider {
        border-color: $blue;
      }
    }

    &__slider:focus,
    &__slider:focus-visible {
      outline-color: $blue;
    }
  }

  // radio button group
  &__radio-button-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: pixelToRem(16);

    &__wrapper {
      display: flex;
      align-items: center;

      &:hover {
        span {
          border-color: $blue;
        }
      }
    }

    &__input-wrapper {
      position: relative;
      display: inline-block;
      width: pixelToRem(24);
      height: pixelToRem(24);
      margin-right: pixelToRem(13);

      input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: pixelToRem(24);
        height: pixelToRem(24);
        background-color: $white;
        border: pixelToRem(1) solid $grey-30;
        border-radius: 50%;
        cursor: pointer;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }

        &:focus,
        &:focus-visible {
          outline-color: $blue;
        }
      }

      input:checked + span:after {
        display: block;
        top: pixelToRem(5);
        left: pixelToRem(5);
        right: pixelToRem(5);
        bottom: pixelToRem(5);
        border-radius: 50%;
        background: $blue;
      }
    }

    label {
      cursor: pointer;
      margin-left: pixelToRem(4);
    }
  }

  &__file-download {
    display: flex;
    justify-content: center;
    gap: pixelToRem(0);

    color: $blue;

    text-decoration: none;

    @include media-breakpoint-up(md) {
      gap: pixelToRem(10);
    }

    &-icon {
      position: relative;
      top: pixelToRem(2);
      font-size: pixelToRem(18);
    }
  }
}
