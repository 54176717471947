$block: 'schachbrett-klein';

.#{$block} {
    @include module-spacing;

    &--bg-gray {
        background-color: $background-grey;
    }

    &__headline {
        @include format(headline);
        font-weight: bold !important;
        margin-bottom: pixelToRem(16);

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    &__text {
        @include format(introtext);
        margin-bottom: pixelToRem(24);

        @include media-breakpoint-up(md) {
            @include h5;
        }
    }

    &__introtext {
        @include format(introtext);
        margin-bottom: pixelToRem(24);

        @include media-breakpoint-up(md) {
            @include h5;
            margin-bottom: pixelToRem(64);
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: pixelToRem(80);
        }
    }

    &__content {
        @include media-breakpoint-up(xl) {
            margin-left: pixelToRem(24);
        }
    }

    &__title {
        @include format(subline);
        font-weight: bold;
        margin-bottom: pixelToRem(8);
        margin-top: pixelToRem(8);

        @include media-breakpoint-up(md) {
            font-size: pixelToRem(23) !important;
            line-height: pixelToRem(32) !important;
            letter-spacing: pixelToRem(0.58) !important;
            margin-top: 0;
            font-weight: 500;
        }

    }

    &__row {
        margin-top: pixelToRem(40);

        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(55);
        }
    }

    &__background {
        background-color: $background-grey;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: pixelToRem(164);

        @include media-breakpoint-up(md) {
            min-height: pixelToRem(187);
        }

        @include media-breakpoint-up(xl) {
            min-height: pixelToRem(250);
        }

        img {
            height: 100%;
            max-height: pixelToRem(90);
        }
    }

    &__image {
        display: block;
        width: 100%;
    }

    &__link {
        margin-bottom: pixelToRem(24);
    }

    &__image-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        &-link {
            @include media-breakpoint-up(md) {
                margin-right: pixelToRem(24);
            }

            &+& {
                margin-top: pixelToRem(12);

                @include media-breakpoint-up(md) {
                    margin-top: 0;
                }
            }
        }

        &-image {
            display: block;
            max-width: 100%;
            height: pixelToRem(40);

        }
    }
}