@mixin font {
    font-family: 'RedHatText', Helvetica, Arial, sans-serif;
}

@mixin headline {
    font-family: 'RedHatDisplay', Helvetica, Arial, sans-serif;
}

@mixin font-thin {
    @include font;
    font-weight: 300;
}

@mixin font-thin-italic {
    @include font;
    font-weight: 300;
    font-style: italic;
}

@mixin font-light {
    @include font;
    font-weight: 300;
    font-style: normal;
}

@mixin font-light-italic {
    @include font;
    font-weight: 300;
    font-style: italic;
}

@mixin font-regular {
    @include font;
    font-weight: 400;
    font-style: normal;
}

@mixin font--italic {
    @include font;
    font-weight: 400;
    font-style: italic;
}

@mixin font-medium {
    @include font;
    font-weight: 500;
    font-style: normal;
}

@mixin font-medium-italic {
    @include font;
    font-weight: 500;
    font-style: italic;
}

@mixin font-semibold {
    @include font;
    font-weight: 600;
    font-style: normal;
}

@mixin font-semibold-italic {
    @include font;
    font-weight: 600;
    font-style: italic;
}

@mixin font-bold {
    @include font;
    font-weight: 700;
    font-style: normal;
}

@mixin font-bold-italic {
    @include font;
    font-weight: 700;
    font-style: italic;
}

@mixin headline-thin {
    @include headline;
    font-weight: 300;
}

@mixin headline-light {
    @include headline;
    font-weight: 300;
}

@mixin headline-medium {
    @include headline;
    font-weight: 500;
}

@mixin headline-bold {
    @include headline;
    font-weight: 700;
}

@mixin headline-thin-italic {
    @include headline;
    font-weight: 300;
    font-style: italic;
}

@mixin headline-light-italic {
    @include headline;
    font-weight: 300;
    font-style: italic;
}

@mixin headline-medium-italic {
    @include headline;
    font-weight: 500;
    font-style: italic;
}

@mixin headline-bold-italic {
    @include headline;
    font-weight: 700;
    font-style: italic;
}

@mixin font-icons {
    font-family: 'DKV', 'Courier New', Courier, monospace;
}

@mixin h1 {
    @include headline;
    color: $font-color;
    font-weight: 300;
    font-size: pixelToRem(30);
    line-height: pixelToRem(37);
    letter-spacing: pixelToRem(0.7);

    @include media-breakpoint-up(md) {
        font-size: pixelToRem(40);
        line-height: pixelToRem(49);
        letter-spacing: pixelToRem(1);
    }

    @include media-breakpoint-up(xl) {
        font-size: pixelToRem(48);
        line-height: pixelToRem(56);
        letter-spacing: pixelToRem(1.2);
    }

    b {
        font-weight: 800;
    }

    em {
        font-weight: 800;
    }

    i {
        font-style: italic;
    }
}

@mixin h2 {
    @include headline;
    color: $font-color;
    font-size: pixelToRem(24);
    line-height: pixelToRem(28);
    letter-spacing: pixelToRem(0.6);

    @include media-breakpoint-up(md) {
        font-size: pixelToRem(32);
        line-height: pixelToRem(40);
        letter-spacing: pixelToRem(0.8);
    }

    @include media-breakpoint-up(xl) {
        font-size: pixelToRem(36);
        line-height: pixelToRem(41);
        letter-spacing: pixelToRem(0.9);
    }

    b {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }
}

@mixin h3 {
    @include headline;
    color: $font-color;
    font-size: pixelToRem(20);
    line-height: pixelToRem(28);
    letter-spacing: pixelToRem(0.5);

    @include media-breakpoint-up(md) {
        font-size: pixelToRem(26);
        line-height: pixelToRem(32);
        letter-spacing: pixelToRem(0.8);
    }

    @include media-breakpoint-up(xl) {
        font-size: pixelToRem(28);
        line-height: pixelToRem(34);
        letter-spacing: pixelToRem(0.8);
    }

    b {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }
}

@mixin h4 {
    @include font;
    color: $font-color;
    font-size: pixelToRem(18);
    line-height: pixelToRem(26);
    letter-spacing: pixelToRem(0.8);

    @include media-breakpoint-up(md) {
        font-size: pixelToRem(23);
        line-height: pixelToRem(34);
        letter-spacing: pixelToRem(0.8);
    }

    @include media-breakpoint-up(xl) {
        font-size: pixelToRem(28);
        line-height: pixelToRem(34);
        letter-spacing: pixelToRem(0.8);
    }

    b {
        font-weight: 500;
    }

    i {
        font-style: italic;
    }
}

// To-Do
@mixin h5 {
    @include font;
    color: $font-color;
    font-size: pixelToRem(16);
    line-height: pixelToRem(24);
    letter-spacing: pixelToRem(0.4);

    @include media-breakpoint-up(md) {
        font-size: pixelToRem(18);
        line-height: pixelToRem(28);
        letter-spacing: pixelToRem(0.8);
    }

    @include media-breakpoint-up(xl) {
        font-size: pixelToRem(20);
        line-height: pixelToRem(30);
        letter-spacing: pixelToRem(0.8);
    }

    b {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }
}

@mixin h6 {
    @include font;
    color: $font-color;
    font-size: pixelToRem(14);
    line-height: pixelToRem(24);
    letter-spacing: pixelToRem(0.4);

    b {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }
}

@mixin error {
    @include font;
    color: $error-color;
    font-size: pixelToRem(12);
    line-height: pixelToRem(16);
    letter-spacing: pixelToRem(0.25);

    @include media-breakpoint-up(md) {
        font-size: pixelToRem(10);
    }

    b {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }
}

@mixin text {
    @include link-styling;
    @include list-styling;
    @include table-styling;

    &>* {
        margin-bottom: 1rem;
        padding-bottom: 0.01rem;
    }

    h3 {
        @include h3;

        &.h3--small {
            @include h4;
            font-weight: 500;
        }
    }

    h4 {
        @include h4;
    }

    h5 {
        @include h5;
    }

    sub,
    sup {
        position: relative;
        font-size: 75%;
    }

    sub {
        top: .5em;
    }

    sup {
        top: -.5em;
    }
}

@mixin link-styling {
    a {
        cursor: pointer;
        color: $font-color-highlight;

        i {
            cursor: pointer;
            color: $font-color-highlight;
        }

        &:hover {
            color: $blue_hover;

            i {
                color: $blue_hover;
            }
        }
    }
}

// import this, when using a list in a section (e.x. in CMS_INPUT_DOM)
@mixin list-styling {

    // bullet point
    ul:not([class]) {
        margin-bottom: 0;
        padding-top: pixelToRem(15);

        li {
            padding-left: pixelToRem(24);
            margin-bottom: 1rem;
            position: relative;

            &::before {
                content: "\2022";
                position: absolute;
                color: inherit;
                font-weight: bold;
                left: pixelToRem(8);
            }
        }
    }

    // Ausrufezeichen
    ul.ausrufezeichen {
        margin-bottom: 0;
        padding-top: pixelToRem(15);

        li {
            padding-left: pixelToRem(35);
            margin-bottom: 1rem;
            position: relative;
            font-weight: 500;
        }

        li::before {
            content: $icon-ico_alert;
            @include font-icons();
            position: absolute;
            top: 0;
            left: 0;
            // top: pixelToRem(-3);
            font-size: 24px;

            // @include media-breakpoint-up(md) {
            //     top: 0;
            // }
        }
    }

    // Haken
    ul.haken {
        margin-bottom: 0;
        padding-top: pixelToRem(15);

        li {
            padding-left: pixelToRem(51);
            margin-bottom: pixelToRem(24);
            position: relative;
            font-weight: 500;
        }

        li::before {
            content: $icon-ico_check;
            @include font-icons();
            position: absolute;
            left: 5px;
            top: 0;
            font-size: 40px;
        }
    }

    ul.haken.haken--green {
        li::before {
            color: $green;
        }
    }

    ul.haken.haken--blue {
        li::before {
            color: $blue;
        }
    }

    // Zahlen
    ol:not([class]) {
        margin-bottom: 0;
        padding-top: pixelToRem(15);
        counter-reset: li;

        li {
            counter-increment: li;
            padding-left: pixelToRem(36);
            margin-bottom: 1rem;
            position: relative;

            &::before {
                content: counter(li)'.';
                position: absolute;
                color: inherit;
                left: 0;
            }
        }
    }
}

@mixin table-styling {
    table {
        width: 100%;
        color: $grey-90;
        margin-top: 25px;

        @include media-breakpoint-up(xl) {
            // @include overlap-col;
        }
    }

    th {
        text-align: left;
        font-weight: 500;
        border: 1px solid $white;
        background-color: $blue_20;
        color: $blue;
        padding-top: pixelToRem(11);
        padding-bottom: pixelToRem(11);
        padding-left: pixelToRem(16);
        line-height: 1.5;
        letter-spacing: 0.4px;
    }

    td {
        padding-top: pixelToRem(13);
        padding-bottom: pixelToRem(13);
        padding-left: pixelToRem(16);
    }

    tr:nth-child(odd) {
        background-color: $background-grey;
    }
}

/*
Hinweise zur Einbindung:
- Headline
    Einbindung der Klasse direkt am hN-Tag
    Einbindung des Inlcudes direkt an der Klasse
- Text
    Einbindung der Klasse am umschließenden Div
    Einbindung des Includes an der Klasse
- Caption
    Einbindung der Klasse direkt am Caption-Tag
    Einbindung des Includes direkt an der Klasse
*/
@mixin format($name) {
    @if ($name==headline) {
        @include h1;

        &--h2 {
            @include h2;
        }
    }

    @if ($name==subline) {
        @include h3;
    }

    @if ($name==introtext) {
        @include h4;
        @include text;
    }

    @if ($name==text) {
        @include h5;
        @include text;
    }

    @if ($name==caption) {
        @include h6;
        @include text;
    }

}
