$block: "landingpage-shop-contact-form";

.#{$block} {
  margin-top: pixelToRem(40);

  @include media-breakpoint-up(lg) {
    margin-top: pixelToRem(80);
  }

  &__wrapper {
    background-color: $blue;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    padding-top: pixelToRem(40);
    padding-bottom: pixelToRem(40);

    @include media-breakpoint-up(md) {
      padding-top: pixelToRem(46);
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: center;

      padding-top: pixelToRem(72);
    }
  }

  &__cta-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(md) {
      border-bottom: 1px solid white;
      padding-bottom: pixelToRem(40);
      margin-left: pixelToRem(24);
      margin-right: pixelToRem(24);
    }

    @include media-breakpoint-only(md) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: pixelToRem(32);
    }

    @include media-breakpoint-up(lg) {
      padding-left: pixelToRem(24);
      padding-right: pixelToRem(56);

      border-right: 1px solid white;
    }
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-only(md) {
      align-items: flex-start;
    }

    .cta-button {
      margin-top: pixelToRem(40);

      @include media-breakpoint-only(md) {
        margin-top: pixelToRem(20);
      }
    }
  }

  &__headline-vertical {
    text-align: center;
    font-size: pixelToRem(24);
    line-height: pixelToRem(33);
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
    width: 100%;

    @include media-breakpoint-only(md) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      font-size: pixelToRem(30);
      text-align: center;
    }
  }

  &__headline {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    font-size: pixelToRem(24);
    font-weight: bold;
    line-height: pixelToRem(33);
    color: white;
  }

  &__text {
    line-height: 1.5;
    text-align: center;
    font-size: pixelToRem(18);
    color: white;

    max-width: 317px;
    margin-top: pixelToRem(40);

    @include media-breakpoint-only(md) {
      margin-top: pixelToRem(20);
      text-align: start;
    }

  }

  &__card {
    width: 272px;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }

    position: absolute;
    top: 0;
    right: 0;
  }

  &__car {
    width: 220px;
    height: auto;

    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__image {
    width: 302px;
    height: 197px;
    position: relative;
  }

  &__subtext {
    @include format(caption);
    font-size: pixelToRem(16);
    margin-top: pixelToRem(32);

    .link-styles--animated {
      margin: 0;
    }

    @include media-breakpoint-up(md) {
      margin-top: -33px;
      height: 32px;

      display: flex;
      gap: pixelToRem(36);

      p {
        vertical-align: middle;
        line-height: 32px;
        margin: 0;
        padding: 0;
      }
    }

    & * {
      color: white;
    }

    a[href] {
      @include cta-button-animated;
    }

    a[href],
    a[href]:hover {
      color: white;

      &::before {
        background: white;
      }
    }

    .text-small {
      @include font;
      font-size: pixelToRem(12);
      line-height: 2;
      letter-spacing: 0.3px;
      color: white;
    }
  }

  & .contact-form {
    padding: pixelToRem(0) pixelToRem(16);

    &__content .contact-form__form form.mwf-form .mwf-error {
      color: $orange;
      border-color: $orange;
    }

    &__content .contact-form__form div[success] {
      transform: translateY(pixelToRem(-40));
      padding-top: 2rem;
      padding-bottom: 2rem;

      margin-left: pixelToRem(-16);

      @include media-breakpoint-up(lg) {
        margin-left: pixelToRem(-56);
      }
    }

    .contact-form__input {
      .mwf-input {
        @include media-breakpoint-up(md) {
          margin: 0;
        }
      }
    }

    .mwf-next {
      margin-top: pixelToRem(32);

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: auto;
        width: fit-content;
      }
    }

    @include media-breakpoint-down(md) {
      padding-top: pixelToRem(40);
    }

    @include media-breakpoint-up(lg) {
      padding-left: pixelToRem(56);
    }

    &__headline {
      font-size: pixelToRem(24);
      line-height: pixelToRem(33);

      @include media-breakpoint-up(lg) {
        font-size: pixelToRem(30);
      }
    }

    @include media-breakpoint-up(md) {
      &__column-fields>ul:first-of-type {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .contact-form__full-width,
        .mwf-captcha {
          grid-column: auto / span 2;
        }

        column-gap: pixelToRem(10);

        @include media-breakpoint-up(lg) {
          column-gap: pixelToRem(36);
        }
      }
    }

    &--bg-transparent {
      background: transparent;
    }

    &--bg-transparent-dark {
      background: transparent;

      .contact-form__form div[success] {
        color: white !important;
      }

      .contact-form__headline {
        color: $white;
      }

      .contact-form__subtext {

        a[href],
        a[href]:hover {
          color: $grey-60;
        }
      }

      .contact-form__radio {
        .mwf-label {
          .mwf-required {
            color: $white;
          }
        }

        .mwf-input {
          fieldset {
            .mwf-option {
              label {
                color: $white;
              }

              label[checked="true"]::after {
                background-color: $white;
              }
            }
          }
        }
      }

      .contact-form__radio--salutation {
        fieldset {
          .mwf-option {
            label[checked="true"] {
              background-color: $white;
            }

            label[checked="false"] {
              color: $white;
            }
          }
        }
      }

      .contact-form__checkbox {
        .mwf-input {
          fieldset {
            label {
              color: $white;
            }
          }
        }
      }

      .contact-form--ines {
        background-color: $white;
      }

      .contact-form__input {
        .mwf-input {
          input {
            color: white;
            border-bottom: 1px solid white;
          }
        }
      }

      .mwf-captcha {
        .mwf-input input {
          color: white;
          border: 1px solid white;
          border-radius: 0;
        }

        .mwf-input input::placeholder {
          color: white;
          opacity: 1;
        }

        img {
          object-fit: contain;
          background-color: white;

        }
      }

      .contact-form__textarea {

        &,
        &:hover,
        &:focus {
          margin-top: pixelToRem(16);
        }

        textarea {
          color: white;
          border: 1px solid white;
          border-radius: 0;
        }

        input::placeholder,
        textarea::placeholder {
          color: white;
        }
      }

      .text-teaser__title--h2,
      .text-teaser__text {
        color: white;
      }

      .contact-form__subtext {
        p {
          color: white;
        }
      }

      .mwf-label {
        color: white;
      }
    }
  }
}