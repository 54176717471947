$block: 'card';

.#{$block} {
    @include module-spacing;

    &--bg-gray {
        background-color: $background-grey;

        .#{$block}__intro-container {
            background-color: $white;
        }
    }

    &__headline {
        @include format(headline);
        font-weight: bold;
        margin-bottom: pixelToRem(32);
        text-align: center;
        font-weight: bold !important;
    }

    &__intro-container {
        background-color: #f1f1f1;
        border-radius: pixelToRem(8);
        padding: pixelToRem(24) pixelToRem(18) pixelToRem(88);
        width: 80%;

        @include media-breakpoint-up(md) {
            padding: pixelToRem(32) pixelToRem(24) pixelToRem(116);
            width: 60%;
        }

        @include media-breakpoint-up(lg) {
            width: 90%;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
            padding: pixelToRem(40) pixelToRem(32) pixelToRem(146);
        }
    }

    &__intro-headline {
        @include format(text);
        font-weight: 500;
        line-height: 1.2 !important;

        &::after {
            content: "—";
            display: block;
        }
    }

    &__intro-image {
        display: block;
        margin-top: 32px;
        width: 130%;

        @include media-breakpoint-up(md) {
            margin-top: 48px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 64px;
        }
    }

    &__text {
        @include format(text);
        margin-top: pixelToRem(24);

        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(32);
        }

        @include media-breakpoint-up(lg) {
            margin-top: pixelToRem(40);
        }
    }

    &__intro-text {
        @include format(text);

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(18);
        }

        em {
            font-weight: 500;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        
        &-item {
            &--left {
                align-items: flex-start;
            }
            
            &--center {
                align-items: center;
            }
            
            &--right {
                align-items: flex-end;
            }

            & + & {
                margin-top: $spacer;
            }
        }
    }
}