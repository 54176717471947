$block: "vat-calculator";

.#{$block} {
    @include module-spacing;
    
    // .container {
    //     padding-top: 3.75rem;
    //     padding-bottom: 1.875rem;
    // }

    & &__headline {
        @include format(headline);
        margin-bottom: .5rem;
    }

    & &__text {
        @include format(text);
        // @include h3;
    }
}
