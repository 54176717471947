$block: 'textboxes-image';

.#{$block} {
  margin-top: pixelToRem(20);
  margin-bottom: pixelToRem(20);

  @include media-breakpoint-up(lg) {
    @include module-spacing();
  }

  .container {
    padding: 0;

    @include media-breakpoint-down(xs) {
      max-width: 100%;
    }

    .row {
      @extend .no-gutters;

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &__box-white {
    padding: pixelToRem(20) $grid-gutter-width;
    background-color: $white;
    box-shadow: 0 0 pixelToRem(20) rgba(0, 0, 0, 0.07);
    margin-bottom: pixelToRem(12);
    @include list-styling;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      padding: pixelToRem(20) pixelToRem(95) pixelToRem(20) pixelToRem(30) ;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top:0;
      left:0;
      z-index: 1;
      padding: pixelToRem(20) pixelToRem(30) pixelToRem(20) pixelToRem(30) ;
    }

    h3 {
      font-size:  pixelToRem(24);
      font-weight: 700;
      line-height: 1.7;
      margin-bottom: pixelToRem(25);
      letter-spacing: pixelToRem(1.2);
    }

    h4 {
      @include format(text);
      font-weight: 700;
      line-height: pixelToRem(16);
      padding-bottom: pixelToRem(5);
      max-width: fit-content;
      border-bottom: 2px solid $black;
      margin-bottom: pixelToRem(5);

      @include media-breakpoint-up(lg) {
        font-size: pixelToRem(16);
        margin-bottom: pixelToRem(32);
      }
    }

    p {
      @include font;
      color: $font-color;
      font-size: pixelToRem(16);
      letter-spacing: pixelToRem(0.4);
      line-height: 1.9;
    }

    ol:not([class]),
    ul:not([class]) {
      line-height: 1.9;
      li {
        margin-bottom: 0.5rem;
      }
    }
  }

  &__image {
    margin-bottom: pixelToRem(12);
    overflow: hidden;

    img {
      width: 100%;
      transition: transform 0.5s ease-in-out;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      position: relative;

      &__wrapper {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
      }

      &,
      &__wrapper {
        height: 100%;
        display: block;
      }

      picture,
      img{
        height: 100%;
        width: auto;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-top: pixelToRem(70);
      height: auto;

      &__wrapper {
        position: relative;
        transform: translate(0,0);
        height: auto;
        width: 100%;
        top:0;
        left:0;
        overflow: hidden;
      }
    }

    picture {
      display: block;
      line-height: 0;
    }
  }

  &__box-blue {
    padding: pixelToRem(20) $grid-gutter-width;
    background-color: $blue;
    @include list-styling;
    color: white;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      padding: pixelToRem(20) $grid-gutter-width pixelToRem(20) pixelToRem(40) ;
    }

    h3 {
      font-size:  pixelToRem(24);
      font-weight: 700;
      line-height: 1.7;
      margin-bottom: pixelToRem(25);
      letter-spacing: pixelToRem(1.2);
    }

    h4 {
      @include format(text);
      color: $white;
      font-weight: 700;
      line-height: pixelToRem(16);
      padding-bottom: pixelToRem(5);
      max-width: fit-content;
      border-bottom: 2px solid $white;
      margin-bottom: pixelToRem(5);

      @include media-breakpoint-up(lg) {
        font-size: pixelToRem(16);
        margin-bottom: pixelToRem(32);
      }
    }

    p {
      @include font;
      font-size: pixelToRem(16);
      letter-spacing: pixelToRem(0.4);
      line-height: 1.9;
    }

    ol:not([class]),
    ul:not([class]) {
      line-height: 1.9;
      li {
        margin-bottom: 0.5rem;
      }
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom:0;
      right:0;
      z-index: 1;
    }
  }

  ol:not([class]),
  ul:not([class]) {
    padding-top: 0;

    li:last-child {
      margin-bottom: 0;
    }
  }

  &--bg-blue-light {
    @include media-breakpoint-up(lg) {
      background-color: rgba($blue_10, 0.5);
    }
  }

  .cta-button {
    margin: pixelToRem(28) 0 pixelToRem(20);
  }
}
