$block: 'accordion-item-video';

.#{$block} {

    &__text-full-width {
        @include format(text);
        margin-top: pixelToRem(4);
        margin-bottom: pixelToRem(8);
        color: #717171!important;

        @include media-breakpoint-up(md) {
            margin-bottom: pixelToRem(16);
            margin-top: pixelToRem(15);
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: pixelToRem(24);
            margin-top: pixelToRem(8);
        }
    }

    &__title {
        @include format(subline);
        margin-top: pixelToRem(4);
        margin-bottom: pixelToRem(8);
        font-weight: 600 !important;

        @include media-breakpoint-up(md) {
            margin-bottom: pixelToRem(16);
            margin-top: pixelToRem(15);
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: pixelToRem(24);
            margin-top: pixelToRem(8);
        }
    }

    &__button {
        margin-top: 1rem;

        @include media-breakpoint-up(xl) {
            margin-top: pixelToRem(16);
        }

        @include media-breakpoint-up(xl) {
            margin-top: pixelToRem(24);
        }
    }
}
