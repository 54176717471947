$block: "bild-kacheln"; 

.#{$block} {
  @include module-spacing;

  &__imagebox {
    height: pixelToRem(469);
    margin-bottom: pixelToRem(10);
  }
  
  &__image {
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: pixelToRem(7);
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  &__item {
    background-color: $background-grey;
    width: 100%;
    padding-bottom: pixelToRem(20);
    margin-bottom: pixelToRem(10);
    margin-left: 0;

    &--title {
      @include h4;
      font-weight: bold;
      padding-left: pixelToRem(40);
      padding-top: pixelToRem(32);
      padding-bottom: pixelToRem(24);
      width: 100%;

      @include media-breakpoint-up(md) {
        padding-left: pixelToRem(50);
      }

      @include media-breakpoint-up(xl) {
        padding-left: pixelToRem(80);
      }
    }

    &--link {
      @include text;
      padding-left: pixelToRem(40);

      @include media-breakpoint-up(md) {
        padding-left: pixelToRem(50);
      }

      @include media-breakpoint-up(xl) {
        padding-left: pixelToRem(80);
      }
    }

  }

  &--bg-gray {
    background-color: $background-grey;

    .#{$block}__item {
        background-color: $white;
    }
  }

}
