$block: 'blog-teaser-start';

.#{$block} {
    max-width: pixelToRem(1920);
    margin-left: auto;
    margin-right: auto;

    &__wrapper {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        padding-left: pixelToRem(24);
        padding-right: pixelToRem(24);
        max-width: 1326px;
    }

    &__headline {
        font-size: pixelToRem(24);
        font-weight: 700;
        line-height: pixelToRem(35);
        letter-spacing: pixelToRem(0.9);
        text-align: center;

        @include media-breakpoint-up(md) {
            font-size: pixelToRem(36);
            line-height: pixelToRem(41);
        }
    }

    &__grid {
        display: grid;

        padding-top: pixelToRem(40);

        grid-template:
            "a b"
            "c c"
            "d e";

        gap: pixelToRem(40) pixelToRem(61.2);

        justify-items: center;

        &>div {
            display: none;
        }

        &>div:nth-child(1) {
            grid-area: a;
            display: block;
        }

        &>div:nth-child(2) {
            grid-area: b;
            display: block;
        }

        &>div:nth-child(3) {
            grid-area: c;
            display: block;
        }

        &>div:nth-child(4) {
            grid-area: d;
            display: block;
        }

        &>div:nth-child(5) {
            grid-area: e;
            display: block;
        }

        &>div:nth-child(3) {
            .#{$block}__image {
                width: 100%;
                max-width: 900px;
                aspect-ratio: calc(900 / 564);
            }

            .#{$block}__card-wrapper {
                min-height: pixelToRem(432) !important;
            }

            .#{$block}__card {
                margin-top: pixelToRem(-432) !important;
            }
        }
    }

    &__item-wrapper {
        width: 100%;
    }

    &__item {
        display: block;
        padding-left: pixelToRem(61.2);
        width: 100%;
        max-width: pixelToRem(982);
        margin-left: auto;
        margin-right: auto;
    }

    &__image {
        position: relative;

        width: 100%;
        aspect-ratio: calc(10 / 9);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left;
        }
    }

    &__label {
        position: absolute;
        top: 0;
        left: 0;

        padding: 9px 20px;
        font-weight: 400;
        font-size: pixelToRem(16);
        line-height: pixelToRem(24);
    }

    &__card-wrapper {
        margin-left: pixelToRem(-61.2);
        margin-top: -250px;
    }

    &__card {
        position: relative;
        border: 1px solid $grey-20;
        padding: pixelToRem(24);

        width: 310px;
        min-height: 280px;

        color: $grey-90;
        background-color: white;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__headline {
            font-weight: 700;
            font-size: pixelToRem(24);
            line-height: pixelToRem(29);
            letter-spacing: pixelToRem(0.9);
            text-align: left;
            margin-bottom: pixelToRem(16);
        }

        &__text {
            font-weight: 400;
            font-size: pixelToRem(16);
            line-height: pixelToRem(23);
            letter-spacing: pixelToRem(0.5);
            margin-bottom: pixelToRem(19);

            p {
                display: inline;
            }

            strong, em {
                font-weight: bold;
            }

            i {
                font-style: italic;
            }
        }

        &__link {
            @include cta-button-animated;
            line-height: pixelToRem(24);
            color: $blue;
            text-decoration: none;
            font-weight: 400;

            width: fit-content;
        }
    }
}
