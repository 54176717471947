$block: 'logo-karussell';

.#{$block} {
    @include module-spacing;
    
    &--bg-gray {
        background-color: $background-grey;
    }

    &__headline {
      @include format(headline);
      font-weight: bold;
      margin-bottom: pixelToRem(33);
      font-size: pixelToRem(36px);
      line-height: 1.14;
      letter-spacing: 0.9px;

      @include media-breakpoint-up(md) {
        font-size: pixelToRem(32);
        line-height: pixelToRem(40);
        letter-spacing: pixelToRem(0.8);
      }

      @include media-breakpoint-up(xl) {
          font-size: pixelToRem(36);
          line-height: pixelToRem(41);
          letter-spacing: pixelToRem(0.9);
      }

      &--center {
        text-align: center;
      }

      &--right {
        text-align: right;
      }
    }

    &__carousel-item {
        padding: 0 pixelToRem(8);
    }
    
    &__image {
        // margin-bottom: pixelToRem(16);
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 1px solid #e3e3e3;
        height: pixelToRem(52);
        // width: pixelToRem(200);
        margin: 0 auto;
        
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__navigation {
        position: relative;
    }

    &__arrow {
        position: absolute;
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }

        &--prev {
            top: 100%;
            margin-top: pixelToRem(16);
            transform: translate(0, -50%);
            left: 0;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                top: 50%;
                left: pixelToRem(-48);
            }

            @include media-breakpoint-up(xl) {
                left: pixelToRem(-88);
            }
        }

        &--next {
            top: 100%;
            margin-top: pixelToRem(16);
            transform: translate(0, -50%);
            right: 0;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                top: 50%;
                right: pixelToRem(-48);
            }

            @include media-breakpoint-up(xl) {
                right: pixelToRem(-88);
            }
        }
    }

    &__arrow-button {
        @include button-reset();
        font-size: pixelToRem(25);
    }

    &__dots {
        .slick-dots {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            & button {
                @include button-reset();
                color: transparent;
                background-color: $grey-20;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin: $spacer*0.5;
            }

            & .slick-active {
                button {
                    background-color: $grey-90;
                }
            }
        }
    }
}
