﻿$block: 'iframe';

.#{$block} {
  @include module-spacing;

  &__iframe {
    width: 100%;
    border: none;
  }

  &--bg-gray {
    background-color: $background-grey;
  }
}