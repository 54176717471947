$block: 'stationsfinder';

.#{$block} {

  &__locator {
    @include media-breakpoint-only(xl) {
    width: 100vw;
    height:calc(100vh - (88px));
    //height:calc(100vh - (88px + 182px));
/*    Viewport height - (Navigation + Footer)
    Angabe relativ zur Größe des Viewports bzw. Browserfensters.*/
    }
    @include media-breakpoint-only(lg) {
      width: 100vw;
      height:calc(100vh - (88px));
      // height:calc(100vh - (88px + 212px));
    }
    @include media-breakpoint-only(md) {
      width: 100vw;
      height:calc(100vh - (88px));
      // height:calc(100vh - (88px + 212px));
    }
    @include media-breakpoint-only(sm) {
      width: 100vw;
      height:calc(100vh - (88px));
      // height:calc(100vh - (88px + 208px));
    }
    @include media-breakpoint-only(xs) {
      width: 100vw;
      height:calc(100vh - (88px));
      // height:calc(100vh - (88px + 280px));
    }
  }
}
