@font-face {
    font-family: 'DKV';
    src: url("../assets/fonts/icomoon/DKV.woff2") format("woff2"),
        url("../assets/fonts/icomoon/DKV.woff") format("woff"),
        url("../assets/fonts/icomoon/DKV.ttf") format("ttf"),
        url("../assets/fonts/icomoon/DKV.eot") format("eot");
    font-style: normal;
}

@font-face {
    font-family: 'DKV-large';
    src: url("../assets/fonts/icomoon/DKV-large.woff2") format("woff2"),
        url("../assets/fonts/icomoon/DKV-large.woff") format("woff"),
        url("../assets/fonts/icomoon/DKV-large.ttf") format("ttf"),
        url("../assets/fonts/icomoon/DKV-large.eot") format("eot");
    font-style: normal;
}

// Red Hat Text

@font-face {
    font-family: 'RedHatText';
    src: url("../assets/fonts/redhattext/RedHatText-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: 'RedHatText';
    src: url("../assets/fonts/redhattext/RedHatText-LightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 300;
}

//Regular
@font-face {
    font-family: 'RedHatText';
    src: url("../assets/fonts/redhattext/RedHatText-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'RedHatText';
    src: url("../assets/fonts/redhattext/RedHatText-Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: 400;
}

//Medium
@font-face {
    font-family: 'RedHatText';
    src: url("../assets/fonts/redhattext/RedHatText-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: 'RedHatText';
    src: url("../assets/fonts/redhattext/RedHatText-MediumItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 500;
}

//SemiBold
@font-face {
    font-family: 'RedHatText';
    src: url("../assets/fonts/redhattext/RedHatText-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: 'RedHatText';
    src: url("../assets/fonts/redhattext/RedHatText-SemiBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 600;
}

//Bold
@font-face {
    font-family: 'RedHatText';
    src: url("../assets/fonts/redhattext/RedHatText-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: 'RedHatText';
    src: url("../assets/fonts/redhattext/RedHatText-BoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 700;
}

// Red Hat Display (headline text font)
//Thin
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-LightItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 300;
}

//Regular
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-Italic.ttf") format("truetype");
    font-style: italic;
    font-weight: 400;
}

//Medium
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-MediumItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 500;
}

//SemiBold
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-SemiBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 600;
}

//Bold
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: 'RedHat';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-BoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 700;
}

//ExtraBold
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-ExtraBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 800;
}
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-ExtraBoldItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 800;
}

//Black
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-Black.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
}
@font-face {
    font-family: 'RedHatDisplay';
    src: url("../assets/fonts/redhatdisplay/RedHatDisplay-BlackItalic.ttf") format("truetype");
    font-style: italic;
    font-weight: 900;
}
