
// FIX: REMOVE IPHONE SPECIFIC DESIGNS
// https://www.prodjex.com/2018/03/how-to-remove-blue-styling-of-telephone-numbers-on-iphone-ios/

a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

select {
    -moz-appearance: none !important; 
	-webkit-appearance: none !important;
	appearance: none !important;
}

