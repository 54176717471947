$block: "landingpage-shop-navigation";

.#{$block} {
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.3s ease;

    @include media-breakpoint-up(xl) {
        & ::-webkit-scrollbar {
            display: none;
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;

        padding: 16px 24px;
    }

    &__logo {
        &-img {
            height: 56px;
            display: block;
        }
    }

    &__hotline {
        display: flex;
        align-items: center;
        gap: pixelToRem(18);
        max-width: 150px;

        @include media-breakpoint-up(lg) {
            gap: pixelToRem(32);
            max-width: initial;
        }

        &__label {
            font-size: pixelToRem(12);
            letter-spacing: pixelToRem(1.2);
            font-weight: 500;
            line-height: pixelToRem(16);

            overflow-wrap: normal;

            @include media-breakpoint-up(lg) {
                font-size: pixelToRem(16);
                font-weight: 400;

                line-height: 24px;
            }
        }

        &__number {
            font-size: pixelToRem(12);
            letter-spacing: pixelToRem(1.2);
            line-height: pixelToRem(24);
            font-weight: 700;

            @include media-breakpoint-up(lg) {
                display: initial;
                font-size: pixelToRem(16);
            }
        }
    }

    & .icon-ico_phone {
        font-size: pixelToRem(30);
    }
}