﻿$block: 'navigation-addon';

.#{$block} {
  display: block;
  // margin-top: pixelToRem(-16);

  @include media-breakpoint-up(xl) {
    display: flex;
    // margin-top: 0;
  }
}