$block: "text-image-logo";

.#{$block} {
  @include module-spacing;

  &__container {
    border-bottom: 2px solid $grey-30;
    padding-bottom: pixelToRem(20);

    @include media-breakpoint-up(md) {
      padding-bottom: pixelToRem(44);
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: pixelToRem(30.5);
    }
  }


  &__headline {
    font-style: normal;
    font-weight: 700;
    font-size: pixelToRem(16);
    line-height: pixelToRem(24);
    letter-spacing: pixelToRem(1.2);
    margin-bottom: pixelToRem(17);

    @include media-breakpoint-up(lg) {
      font-size: pixelToRem(48);
      line-height: pixelToRem(52);
      margin-bottom: pixelToRem(24);
    }
  }

  &__text {
    @include format(text);
    margin-bottom: pixelToRem(16);
    color: $grey-90;

    @include media-breakpoint-up(md) {
      font-size: pixelToRem(16);
      line-height: pixelToRem(24);
      letter-spacing: pixelToRem(0.4);
    }

    @include media-breakpoint-up(lg) {
      font-size: pixelToRem(16);
      line-height: pixelToRem(30);
      letter-spacing: pixelToRem(0.5);
    }

    p:last-child {
      margin-bottom: 0;
    }

    &--truncate {

      @include media-breakpoint-down(md) {
        overflow: hidden;
        max-height: pixelToRem(70);
        display: block;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }

      @include media-breakpoint-down(sm) {
        max-height: pixelToRem(120);
        -webkit-line-clamp: 5;
      }
    }

    &--open {
      max-height: none;
      height: auto;
      display: block;
      -webkit-box-orient: initial;
      -webkit-line-clamp: none;
    }
  }

  &__image {
    display: block;
    width: 100%;
    object-fit: contain;
  }

  &__button {

    @include media-breakpoint-up(lg) {
      display: none;
    }

    button {
      font-weight: 400;
      background-color: transparent;
      margin-bottom: pixelToRem(16);

      &:after {
        margin-left: pixelToRem(5);
        transform: rotate(90deg);
      }
    }

    &--open {

      button {
        &:after {
          transform: rotate(-90deg);
        }
      }
    }
  }

}
