$block: 'news-teaser';

.#{$block} {
    padding-top: pixelToRem(40);
    padding-bottom: pixelToRem(40);

    &__category {
        position: absolute;
        top: 0;
        left: 0;
        padding: pixelToRem(9) pixelToRem(24);
        @include h5;
        color: white;
        background-color: $blue;

        font-size: pixelToRem(16) !important;
        line-height: pixelToRem(24) !important;
        letter-spacing: pixelToRem(0.4) !important;
    }

    &__container {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        max-width: 100%;
        position: relative;
        width: 510px;

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        @include media-breakpoint-up(md) {
            height: 300px;
            width: 340px;

            &:after {
                display: none;
            }
        }

        @include media-breakpoint-up(lg) {
            height: 405px;
            width: 468px;
        }

        @include media-breakpoint-up(xl) {
            height: 400px;
            width: 100%;
        }
    }

    &__introtext {
        @include format(introtext);
        margin-bottom: pixelToRem(24);

        @include media-breakpoint-up(md) {
            @include h5;
            margin-bottom: pixelToRem(64);
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: pixelToRem(80);
        }
    }

    &__content {
    }

    &__date {
        display: flex;
        line-height: pixelToRem(30);
        color: $blue;
        margin-top: pixelToRem(16);
        margin-left: pixelToRem(10);
        padding-bottom: pixelToRem(14);
    }


    &__icon {
        //font-size: pixelToRem(20);
        font-size: pixelToRem(26);
        line-height: pixelToRem(30);
        margin-right: pixelToRem(8);
        color: $blue;
    }

    &__title {
        margin-bottom: pixelToRem(12);
        font-size: pixelToRem(18);
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        margin-top: pixelToRem(12);
        height: pixelToRem(69);
        color: $grey-90;

        em {
            color: $grey-lighter;
            font-weight: normal;
        }
        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(16);
        }
        @include media-breakpoint-down(lg) {
            height: auto;
        }
    }

    &__teaser-text {

        font-size: 18px;
        color: $dark-gray;
        line-height: 1.39;
        margin-bottom: 24px;
        height: pixelToRem(100);

        @include media-breakpoint-down(lg) {
            height: auto;
        }

        @include media-breakpoint-only(md) {
            font-size: 17px;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include media-breakpoint-down(md) {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                /* IE10+ CSS styles go here */
                position: relative;
                top: 50%;
                transform: translateY(-50%);
           }
        }
    }

    &__figcaption {
        position: absolute;
        top: 0;
        left: 0;
        padding: pixelToRem(9) pixelToRem(24);
        @include h5;
        color: white;
        background-color: $blue;

        font-size: pixelToRem(16) !important;
        line-height: pixelToRem(24) !important;
        letter-spacing: pixelToRem(0.4) !important;
    }

    &__figure {
        position: relative;
        
        &-infobox {
            background-color: $background-grey;
            & > * {
                margin-right: pixelToRem(16);
            }
    }
    }

    &__detail-link {
        margin-top: pixelToRem(40);

        @include media-breakpoint-down(lg) {
            margin-top: pixelToRem(20);
        }

        &-a {
            text-transform: uppercase;
            font-size: 16px;
        }
    }

    &__tags {
        margin-top: pixelToRem(40);
        @include h5;
        font-style: normal;
        color: $devil;

        @include media-breakpoint-down(lg) {
            margin-top: pixelToRem(20);
        }
    }

    &__tag {
        margin-right: pixelToRem(24);
    }

    &__functions {
        margin-top: pixelToRem(24);
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include media-breakpoint-down(lg) {
            margin-top: pixelToRem(16);
        }
    }

    &__share {
        display: inline-block;
        margin-top: pixelToRem(16);
    }

    &__download {
        display: inline-block;
        padding-bottom: pixelToRem(13);
    }
}
