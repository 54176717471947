$block: "bild-text-link-vertical";

.#{$block} {
    @include module-spacing;

    &--bg-gray {
        background-color: $background-grey;
    }

    &__headline {
        @include h2;
        font-weight: bold;
        margin-bottom: pixelToRem(17);
    }

    &__text {
        @include format(text);
        margin-bottom: pixelToRem(24);

        @include media-breakpoint-up(md) {
            margin-bottom: pixelToRem(40);
        }
    }

    &__image {
        margin-bottom: pixelToRem(23);
        width: 100%;

        & * {
            display: block;
        }
    }

    &__list-wrapper {
        @include media-breakpoint-up(lg) {
            margin-left: pixelToRem(33);
        }
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        &--horizontal {
            display: flex;
            flex-direction: column;
            margin-top: pixelToRem(0);
            margin-bottom: pixelToRem(-16);

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            .#{$block} {
                &__item {
                    margin-right: pixelToRem(41);
                }
            }
        }

        a {
            margin-bottom: pixelToRem(17);
        }
    }
}