$block: 'news-detail';

.#{$block} {


    @include module-spacing;
    padding-bottom: pixelToRem(40);

    @include media-breakpoint-up(md) {
        padding-bottom: pixelToRem(40);
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: pixelToRem(40);
    }

    &--bg-gray {
        background-color: $background-grey;
    }

    &__date {
        color: $blue;
        @include media-breakpoint-down(sm) {
            float: left;
        }
    }

    &__headline {
        font-weight: 500;
        font-size: 45px;
        color: $blue;
        margin-bottom: pixelToRem(35);
        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
    }

    &__image {
        display: block;
        width: 100%;

        @include media-breakpoint-down(xl) {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                /* IE10+ CSS styles go here */
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &__figure {
        position: relative;
        max-height: 550px;
        min-height: 300px;
        height: 100%;


        @include media-breakpoint-down(xl) {
            overflow: hidden;
        }

        @include media-breakpoint-down(sm) {
            max-height: pixelToRem(200);
            min-height: auto;
        }
    }

    &__text {
        @include format(text);
        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 32px;
        }

        font-size: 18px;
        line-height: 32px;
        margin-top: pixelToRem(37);
        margin-bottom: pixelToRem(31);
    }

    &__share {
        display: inline-block;
    }

    &__functions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__download {
        display: flex;
        color: $blue;
        text-decoration: none;
        line-height: pixelToRem(30);

        &__news {
            @include media-breakpoint-down(sm) {
                float: left;
            }
        }
    }

    &__mail {
        display: flex;
        line-height: pixelToRem(30);
        @include media-breakpoint-up(lg) {
            float: right;
        }
        color: $blue;
        text-decoration: none;
        font-size: 12px;
        margin-bottom: 32px;
    }
    &__box {
        display: flex;
        line-height: pixelToRem(30);
        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;

        }
        @include media-breakpoint-up(md) {
            float: left;
        }
        padding-right: 16px;
        margin-bottom: 14px;
    }
    &__share_box {
        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
        }
        @include media-breakpoint-up(md) {
            float: left;
        }
        padding-right: 16px;
    }

    &__icon {
        font-size: pixelToRem(26);
        line-height: pixelToRem(30);
        margin-right: pixelToRem(6);
        color: $blue;
        &-cal {
            font-size: pixelToRem(26);
            line-height: pixelToRem(30);
            margin-right: pixelToRem(4);
        }
    }

    &__icon_unread {
        font-size: 18px;
        line-height: pixelToRem(30);
        margin-right: pixelToRem(1);
        color: $blue;
    }

    .download {
        padding-bottom: 40px;

        &__line {
            width: 100%;
            height: 1px;
            border-bottom: 1px solid #979797;
            margin-bottom: 17px;
        }

        &__item {
            margin-bottom: 17px;

            a {
                color: $blue;
            }
        }
    }

    .row-md {
        @include media-breakpoint-up(md) {
            display: flex;
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .row-sm {
        @include media-breakpoint-up(md) {
            display: none;
        }
        @include media-breakpoint-down(sm) {
            display: flex;
        }
    }
}
