$block: "hero__ebene-zwei";

.#{$block} {
  background: $blue;
  position: relative;

  @include media-breakpoint-up(md) {
    height: pixelToRem(350);
  }

  @include media-breakpoint-up(lg) {
    height: pixelToRem(480);
  }

  picture {
    display: block;
    width: 100%;
    height: pixelToRem(240);

    @include media-breakpoint-up(md) {
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
      gap: pixelToRem(96);
    }
    @include media-breakpoint-up(lg) {
      gap: pixelToRem(112);
    }
  }

  &__content {
    padding: pixelToRem(24) 0;
    @include media-breakpoint-up(sm) {
      padding: pixelToRem(32) 0;
    }
    @include media-breakpoint-up(md) {
      padding: 0;
    }

    &__headline {
      @include h1;
      font-size: pixelToRem(24);
      line-height: pixelToRem(32);
      color: $white;

      @include media-breakpoint-up(md) {
        font-size: pixelToRem(24);
        line-height: pixelToRem(32);
      }

      @include media-breakpoint-up(lg) {
        font-size: pixelToRem(32);
        line-height: pixelToRem(48);
      }

      @include media-breakpoint-up(xl) {
        font-size: pixelToRem(40);
        line-height: pixelToRem(50);
      }
    }

    &__copy {
      margin-top: pixelToRem(16);
      line-height: 1.5;
      color: $white;
      @include media-breakpoint-up(md) {
        padding-right: pixelToRem(40);
      }

      p:not(:last-child) {
        margin-bottom: pixelToRem(8);
      }

      li {
        position: relative;
        margin-bottom: pixelToRem(8);
        padding-left: pixelToRem(24);

        &:before {
          position: absolute;
          left: pixelToRem(8);
          content: "\2022";
          font-weight: bold;
          color: inherit;
        }
      }
    }

    &__button-container {
      margin-top: pixelToRem(32);

      .cta-button--variants {
        @include media-breakpoint-down(md) {
          @include cta-button-small;
        }
      }

      @include media-breakpoint-up(md) {
        padding-right: pixelToRem(40);
      }
    }

    &__link-container {
      margin-top: pixelToRem(32);

      .cta-button {
        color: $white;
        font-style: normal;

        &:before {
          background-color: $white;
        }
      }

      @include media-breakpoint-up(md) {
        padding-right: pixelToRem(40);
      }
    }

    &__button-container + #{&}__link-container {
      margin-top: pixelToRem(16);
    }
  }

  /**
   * variant, 700px height
   */
  &--sea {
    height: calc(100vh - 88px);
    display: grid;
    grid-template-rows: 1fr 1fr;

    @include media-breakpoint-up(md) {
      grid-template-rows: auto;
    }

    picture {
      height: 100%;
    }
  }
}
