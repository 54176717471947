$block: "hero__startseite";

.#{$block} {
  background-color: $blue;
  color: white;
  position: relative;

  &__image-container {
    width: 100%;
    aspect-ratio: 96 / 35;

    @supports not (aspect-ratio: auto) {
      height: pixelToRem(280);

      @include media-breakpoint-up(md) {
        height: pixelToRem(374);
      }

      @include media-breakpoint-up(lg) {
        height: pixelToRem(467);
      }

      @include media-breakpoint-up(xl) {
        height: pixelToRem(700);
      }
    }
    max-height: pixelToRem(700);

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    width: 100%;
    background: $white;
    padding-top: pixelToRem(32);

    @include media-breakpoint-up(md) {
      background: transparent;
      padding-top: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &__headline {
      @include h1;
      color: $blue;

      @include media-breakpoint-up(md) {
        color: $white;
        width: 50%;
      }
    }

    &__copy {
      line-height: 1.5;
      margin-top: pixelToRem(16);
      color: $black;

      @include media-breakpoint-up(md) {
        color: $white;
        width: 50%;
      }

      u {
        text-decoration: underline
      }
      
      b {
        font-weight: 800
      }

      em{
        color: #8c8c8c; 
        font-weight: 400;
      }
      
      i {
        font-style: italic;
      }

    }

    &__button-container {
      margin-top: pixelToRem(32);
      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }
  }
}
