$block: "sticky-navigation";

.#{$block} {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  transition: all 0.5s;
  filter: drop-shadow(0 0 16px rgba(0, 0, 0, 0.15));

  &__button {
    display: block;
    font-size: pixelToRem(16);
    padding: pixelToRem(4) pixelToRem(16);
    color: $blue;
    background: $white;
    border-radius: 20px;
    text-decoration: none;
    transition: all 0.2s;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    flex-direction: column;
    bottom: 50vh;
    transform: translateY(50%);

    &:hover {
      cursor: pointer;
    }
  }

  &__header {
    width: 100vw;
    height: pixelToRem(60);
    padding: 0 pixelToRem(24);
    background: $blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      width: 100%;
      height: pixelToRem(40);
      padding: 0;
      justify-content: start;
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    gap: pixelToRem(12);

    color: $white;
    font-size: 14px;

    padding: pixelToRem(8);

    position: relative;
    left: pixelToRem(8);

    &__icon {
      font-size: 22px;
    }

    &:hover {
      cursor: pointer;
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        cursor: default;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row-reverse;
      padding: 0;
      gap: pixelToRem(14);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;

    &-hidden {
      display: none;
    }
  }

  &__link {
    box-sizing: border-box;
    width: 100%;
    text-decoration: none;
    white-space: nowrap;
    background: $white;
    color: $gray;
    padding: pixelToRem(16) pixelToRem(48) pixelToRem(16) pixelToRem(8);
    position: relative;
    left: 0;
    font-size: 14px;
    transition: all 0.5s;
    display: flex;
    align-items: center;

    &-active {
      background: #effafe;
    }

    & img {
      width: pixelToRem(22);
      margin-right: pixelToRem(14);
    }

    & p {
      pointer-events: none;
    }
  }

  &__separator {
    width: 100%;
    height: 1px;
    background: $gray-lighter;
  }
}
