﻿$block: 'google-map';

.#{$block} {
  @include module-spacing;

    &__iframe {
      border: 0;
      height: pixelToRem(277);
      width: 100%;
    }

    &--bg-gray {
      background-color: $background-grey;
    }
}
