$block: "diesel-prices-index";

.#{$block} {
  @include module-spacing;

  &__toggle {
    width: 100%;
    padding: pixelToRem(24) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border: none;
    border-top: pixelToRem(1) solid $grey-30;
    border-bottom: pixelToRem(1) solid $grey-30;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      padding: pixelToRem(32) 0;
    }

    &__title {
      display: flex;
      align-items: center;

      gap: pixelToRem(8);

      @include media-breakpoint-up(md) {
        gap: pixelToRem(20);
      }

      @include media-breakpoint-up(md) {
        gap: pixelToRem(24);
      }

      img {
        width: pixelToRem(40);

        @include media-breakpoint-up(md) {
          width: pixelToRem(52);
        }

        @include media-breakpoint-up(md) {
          width: pixelToRem(60);
        }
      }

      h2 {
        @include h2;
        font-weight: 700;
        letter-spacing: pixelToRem(0.7);

        font-size: pixelToRem(16);
        line-height: pixelToRem(32);

        @include media-breakpoint-up(md) {
          font-size: pixelToRem(28);
        }
      }
    }

    &__chevron {
      font-size: pixelToRem(32);
    }
  }

  &__table {
    height: pixelToRem(0);
    overflow: hidden;

    font-size: pixelToRem(14);
    font-weight: 400;
    line-height: pixelToRem(20);
    color: $grey-90;

    display: grid;
    grid-template-columns: auto 1fr auto auto;

    &__head {
      background: $gray-10;
    }

    &__cell {
      padding: pixelToRem(14) pixelToRem(0);
      border-bottom: pixelToRem(1) solid $grey-30;

      display: flex;
      justify-content: start;

      font-size: pixelToRem(12);

      padding-right: pixelToRem(8);

      @include media-breakpoint-up(md) {
        font-size: pixelToRem(14);
        padding-right: pixelToRem(16);
      }

      &-start {
        padding-left: pixelToRem(8);

        @include media-breakpoint-up(md) {
          padding-right: pixelToRem(16);
        }
      }

    }

    &__subhead {
      padding: pixelToRem(14) pixelToRem(16);
      background: $gray-10;
      border-bottom: pixelToRem(1) solid $grey-30;

      grid-column-end: span 4;

      display: flex;
      justify-content: center;
    }
  }
}
