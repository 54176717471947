$block: "flow-paper";

.#{$block} {
    @include module-spacing;

    &__content {
        &--bg-blue {
            @include module-spacing;
            background-color: $blue;
            color: white !important
        }
    }

    &__headline {
        @include format(headline);
        font-weight: 800;
        margin-top: pixelToRem(2);
        margin-bottom: pixelToRem(26);

        &--orange {
            color: $orange !important;
        }
    }

    &__text {
        @include format(text);
        margin-bottom: pixelToRem(-16);
        overflow: auto;

        li {
            padding-bottom: pixelToRem(1);
        }

        .cta-button {
            margin-top: pixelToRem(15);
        }
    }

    &__tile-headline {
        @include format(text);

        font-weight: bold;
        margin-top: pixelToRem(20);
        margin-bottom: pixelToRem(16);
    }

    &__tile-img-box {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: auto;

        @include media-breakpoint-up(md) {
            aspect-ratio: 4/3;
        }
    }

    &__tile-img-link {
        display: flex;
    }

    &__tile-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-left: 60px solid $orange;
        border-bottom: 30px solid transparent;
    }

    &__image {
        width: 100%;
        height: auto;
        border: 1px solid #fff;
        object-fit: cover;

        &--portrait {
            width: 100%;
            height: auto;

            @include media-breakpoint-up(md) {
                width: auto;
                height: 100%;
            }
        }

        &--play {
            border: 1px solid $orange;
        }
    }

    &__cta-button {
        padding-left: 0;
    }

    &__content {
        &--bg-blue {
            @include module-spacing;
            background-color: $blue;

            .#{$block}__headline,
            .#{$block}__text,
            .#{$block}__tile-headline,
            .#{$block}__cta-button {
                color: #fff;
            }

            .#{$block}__tile-download {
                background-color: $blue;
            }

            .#{$block}__cta-button {
                &::before {
                    background: #fff;
                }
            }
        }
    }
}