﻿$block: 'contact-form-large';

.#{$block} {
	@include module-spacing;

	&__headline {
		@include h2;
		margin-bottom: 2rem;
		
		em, b {
			@include font-bold;
		}

		@include media-breakpoint-up(xl) {
			font-size: pixelToRem(40);
			line-height: pixelToRem(50);
			letter-spacing: pixelToRem(1);
		}
	}

	&__text {
		@include format(text);
		margin-bottom: 2rem;
	}

	&--bg-gray {
		background-color: $background-grey;
	}

	&--bg-blue {
		background-color: $blue;
	}

	& .contact-form {
		padding: 0;

		& .mwf-button.mwf-next input {
			line-height: 2 !important;
		}

		&__input {
			.mwf-label {
				padding: 0;
				color: $grey-60;
			}

			input:not(.contact-form__input--square) {
				padding: 0 !important;
				padding-bottom: pixelToRem(6) !important;
			}
			
			.mwf-input {
				padding-top: 1rem;
			}
			
			.mwf-text,
			.mwf-select {
				flex-basis: 100%;
				max-width: none;
			}

			&--square {
				label {
					display: none;
				}

				.mwf-input {
					border: 1px solid $grey-30 !important;
					margin-bottom: pixelToRem(8);
					padding: pixelToRem(9) 1rem;
					
					input {
						// color: $grey-40;
						color: $grey-90;
						padding: 0;
						border: none;
					}
				}
			}
			
			&--disabled {
				.mwf-input {
					background-color: $grey-20 !important;
				}
			}

			&--mr-1 {
				margin-right: 1rem;
			}
		}

		&__radio {
			margin-bottom: 0;

			label.mwf-label {
				padding: 0;
			}

			.mwf-input {
				margin-left: 0;
				margin-bottom: pixelToRem(8);

				.mwf-option {
					width: 100% !important;
					
					@include media-breakpoint-up(md) {
						width: auto !important;
					}

					& + .mwf-option {
						@include media-breakpoint-up(md) {
							margin-left: pixelToRem(64);
						}
					}
				}
			}
		}

		.mwf-field.contact-form__select.col-3.col-md-2.col-lg-1 {
			@include media-breakpoint-up(md) {
				max-width: 10%;
				flex: 0 0 10%;
			}

			@include media-breakpoint-up(lg) {
				max-width: 12.445%;
				flex: 0 0 12.445%;
			}
		}
			
		.mwf-field.contact-form__input.contact-form__input--square.contact-form__input--disabled.col-9.col-md-4.col-lg-5 {
			@include media-breakpoint-up(md) {
				max-width: 40%;
				flex: 0 0 40%;
			}

			@include media-breakpoint-up(lg) {
				max-width: 37.555%;
				flex: 0 0 37.555%;
			}
		}

		.contact-form__select .contact-form__select {
			margin-bottom: pixelToRem(8);
			@include media-breakpoint-only(md) {
				padding-left: pixelToRem(12);

				&::after {
					right: 10%;
				}
			}

			@include media-breakpoint-up(lg) {
				padding-left: pixelToRem(22);
			}
		}

		.contact-form__select .mwf-label {
			display: none;
		}
	}
}