﻿$block: 'layout5050';

.#{$block} {
  @include module-spacing;

  &--bg-gray {
    background-color: $background-grey;
  }

  & section [class*="offset-"] {
    margin-left: 0;
  }

  & section [class*="col-"] {
    flex: 0 0 100%;
    max-width: 100%;
  }

  & .logo-karussell {
    &__carousel {
      width: 80%;
      margin: auto;
    }

    &__arrow {
      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }
  }

  &__row {
    @include media-breakpoint-up(lg) {
      justify-content: center;
    }
  }

  &__col {
    & + & {
      @include media-breakpoint-up(lg) {
        margin-left: 2.25rem;
      }
    }
  }
}
