$block: "landingpage-shop-vorteilsliste";

.#{$block} {
    margin-top: pixelToRem(40);

    @include media-breakpoint-up(lg) {
        margin-top: pixelToRem(80);
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: pixelToRem(6);

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include media-breakpoint-up(lg) {
            gap: pixelToRem(20);
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;

        gap: pixelToRem(16);

        border: 0.5px solid $grey-40;
        padding: pixelToRem(24);

        font-size: pixelToRem(12);
        line-height: pixelToRem(22);
        text-align: center;
        color: $grey-90;

        @include media-breakpoint-up(lg) {
            text-align: start;
        }

        [class^="icon-"], [class*=" icon-"] {
            font-size: pixelToRem(40);
        }

        .#{$block}__card-icon-wrapper {
            width: pixelToRem(100);
            height: pixelToRem(100);

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }
}
