$block: "navigation";

.#{$block} {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.3s ease;

    .#{$block}__social-links.#{$block}__mobile {
        margin-bottom: pixelToRem(24);

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .#{$block}__social-links.#{$block}__desktop {
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
        }
    }

    &__social-links {
        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;

            // override container
            max-width: initial;
            width: auto;
            padding: 0;
            margin: 0;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: pixelToRem(8);

            @include media-breakpoint-up(md) {
                margin-left: pixelToRem(24);
            }

            a {
                color: $grey;
                text-decoration: none;
                font-size: pixelToRem(48);

                @include media-breakpoint-up(md) {
                    font-size: pixelToRem(24);
                }
            }

            a.navigation__icon-youtube {
                padding: pixelToRem(7);
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    margin-top: pixelToRem(-0.1);
                    width: pixelToRem(34);
                    height: pixelToRem(34);
                }

                @include media-breakpoint-up(md) {
                    padding: pixelToRem(3);

                    img {
                        margin-top: pixelToRem(-0.1);
                        width: pixelToRem(17);
                        height: pixelToRem(17);
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        & ::-webkit-scrollbar {
            display: none;
        }
    }

    // workaround for switching from backdrop color to white for hovering on links
    &:hover {
        background-color: white;
        // transition: background-color 0.5s ease
    }

    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-color: rgba(255, 255, 255, 0.92); // DES initial: 0.85
        backdrop-filter: blur(10px);

        // overwrite workaround styling to show blur effect
        @supports ((backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
            backdrop-filter: blur(10px);
            background-color: rgba(255, 255, 255, 0.7);
        }

        &.noBackground {
            background-color: rgba(255, 255, 255, 0);
            backdrop-filter: blur(0);
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;
    }

    &__logo {
        margin-right: auto;
        margin: 16px 0;

        &-img {
            height: 56px;
            display: block;

            & :hover {
                outline: none;
            }

            & :focus {
                outline: none;
            }

            & :active {
                outline: none;
            }
        }
    }

    // support links
    &__misc {
        display: flex;
        align-content: center;

        .#{$block} {

            &__login {
                display: none;
            }

            &__customer {
                display: none;
            }

            &__language-select {
                @include button-reset();
                @include font;
                padding: 12px;
                font-size: 16px;
                letter-spacing: 0.58px;
                display: flex;
                align-items: center;
            }

            &__search {
                @include button-reset();
                padding: 12px;
                font-size: 25px;
                line-height: 1;

                @include media-breakpoint-only(xl) {
                    font-size: 20px;
                    padding: 12px 0;
                }

                &::before {
                    @include font-icons;
                    content: $icon-ico_search;
                }
            }

            &__toggle {
                @include button-reset();
                padding: 12px;
                margin-right: -12px;
                // font-size: 47px;
                font-size: pixelToRem(31);
                line-height: 1;

                @include media-breakpoint-up(xl) {
                    display: none;
                }

                &::before {
                    @include font-icons;
                    content: $icon-ico_burger;
                }
            }
        }
    }

    // containing __content (main links and custom links) and __misc
    &__wrapper {
        display: flex;

        @include media-breakpoint-up(xl) {
            width: 100%;
        }
    }

    // containing main links and custom links
    &__content {
        display: none;
        height: calc(100vh - 88px);
        padding-bottom: pixelToRem(160);

        @include media-breakpoint-up(md) {
            padding-bottom: 0;
            display: flex;
            height: auto;
        }

        @include media-breakpoint-up(xl) {
            justify-content: space-between;
            width: 100%;
        }

        .#{$block} {

            // main links
            &__main {
                z-index: 1;

                @include media-breakpoint-up(md) {
                    display: none;
                    background-color: white;
                    height: calc(100vh - 88px);
                    // overflow-x: hidden;
                    // overflow-y: auto;
                }

                @include media-breakpoint-up(xl) {
                    display: flex;
                    align-items: center;
                    height: auto;
                    background-color: transparent;
                }

                &-container {
                    padding-top: 48px;

                    @include media-breakpoint-up(xl) {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 100%;
                        padding: 0;
                    }

                    @include media-breakpoint-up(xl) {
                        padding: 0 pixelToRem(20);
                    }



                    .#{$block} {
                        &__link-group {

                            @include media-breakpoint-up(xl) {
                                display: flex;
                                // fix for IE
                                &.active-lg {
                                    height: 88px;
                                }
                            }

                            &-title {
                                @include button-reset();
                                @include font;
                                font-size: 30px;
                                letter-spacing: 0.7px;
                                padding: 16px 0;
                                width: 100%;
                                text-align: left;
                                align-items: center;
                                display: flex;
                                justify-content: space-between;

                                @include media-breakpoint-up(xl) {
                                    font-size: pixelToRem(14);
                                    letter-spacing: 0.2px;
                                    padding: 8px;
                                    height: 100%;

                                    .icon-ico_chevron-right {
                                        display: none;
                                    }
                                }

                                @include media-breakpoint-up(xl) {
                                    font-size: pixelToRem(16);
                                    letter-spacing: 0.7px;
                                    padding: 12px;
                                }

                                &.selected {
                                    color: $orange;
                                }
                            }

                            &:hover {
                                @include media-breakpoint-up(xl) {
                                    color: $orange;
                                }
                            }

                            &--no-shadow {
                                &::after {
                                    content: none !important;
                                }
                            }

                            &.active {
                                color: $orange;

                                .#{$block}__links {
                                    left: 0;
                                    height: calc(100vh - 88px);
                                    overflow-x: hidden;
                                    overflow-y: auto;
                                }
                            }

                            @include media-breakpoint-up(xl) {
                                .animation-show {
                                    opacity: 0;
                                }

                                &.active-lg {
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        top: 100%;
                                        left: 0;
                                        height: calc(100vh - 88px);
                                        right: 0;
                                        background-color: rgba(0, 0, 0, 0.486);
                                        pointer-events: none;
                                    }


                                    .#{$block} {
                                        &__links {
                                            visibility: visible;
                                        }
                                    }

                                    .animation-show {
                                        opacity: 1;
                                        transition-duration: 0.5s;
                                        transition: opacity 0.5s ease;

                                        @for $i from 1 through 30 {
                                            &:nth-child(#{$i}) {
                                                transition-delay:(#{($i+1)*0.1s});
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &__links {
                            background-color: white;
                            position: fixed;
                            top: 88px;
                            left: 100%;
                            right: 0;
                            width: 100vw;
                            flex-direction: column;
                            height: 100%;
                            transition: left 0.5s ease-in;
                            white-space: nowrap;
                            overflow: hidden;
                            z-index: 1;

                            @include media-breakpoint-up(xl) {
                                position: absolute;
                                overflow: auto;
                                // overflow-y: hidden;
                                transition: none;
                                // max-height: 0;
                                background-color: white;
                                padding-bottom: pixelToRem(40);
                                // opacity: 0;
                                // transition: max-height 0.5s ease, opacity 0.5s ease;

                                // &--no-transition {
                                //     transition: none;
                                //     opacity: 1;
                                // }

                                // none active-xl
                                top: 100%;
                                left: 0;
                                right: 0;
                                height: auto;
                                // height: calc(100vh - 88px);
                                background-color: white;
                                // max-height: 100vh;
                                max-height: calc(100vh - 88px);
                                // transition: max-height .5s ease;
                                z-index: 1;
                                opacity: 1;
                                visibility: hidden;
                            }

                            &-wrapper {
                                // more padding for safaris overlay
                                padding-bottom: pixelToRem(160);

                                @include media-breakpoint-up(md) {
                                    padding-bottom: pixelToRem(48);
                                }

                                @include media-breakpoint-up(xl) {
                                    min-height: 0;
                                    transition: min-height 0.5s ease;
                                    padding-bottom: 0;
                                    // to fit under the first link right to the logo
                                    padding-left: pixelToRem(97);
                                    position: relative;
                                }
                            }

                            &-back {
                                @include button-reset();
                                font-size: 30px;
                                padding: pixelToRem(16) 0 pixelToRem(4);
                                color: $grey-90;

                                @include media-breakpoint-up(xl) {
                                    display: none;
                                }
                            }

                            &-headline {
                                @include font;
                                font-size: pixelToRem(30);
                                letter-spacing: 0.7px;
                                color: $orange;
                                margin-left: pixelToRem(24);
                                padding: pixelToRem(12) 0;

                                @include media-breakpoint-up(xl) {
                                    display: none;
                                }
                            }

                            &-link,
                            .#{$block}__accordion-button-text {
                                @include font;
                                font-size: pixelToRem(21);
                                letter-spacing: 0.51px;
                                color: $grey-90;
                                display: block;
                                padding: pixelToRem(12) 0;
                                text-decoration: none;
                                margin-left: pixelToRem(24);
                                line-height: normal;
                                white-space: initial;

                                @include media-breakpoint-up(xl) {
                                    font-size: pixelToRem(16);
                                    padding: pixelToRem(10) 0;
                                    margin-left: 0;
                                }

                                &:hover {
                                    @include media-breakpoint-up(xl) {
                                        color: $orange;
                                    }
                                }
                            }

                            &-link {

                                @include media-breakpoint-up(xl) {
                                    width: 35%;
                                }
                            }

                            .#{$block}__accordion {
                                width: 100%;
                                color: $grey-90;

                                @include media-breakpoint-up(xl) {
                                    display: block;
                                    width: 35%;

                                    &:hover {
                                        .#{$block} {
                                            &__sublinks {
                                                visibility: visible;

                                                &-link {
                                                    @include media-breakpoint-up(xl) {
                                                        opacity: 1;
                                                        transition-duration: 0.5s;
                                                        transition: opacity 0.5s ease;

                                                        @for $i from 1 through 30 {
                                                            &:nth-child(#{$i}) {
                                                                transition-delay:(#{($i+1)*0.1s});
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &__accordion {
                                                &-icon {
                                                    color: $orange;
                                                }

                                                &-button-text {
                                                    color: $orange;
                                                }
                                            }
                                        }
                                    }
                                }

                                &-button {
                                    @include button-reset();
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;


                                    // @include media-breakpoint-up(xl) {
                                    //     align-items: center;
                                    // }


                                    &-text {
                                        // width: 100%;
                                        text-align: left;
                                        position: relative;

                                        // underline styling
                                        &::before {
                                            bottom: 10px;
                                            display: block;
                                            content: "";
                                            height: 2px;
                                            left: 0;
                                            position: absolute;
                                            background: $grey-90;
                                            transition: width 0.3s ease 0s, right 0.3s ease 0s;
                                            width: 0;
                                            transform: scaleX(0);
                                        }
                                    }
                                }

                                &-icon {
                                    @include font-icons;
                                    // color: $grey-90;
                                    font-size: 30px;

                                    &::after {
                                        content: $icon-ico_down;
                                    }

                                    @include media-breakpoint-up(xl) {
                                        display: flex;

                                        &::after {
                                            content: $icon-ico_chevron-right;
                                            margin-left: pixelToRem(16);
                                            font-size: pixelToRem(21);
                                        }
                                    }
                                }

                                &:not(.collapsed) {
                                    .#{$block}__accordion-button-text {

                                        // underline styling
                                        &::before {
                                            width: 100%;
                                            right: 0;
                                            transform: scaleX(1);
                                        }

                                        @include media-breakpoint-up(xl) {
                                            text-decoration: none;

                                            &::before {
                                                content: none;
                                            }
                                        }
                                    }

                                    .#{$block}__accordion-icon {
                                        transform: rotate(180deg);

                                        @include media-breakpoint-up(xl) {
                                            transform: none;
                                        }
                                    }
                                }
                            }
                        }

                        &__sublinks {
                            @include media-breakpoint-up(xl) {
                                display: block;
                                visibility: hidden;
                                transition: none;
                                position: absolute;
                                left: 35%;
                                top: 0;
                                width: 35%;
                                padding-left: pixelToRem(40);
                                min-height: 100%;
                            }

                            &-link {
                                @include font;
                                font-size: pixelToRem(21);
                                letter-spacing: 0.51px;
                                color: $grey-lighter;
                                display: block;
                                padding: pixelToRem(12) 0;
                                text-decoration: none;
                                margin-left: pixelToRem(56);
                                line-height: normal;
                                white-space: normal;

                                &:hover {
                                    color: $orange;
                                }

                                @include media-breakpoint-up(xl) {
                                    font-size: pixelToRem(16);
                                    padding: pixelToRem(10) 0;
                                    margin: 0;
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }
            }

            &__close {
                @include button-reset();
                display: none;
                margin-left: auto;
                margin-bottom: pixelToRem(54);
                color: $grey-90;
                font-size: pixelToRem(36);

                @include media-breakpoint-up(xl) {
                    display: block;
                }
            }

            &__custom-links {
                padding-bottom: 0;

                @include media-breakpoint-up(md) {
                    padding-bottom: pixelToRem(80);
                    display: flex;
                    padding: 0;
                }

                .#{$block} {
                    &__customer {
                        border-top: 2px solid $light-gray2;
                        border-bottom: 2px solid $light-gray2;
                        margin-top: pixelToRem(30);
                        margin-bottom: pixelToRem(12);

                        @include media-breakpoint-up(md) {
                            display: flex;
                            align-items: center;
                            margin: 0;
                            border: none;
                        }

                        .container {
                            @include media-breakpoint-up(md) {
                                max-width: initial;
                                width: auto;
                                padding: 0;
                                margin: 0;
                            }
                        }

                        &-link {
                            @include font;
                            display: flex;
                            justify-content: space-between;
                            padding: pixelToRem(24) 0;
                            color: $gray;
                            font-size: pixelToRem(20);
                            letter-spacing: 0.64px;
                            text-decoration: none;

                            @include media-breakpoint-up(md) {
                                @include cta-button-primary-small;
                                border-radius: 50px;
                                font-size: pixelToRem(16) !important;
                                line-height: 1.5 !important;
                                margin: pixelToRem(12);
                            }

                            @include media-breakpoint-only(xl) {
                                font-size: pixelToRem(12) !important;
                                margin: 0;
                                padding: pixelToRem(3) pixelToRem(15);
                            }

                            &::after {
                                @include font-icons;
                                content: $icon-ico_next;

                                @include media-breakpoint-up(md) {
                                    content: none;
                                }
                            }

                            @include media-breakpoint-up(md) {
                                &--hollow {
                                    background: $white;
                                    border: 1px solid $grey-30;
                                    color: $black;

                                    &:hover {
                                        background: $orange;
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }

                    &__login {

                        @include media-breakpoint-up(md) {
                            display: flex;
                            align-items: center;

                            // override container
                            max-width: initial;
                            width: auto;
                            padding: 0;
                            margin: 0;
                        }

                        &-link {
                            @include font;
                            padding: pixelToRem(10) 0;
                            display: block;
                            text-decoration: none;
                            color: $gray;
                            font-size: pixelToRem(20);
                            letter-spacing: 0.64px;

                            @include media-breakpoint-up(md) {
                                padding: pixelToRem(12);
                                color: $grey-90;
                                font-size: pixelToRem(16);
                                letter-spacing: 0.58px;
                                height: 100%;
                                display: flex;
                                align-items: center;
                            }

                            @include media-breakpoint-only(xl) {
                                padding: pixelToRem(10);
                                font-size: pixelToRem(14);
                            }
                        }
                    }
                }
            }
        }
    }

    // Styling for opened navigation
    &--open {
        background-color: white;

        .#{$block} {
            &__misc {
                display: flex;
            }

            &__toggle {
                &::before {
                    content: $icon-ico_clear;
                }
            }

            &__content {
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                background-color: white;
                height: calc(100vh - 88px);
                overflow-x: hidden;
                overflow-y: auto;

                @include media-breakpoint-up(md) {
                    display: flex;
                    position: static;
                    top: auto;
                    left: auto;
                    right: auto;
                    height: auto;
                }

                &.no-scroll {
                    overflow: hidden;
                }
            }

            &__main {
                @include media-breakpoint-up(md) {
                    display: block;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                }

                &-container {
                    display: flex;
                    flex-direction: column;
                    // height: calc(100vh - 88px);
                    // overflow: auto;
                }
            }

            &__language-select,
            &__search {
                display: none;
            }

            &__custom-links {
                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }
    }

    &--smaller {
        @include media-breakpoint-down(md) {
            .#{$block}__logo {
                margin: pixelToRem(8) 0;
                &-img {
                    height: pixelToRem(44);
                }
            }
        }
    }
}
