$block: 'spacer';

.#{$block} {
	position: relative;
    width: 100%;

	$margins: 80, 60, 40, 20;

	@each $margin in $margins {
		@each $breakpoint, $noNeed in $container-max-widths {
			&--#{$breakpoint}-#{$margin} {
				margin-bottom: pixelToRem(-$margin);

				.#{$block}__editorId {
					top: pixelToRem(-$margin);
				}
			}
		}
	}

	&__editorId {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: pixelToRem(20);
		z-index: 20;
	}
}
