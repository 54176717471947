$block: "cross-selling-header";

.#{$block} {
    &__wrapper {
        position: relative;
        overflow: hidden;
        height: calc((1 / 3) * 100vw);

        @media (min-width: 1920px) {
            height: 700px;
        }

        &--max-width {
            max-width: pixelToRem(1920);
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__image {
        position: relative;
        height: 100%;
        width: 100%;
        display: block;

        img {
            object-fit: cover;
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    &__headline-wrapper {
        position: absolute;
        top: 50%;
        left: 0%;
        right: pixelToRem(24);
        transform: translate(0%, -50%);
        z-index: 10;
        text-align: left;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include media-breakpoint-down(md) {
            margin-left: pixelToRem(24);
        }

        @include media-breakpoint-up(lg) {
            left: 5%;
        }

        @include media-breakpoint-up(xl) {
            left: 15%;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__headline-wrapper__mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: pixelToRem(24);
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__slogan {
        position: absolute;
        right: 0;
        bottom: 0;
        color: white;
        font-size: pixelToRem(12);
        font-weight: 500;

        margin-right: pixelToRem(16);
        margin-bottom: pixelToRem(16);

        @include media-breakpoint-up(md) {
            font-size: pixelToRem(18);
        }

        @include media-breakpoint-up(lg) {
            margin-right: pixelToRem(72);
            margin-bottom: pixelToRem(30);
        }

        text-transform: uppercase;

        animation: fadeIn 1.5s;

        @keyframes fadeIn {
            from {
                opacity: 0;
            }

            to {
                opacity: 0.9;
            }
        }
    }

    &__headline {
        @include h1;
        color: inherit;
        display: inline-block;
        font-size: pixelToRem(22);
        font-weight: bold;
        line-height: pixelToRem(24);
        max-width: pixelToRem(449);

        @include media-breakpoint-up(md) {
            font-size: pixelToRem(32);
            line-height: pixelToRem(32);
        }

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(40);
            line-height: pixelToRem(48);
        }

        @include media-breakpoint-up(xl) {
            font-size: pixelToRem(48);
            line-height: pixelToRem(57);
        }

        em {
            font-weight: bold;
        }

        &__mobile {
            color: $grey-90;
            font-size: pixelToRem(24);
            line-height: pixelToRem(30);
            font-weight: 700;
        }

        &--big {
            font-style: normal;
            font-weight: 700;
            font-size: pixelToRem(48);
            line-height: pixelToRem(40);
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                line-height: pixelToRem(50);
                text-transform: uppercase;
                max-width: pixelToRem(620);
            }

            @include media-breakpoint-up(xl) {
                font-size: 108px;
                line-height: 100px;
                max-width: pixelToRem(849)
            }
        }
    }

    &__subheadline {
        display: inline-block;
        word-break: break-word;
        max-width: 365px;
        margin-top: pixelToRem(21);

        font-size: 18px;
        line-height: pixelToRem(24);

        em {
            font-weight: bold;
        }

        &__mobile {
            color: $grey-90;
            line-height: pixelToRem(24);
            margin-top: pixelToRem(16);
        }
    }

    &__curtain {
        width: 150%;
        height: 100%;
        top: 0;
        position: absolute;
        animation-duration: 1.5s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        // required for Image Cropper in FS
        pointer-events: none;
        transform-origin: top left;

        @include media-breakpoint-up(lg) {
            left: 60%;
            top: 0;
        }

        &--top-down {
            background: linear-gradient(0deg, rgba(0, 128, 178, 0) 0%, $card-button-blue 80%);
            opacity: 0.6;
            animation-name: bottomUp;
            left: 35%;

            @include media-breakpoint-up(xl) {
                left: 50%;
            }

            @keyframes bottomUp {
                from {
                    transform: skew(-18.5deg) translateY(150%);
                }

                to {
                    transform: skew(-18.5deg) translateY(0);
                }
            }
        }

        &--bottom-up {
            transform: skew(-16.5deg);
            background: linear-gradient(180deg, rgba(0, 73, 123, 0) 0%, $card-button-blue 100%);
            opacity: 0.75;
            animation-name: topDown;

            @keyframes topDown {
                from {
                    transform: skew(-16.5deg) translateY(-150%);
                }

                to {
                    transform: skew(-16.5deg) translateY(0);
                }
            }
        }

        &--dark {
            background: $card-button-blue;
            opacity: 0.9;
        }

        &--slimmer {
            left: 62.5%;

            @include media-breakpoint-up(sm) {
                left: 72.5%;
            }

            @include media-breakpoint-up(md) {
                left: 82.5%;
            }

            @include media-breakpoint-up(xl) {
                left: 90%;
            }
        }
    }

    &__country {
        &-container {
            position: absolute;
            bottom: 12.5%;
            left: 0;
            right: 0;
            z-index: 10;
        }

        &-col {
            display: flex;
            align-items: center;
        }

        &-icon {
            display: block;
            width: auto;
            height: pixelToRem(32);
            overflow: hidden;
            border: pixelToRem(2) solid white;
        }

        &-abbr {
            font-size: pixelToRem(24);
            margin-left: pixelToRem(16);
        }
    }

    &--lvl-2 {
        .#{$block} {

            &__headline,
            &__subheadline {
                em {
                    color: $white;
                }
            }

            &__curtain--top-down {
                display: none;
            }

            &__curtain--bottom-up {
                background-color: $blue;
                opacity: 0.8;
                animation: none;
            }
        }
    }

    &--lvl-3 {
        height: pixelToRem(280);

        .#{$block} {

            &__gradient,
            &__curtain,
            &__headline-wrapper {
                display: none;
            }
        }
    }

    &--bg-blue {
        .#{$block} {
            &__gradient {
                display: none;
            }

            &__image {
                width: 50%;
            }

            &__curtain--bottom-up {
                background: none;
                background-color: $blue;
                opacity: 1;
            }
        }
    }

    &--bg-light-blue {
        .#{$block} {
            &__gradient {
                display: none;
            }

            &__image {
                width: 50%;
            }

            @include media-breakpoint-up(lg) {

                &__headline,
                &__subheadline {
                    color: $black;

                    em {
                        color: $black;
                    }
                }
            }

            &__curtain--bottom-up {
                opacity: 1;

                @include media-breakpoint-up(lg) {
                    background: none;
                    background-color: $blue_20;
                }
            }
        }
    }

    &__badge {
        position: absolute;
        left: $spacer;
        bottom: $spacer;
        height: pixelToRem(80);
        z-index: 11;

        @include media-breakpoint-up(md) {
            bottom: $spacer*2;
            right: $spacer*2;
            left: auto;
            height: pixelToRem(110);
        }

        @include media-breakpoint-up(xl) {
            height: pixelToRem(125);
        }

        @media (min-width: 1600px) {
            height: pixelToRem(150);
            right: 8rem;
        }
    }

    &__ctaContainer {
        width: 100%;
        font-size: 16px;
        position: relative;
        z-index: 2;
        text-align: center;

        @include media-breakpoint-up(md) {
            position: absolute;
            left: 0;
            bottom: 2rem;
            margin-bottom: pixelToRem(66);
        }
    }

    &__light {
        color: white;
    }

    &__dark {
        color: $grey-90;
    }

    .cta-button {
        display: inline-block;
        margin-top: pixelToRem(32);

        @include media-breakpoint-up(md) {
            margin-top: 3rem;
        }
    }
}
