﻿$block: 'image-teaser-centered';

.#{$block} {
  @include module-spacing;

  .layout5050__col & {
    padding-top: 0px;
  }

  &__headline {
    @include h3;
    background-color: $background-grey;
    padding: pixelToRem(24) pixelToRem(16);

    @include media-breakpoint-up(md) {
      padding: pixelToRem(32) pixelToRem(24);
    }

    @include media-breakpoint-up(xl) {
      padding: pixelToRem(48);
      padding-bottom: pixelToRem(32);
    }
  }

  &--bg-gray {
    background-color: $background-grey;
  }

  &__figure {
    padding-left: pixelToRem(16);
    padding-right: pixelToRem(16);

    @include media-breakpoint-up(md) {
      padding-left: pixelToRem(24);
      padding-right: pixelToRem(24);
    }

    @include media-breakpoint-up(xl) {
      padding-left: pixelToRem(32);
      padding-right: pixelToRem(32);
    }
  }

  &__image {
    width: 100%;
    display: block;
    margin: auto;

    @include media-breakpoint-up(md) {
      width: 75%;
    }

    @include media-breakpoint-up(xl) {
      width: 100%;
    }
  }
}
