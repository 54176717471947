$block: 'beratungsmodul';

.#{$block} {
	@include module-spacing;
	padding-bottom: 0 !important;

	&__title {
		@include h1;
		margin-bottom: pixelToRem(16);

		em {
			font-weight: bold;
		}
	}

	&__text {
		@include format(text);
		margin-bottom: pixelToRem(40);
		
		@include media-breakpoint-up(md) {
			margin-bottom: pixelToRem(56);
		}
		
		@include media-breakpoint-up(lg) {
			margin-bottom: pixelToRem(40);
		}
	}
}
