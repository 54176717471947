$block: "banner";

.#{$block} {
    &--bg-blue {
        background-color: $blue;
    }
    
    @include module-spacing;

    &__headline {
        @include format(headline);
        margin-bottom: pixelToRem(40);
        color: white;

        @include media-breakpoint-up(md) {
            margin-bottom: pixelToRem(20);
        }

        &--h2 {
            color: white;
        }
        
        em {
            color: white;
            font-weight: bold;
        }
    }

    &__text {
        @include format(text);
        font-size: pixelToRem(16)!important;
        line-height: 1.13;
        letter-spacing: 0.4px;
        font-weight: normal;
        margin-bottom: pixelToRem(40);
        color: white;

        @include media-breakpoint-up(md) {
            margin-bottom: pixelToRem(20);
        }
    }
}