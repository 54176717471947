$block: "drei-bilder-text";

.#{$block} {
  @include module-spacing;

  &__headline {
    @include format(headline);
    font-weight: bold!important;
    margin-bottom: pixelToRem(17);
  }

  &__text {
    @include format(text);
  }

  &__image-wrapper {
    margin-top: pixelToRem(40);
    margin-bottom: pixelToRem(16);

    @include media-breakpoint-up(md) {
      text-align: center;
    }

    @include media-breakpoint-up(lg) {
      text-align: left;
    }

  }

  &__cols {
    margin: 0 !important;
    padding-right: pixelToRem(11) !important;

  }

  &__image {
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid $grey-40;
    height: pixelToRem(193);
    margin-bottom: 1rem;
  }

  &--bg-gray {
    background-color: $background-grey;
  }

}
