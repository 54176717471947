$block: 'h1-textmodul';

.#{$block} {
    @include module-spacing;

    &__title {
        @include format(headline);
        margin-top: pixelToRem(2);
        margin-bottom: pixelToRem(26);
    }

    &__text {
        @include format(text);
        margin-bottom: pixelToRem(-16);

        li {
            padding-bottom: pixelToRem(1);
        }

        .cta-button {
            margin-top: pixelToRem(15);
        }
    }

    &__cta-button {
        color: white !important;
        padding-bottom: 5px;
    }

    &__arrowLink {
        @include format(text);
        font-size: pixelToRem(16)!important;
        font-weight: 500;
        margin-top: pixelToRem(9)!important;

    }

    &__list {
        @include format(text);
    }

    &--bg-gray {
        background-color: $background-grey;
    }

    & table tbody tr:first-child td {
        text-align: left;
        font-weight: 500;
        border: 1px solid $white;
        background-color: $blue_20;
        color: $blue;
        padding-top: 0.6875rem;
        padding-bottom: 0.6875rem;
        padding-left: 1rem;
        line-height: 1.5;
        letter-spacing: 0.4px;
    }

}
