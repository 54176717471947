$block: 'comfort-address-input';

.#{$block} {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-modal;

  &.show {
    display: block;
  }

  &__button-wrapper {
    margin-bottom: $spacer;
  }

  &__close {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    i {
      &::before {
        font-size: pixelToRem(28);
      }
    }
  }

  &__close-wrapper {
    margin-bottom: $spacer / 2;
    text-align: right;
  }

  &__coordinates-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__input-container {
    margin-bottom: pixelToRem(32);
  }

  &__map {
    height: 500px;
  }

  &__overlay {
    background-color: rgba(0, 0, 0, .5);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $z-index-modal-overlay;
  }

  &__title {
    font-weight: $font-weight-bold;
    margin-bottom: pixelToRem(32);
  }

  &__wrapper {
    background-color: $white;
    box-shadow: 0 pixelToRem(10) pixelToRem(20) 0 rgba(128, 128, 128, 0.2);
    height: 100%;
    left: 0;
    overflow-y: auto;
    padding: $spacer * 2;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-modal-wrapper;
  }

  @include media-breakpoint-up(lg) {
    &__wrapper {
      height: $modal-height;
      left: calc(50% - (#{$modal-width} / 2));
      top: calc(50% - (#{$modal-height} / 2));
      max-width: $modal-width;
    }
  }
}