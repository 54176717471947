$block: "interaktive-landkarte";

.#{$block} {
    @include module-spacing;

    &__headline {
        @include format(headline);
        font-weight: bold !important;
        margin-bottom: pixelToRem(16);
    }

    &__text {
        @include format(text);
        color: $grey;
        line-height: 1.5;
        letter-spacing: 0.5px;
    }

    &__form-group {
        margin-bottom: pixelToRem(80);
        padding-top: pixelToRem(40);
    }

    &__select {
        height: pixelToRem(44);
        padding-top: pixelToRem(13);
        font-size: pixelToRem(16);
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: none;

        li {
            margin-bottom: pixelToRem(24);
        }

        li:last-child {
            margin-bottom: pixelToRem(0);
        }

    }


    &__svg {
        display: block;
        width: 100%;
    }

    &__cls-1,
    &__cls-2,
    &__cls-3 {
        fill: #d4d4d4;
        stroke: $white;
        stroke-miterlimit: 10;

        &:hover {
            fill: #206289 !important;
            stroke: $background-grey;
            stroke-width: 0.5px;
            cursor: pointer;
            transition: all .3s;
        }
    }

    &__cls-1 {
        stroke-width: 0.5px;
    }

    &__cls-3 {
        stroke-width: 0.89px;
    }

    &__link {
        padding-top: pixelToRem(35);
        padding-bottom: pixelToRem(35);
        text-align: left;

        @include media-breakpoint-up(md) {
            text-align: center;
        }

        @include media-breakpoint-up(lg) {
            visibility: hidden;
        }
    }

    &__icon {
        font-size: 2rem;
        color: $blue;
    }
    &__icon-text {
        display: flex;
        flex-direction: row;
        font-size: pixelToRem(16);
        align-items: center;
        color: $blue;
        font-weight: bold;
    }

    &__mautinformation {
        //display: none;
        background: $background-grey;
        padding-left: pixelToRem(16);
        padding-right: pixelToRem(16);
        opacity: 1;
        z-index: 100;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            padding-left: pixelToRem(38);
            padding-right: pixelToRem(38);
        }

        @include media-breakpoint-up(lg) {
            margin-top: 0px;
            width: pixelToRem(464);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: .25s;

            &--align-left {
                left: 0;
                animation-name: SlideFadeIn-left;
            }

            &--align-right {
                right: 0;
                animation-name: SlideFadeIn-right;
            }

        }

        &-fade-out-left {
            @include media-breakpoint-up(lg) {
                opacity: 0;
                left: -100%;
                animation-name: SlideFadeOut-left;
                animation-iteration-count: 1;
                animation-timing-function: ease-out;
                animation-duration: .25s;
            }
        }

        &-fade-out-right {
            @include media-breakpoint-up(lg) {
                opacity: 0;
                right: -100%;
                animation-name: SlideFadeOut-right;
                animation-iteration-count: 1;
                animation-timing-function: ease-out;
                animation-duration: .25s;
            }
        }

        table {
            table {
                font-size: pixelToRem(16);
                border-collapse: collapse;
                border-style: hidden;
                border-bottom-style: initial;
                margin-left: 0;
                margin-right: 0;
                width: auto;
        
                tbody tr:first-child td {
                    background-color: transparent;
                    font-weight: normal;
                    border-bottom: 1px solid #979797;
                    color: $grey;
                    padding: pixelToRem(8);
                }

            }

            tr { 
                background-color: transparent;
                font-weight: normal;
                border-bottom: 1px solid #979797;
                color: $grey;
                padding: pixelToRem(8);
            }

            th {
                padding-right: 25px;
            }
        
            td {
                color: $grey;
                border-bottom: 1px solid #979797;
                
                border-left: 1px solid #979797;
                padding: pixelToRem(8);
        
                &[colspan]:not([colspan="1"]) {
                    text-align: center;
                }
            }
        }
    }
    
    @keyframes SlideFadeIn-left {
        0% {
            opacity: 0;
            left: -25%;
            transform: translateY(-50%);
        }
        100% {
            opacity: 1;
            left: 0;
            transform: translateY(-50%);
        }
    }
    
    @keyframes SlideFadeIn-right {
        0% {
            opacity: 0;
            right: -25%;
            transform: translateY(-50%);
        }
        100% {
            opacity: 1;
            right: 0;
            transform: translateY(-50%);
        }
    }

    @keyframes SlideFadeOut-left {
        0% {
            opacity: 1;
            left: 0;
            transform: translateY(-50%);
        }
        100% {
            opacity: 0;
            left: -25%;
            transform: translateY(-50%);
        }
    }



    @keyframes SlideFadeOut-right {
        0% {
            opacity: 1;
            right: 0;
            transform: translateY(-50%);
        }
        100% {
            opacity: 0;
            right: -25%;
            transform: translateY(-50%);
        }
    }

    .information {
        display: none;
    }

    select {
        -moz-appearance: none !important; 
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-left: 0.8125rem;
        height: 2.75rem;
        font-size: 1rem;
        border: 1px solid lightgray !important;
        border-radius: 0.25rem;
        cursor: pointer;
    }

    // OVERWRITE ".nice-select" DEFAULT STYLING
    .nice-select:after {
        border-bottom: 2px solid $grey-90;
        border-right: 2px solid $grey-90;
        height: pixelToRem(10);
        right: pixelToRem(15);
        top: 45%;
        width: pixelToRem(10);
    }

    .nice-select .option {
        cursor: pointer;
        line-height: pixelToRem(30);
        min-height: pixelToRem(30);
    }

    .nice-select .list {
        width: 100%;
        height: pixelToRem(350);
        overflow-y: scroll;
    }

}

// OVERWRITE ".context-menu" DEFAULT STYLING
.context-menu-icon-link {
    @include cta-button-animated;

    &::before {
        margin-top: pixelToRem(7);
        height: pixelToRem(2);
    }
}

.context-menu-list {
    background: $background-grey;
    border: none;
    border-radius: 0px !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-top: pixelToRem(8);
    padding-bottom: pixelToRem(6);
    padding-left: pixelToRem(12);
}

.context-menu-item {
    margin: 0px !important;
    padding: 0px !important;
    user-select: none;
    background-color: $background-grey !important;
}

li .context-menu-hover {
    background-color: none !important;
}

.context-menu-icon-title {
    padding-top: pixelToRem(7);
    color: $grey !important;
    font-size: pixelToRem(20);
    letter-spacing: 0.5px;
}

.highlight {
    fill: $blue !important;
    stroke: $background-grey;
    stroke-width: 0.5px;
    cursor: pointer;
    transition: all .3s;
}

.noaction:hover {
    fill: #d4d4d4 !important;
    stroke: $white !important;
    stroke-miterlimit: 10 !important;
    cursor: default !important;
}

