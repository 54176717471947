$block: "led-wall-page";

.#{$block} {
  main > :first-child {
    margin-top: 0;
  }

  #version-info,
  #js-debug,
  #mediaquery {
    display: none;
  }
}
