$block: 'bild-parallax-scrolling';

.#{$block} {
    @include module-spacing-margin();

    height: pixelToRem(440);
    overflow: hidden;
    
    &__gradient {
        height: 100%;
        position: relative;
    }
    
    &__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }

    .container {
        height: 100%;
    }

    .row {
        height: 100%;
        position: relative;
    }

    &__content {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        background-color: $white;
        padding: pixelToRem(32) pixelToRem(40);
        max-width: pixelToRem(560);

    }

    &__headline {
        @include format(headline);
        margin-bottom: pixelToRem(8);

        em {
            font-weight: bold;
        }
    }

    &__text {
        @include format(caption);
        margin-bottom: pixelToRem(24);
    }

    &--dark {
        .#{$block} {
            &__gradient {
                background-image: linear-gradient(to right, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 89%);
            }

            &__content {
                max-width: initial;
                background: none;
                padding: 0;
            }

            &__headline,
            &__text {
                color: $white;
            }
        }
    }
}