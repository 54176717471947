$block: "blog-header";

.#{$block} {
    max-width: pixelToRem(1920);
    margin-right: auto;
    margin-left: auto;

    &__wrapper {
        min-height: pixelToRem(550);

        @include media-breakpoint-up(sm) {
            min-height: pixelToRem(600);
        }

        @include media-breakpoint-up(md) {
            min-height: auto;
            height: pixelToRem(330);
        }

        @include media-breakpoint-up(lg) {
            height: pixelToRem(400);
        }

        @include media-breakpoint-up(xl) {
            height: pixelToRem(500);
        }
    }

    &__rectangle {
        position: relative;
        overflow: hidden;
        min-height: pixelToRem(300);
        background: $blue;

        @include media-breakpoint-up(md) {
            flex-basis: 50%;
        }
    }

    &__curtain {
        width: 150%;
        height: 100%;
        top: 0;
        position: absolute;
        animation-duration: 1.5s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        // required for Image Cropper in FS
        pointer-events: none;
        transform-origin: top left;

        &--top-down {
            background: $blue;
            opacity: 0.8;
            z-index: 2;
            left: 70%;
            animation-name: blog-curtain--top-down;

            @keyframes blog-curtain--top-down {
                from {
                    transform: skew(-16.5deg) translateY(-150%);
                }

                to {
                    transform: skew(-16.5deg) translateY(0);
                }
            }

            @include media-breakpoint-up(md) {
                left: 70%;
            }
        }

        &--bottom-up {
            background: $blue-hover;
            z-index: 1;
            left: 30%;
            clip-path: polygon(0 0, 8% 100%, 100% 38%);
            animation-name: blog-curtain--bottom-up;

            @keyframes blog-curtain--bottom-up {
                from {
                    transform: translateY(150%);
                }

                to {
                    transform: translateY(30%);
                }
            }

            @include media-breakpoint-up(sm) {
                left: 40%;
            }

            @include media-breakpoint-up(md) {
                left: 10%;
                clip-path:  polygon(0 0, 100% 36%, 100% 100%, 14% 100%);
                animation-name: blog-curtain--bottom-up;

                @keyframes blog-curtain--bottom-up {
                    from {
                        transform: translateY(150%);
                    }

                    to {
                        transform: translateY(15%);
                    }
                }
            }
        }
    }

    &__content-wrapper {
        position: relative;
        z-index: 3;
        max-width: pixelToRem(368);
        margin: pixelToRem(36) pixelToRem(25) pixelToRem(38);

        @include media-breakpoint-up(md) {
            max-width: pixelToRem(544);
            margin: pixelToRem(36) pixelToRem(42) pixelToRem(38);
        }

        h1 {
            color: $white;
            font-style: normal;
            font-weight: 400;
            font-size: pixelToRem(24);
            line-height: pixelToRem(36);
            letter-spacing: pixelToRem(1.2);
            margin-bottom: pixelToRem(16);

            @include media-breakpoint-up(lg) {
                font-style: normal;
                font-weight: 300;
                font-size: pixelToRem(40);
                line-height: pixelToRem(49);
            }

            em {
                font-weight: 700
            }
        }
    }

    &__paragraph {
        color: $white;
        font-style: normal;
        font-weight: 400;
        font-size: pixelToRem(16);
        line-height: pixelToRem(23);
        letter-spacing: pixelToRem(0.5);
        display: -webkit-box;
        -webkit-line-clamp: var(--max-lines);
        -webkit-box-orient: vertical;
        overflow: hidden;

        em {
            font-weight: 700
        }
    }

    &__ctaContainer {
        .cta-button {
            margin-top: pixelToRem(19);

            &:before {
                background: $white;
            }
        }
    }

    &__image {
        overflow: hidden;
        flex-grow: 1;
        max-height: pixelToRem(320);

        @include media-breakpoint-up(md) {
            flex-basis: 50%;
            width: 100%;
            height: 100%;
            max-height: 100%;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: all .3s ease-in-out;
        }
    }

    &:hover {
        img {
            transform:scale(1.025);
        }
    }
}
