$block: "blog-linkedin";

.#{$block} {
  @include module-spacing;

  overflow-x: hidden;

  &__container {
    display: flex;
    gap: pixelToRem(48);

    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: pixelToRem(24);
    }
  }

  &__info {
    @include media-breakpoint-up(md) {
      max-width: pixelToRem(215);
    }

    &__logo {
      svg {
        width: 40px;
        height: 40px;
      }
    }

    &__headline {
      margin-top: pixelToRem(28);

      font-weight: bold;
      font-size: pixelToRem(24);
      color: $dark-grey;
    }

    scroll-snap-align: start;

    &__text {
      margin-top: pixelToRem(12);

      line-height: pixelToRem(21.5);
    }
  }

  &__list {
    display: flex;
    gap: pixelToRem(24);
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    width: 100vw;

    @include media-breakpoint-down(sm) {
      padding-right: pixelToRem(221 + 24);
    }

    &__post {
      scroll-snap-align: start;
      width: pixelToRem(221);
      min-width: pixelToRem(221);

      &__top-row {
        display: flex;
        align-items: center;
        gap: pixelToRem(8);

        p {
          font-weight: bold;
          line-height: pixelToRem(24);
          color: $dark-grey;
        }
      }

      &__body {
        margin-top: pixelToRem(28);

        p {
          line-height: pixelToRem(21.5);
          letter-spacing: pixelToRem(1.2);

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }

      &__thumbnail {
        margin-top: pixelToRem(16);
        width: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
          aspect-ratio: 1 / 1;
          object-fit: cover;
          object-position: center;
          transition: all .2s ease-in-out;
        }
      }

      .#{$block}__cta {
        margin-top: pixelToRem(16);
      }

      &:hover &__thumbnail img {
        transform: scale(1.025);
      }
    }
  }

  &__cta {
    @include cta-button-animated;
    font-weight: normal;
    margin-top: pixelToRem(8);
  }
}
