/*
this section contains multiple versions: normal (level 1 and 2), level 3 and lvl 4
*/

$block: "intro-header";

.#{$block} {
    position: relative;
    overflow: hidden;

    height: pixelToRem(240);

    @include media-breakpoint-up(md) {
        height: pixelToRem(360);
    }

    @include media-breakpoint-up(lg) {
        height: pixelToRem(480);
    }

    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-blend-mode: multiply;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.45) 0%, transparent 60%); // rgba(0, 0, 0, 0.95)
        z-index: 5;

        @include media-breakpoint-up(xl) {
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.45) 0% 5%, transparent 50%); // rgba(0, 0, 0, 0.95)
        }
    }

    &__image {
        position: relative;
        height: 100%;
        width: 100%;
        display: block;

        img {
            display: block;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &__headline-wrapper {
        position: absolute;
        top: 50%;
        left: pixelToRem(24);
        right: pixelToRem(24);
        transform: translate(0%, -50%);
        z-index: 10;
        text-align: right;

        @include media-breakpoint-up(md) {
            text-align: left;
        }

        @include media-breakpoint-up(lg) {

            left: 55%;
            right: pixelToRem(32);

        }

        @include media-breakpoint-down(md) {
            @include make-container();
        }

        @each $breakpoint,
        $container-max-width in $container-max-widths {
            @if ($breakpoint !=lg and $breakpoint !=xl) {
                @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
                    max-width: $container-max-width;
                }
            }
        }
    }

    &__headline {
        @include h2;
        display: inline;
        word-break: break-word;
        color: $white;
        font-size: pixelToRem(18);

        @include media-breakpoint-up (md) {
            @include h2;
            color: $white;
        }

        @include media-breakpoint-up(lg) {
            @include h1;
            display: block;
            color: $white;
            margin-right: 1rem;
        }

        em {
            font-weight: bold;
            color: $text-emphasized;
        }

    }

    &__subheadline {
        @include h2;
        font-weight: normal;
        margin-top: pixelToRem(8);
        display: inline;
        word-break: break-word;
        color: $white;

        @include media-breakpoint-up(lg) {
            @include h4;
            display: block;
            font-weight: 300;
            line-height: 1.39;
            color: $white;
        }

        em {
            font-weight: bold;
            color: $text-emphasized;
        }
    }

    &__curtain {
        width: 150%;
        height: 100%;
        top: 0;
        position: absolute;
        animation-duration: 1.5s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        // required for Image Cropper in FS
        pointer-events: none;
        transform-origin: top left;

        @include media-breakpoint-up(lg) {
            left: 60%;
            top: 0;
        }

        &--top-down {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, $card-button-blue 80%);
            opacity: 0.6;
            animation-name: bottomUp;
            left: 35%;

            @include media-breakpoint-up(xl) {
                left: 50%;
            }

            @keyframes bottomUp {
                from {
                    transform: skew(-18.5deg) translateY(150%);
                }

                to {
                    transform: skew(-18.5deg) translateY(0);
                }
            }
        }

        &--bottom-up {
            transform: skew(-16.5deg);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $card-button-blue 100%);
            opacity: 0.75;
            animation-name: topDown;

            @keyframes topDown {
                from {
                    transform: skew(-16.5deg) translateY(-150%);
                }

                to {
                    transform: skew(-16.5deg) translateY(0);
                }
            }
        }
    }

    &__country {
        &-container {
            position: absolute;
            bottom: 12.5%;
            left: 0;
            right: 0;
            z-index: 10;
        }

        &-col {
            display: flex;
            align-items: center;
        }

        &-icon {
            display: block;
            width: auto;
            height: pixelToRem(32);
            overflow: hidden;
            border: pixelToRem(2) solid $white;
        }

        &-abbr {
            color: $white;
            font-size: pixelToRem(24);
            margin-left: pixelToRem(16);
        }
    }

    &--lvl-2 {
        .#{$block} {

            &__headline,
            &__subheadline {
                em {
                    color: $white;
                }
            }

            &__curtain--top-down {
                display: none;
            }

            &__curtain--bottom-up {
                background-color: $blue;
                opacity: 0.8;
                animation: none;
            }
        }
    }

    &--lvl-3 {
        height: pixelToRem(280);

        .#{$block} {

            &__gradient,
            &__curtain,
            &__headline-wrapper {
                display: none;
            }
        }
    }

    &--bg-blue {
        .#{$block} {
            &__gradient {
                display: none;
            }

            &__curtain--bottom-up {
                background: none;
                background-color: $blue;
                opacity: 1;
            }
        }
    }

    &--bg-light-blue {
        .#{$block} {
            &__gradient {
                display: none;
            }

            &__headline,
            &__subheadline {
                color: $black;

                em {
                    color: $black;
                }
            }

            &__curtain--bottom-up {
                opacity: 1;
                background: none;
                background-color: $blue_20;
            }
        }
    }

    &--img-half-width {
        .#{$block}__image {
            width: 53%;
        }
    }

    &--md-down-text-right {
        .#{$block}__headline-wrapper {
            @include media-breakpoint-down(md) {
                text-align: right;
            }
        }
    }

    &__badge {
        position: absolute;
        left: $spacer;
        bottom: $spacer;
        height: pixelToRem(80);
        z-index: 11;

        @include media-breakpoint-up(md) {
            bottom: $spacer*2;
            right: $spacer*2;
            left: auto;
            height: pixelToRem(110);
        }

        @include media-breakpoint-up(xl) {
            height: pixelToRem(125);
        }

        @media (min-width: 1600px) {
            height: pixelToRem(150);
            right: 8rem;
        }
    }

    &__ctaContainer {
        width: 100%;
        color: $white !important;
        font-size: 16px;
        color: inherit;
        position: relative;
        z-index: 2;
        text-align: center;

        @include media-breakpoint-up(md) {
            position: absolute;
            left: 0;
            bottom: 2rem;
            margin-bottom: pixelToRem(66);
        }
    }

    &__button {
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
            margin-top: 3rem;
        }
    }
}
