$parent: 'blog-teaser';
$block: 'blog-teaser-slider';

.#{$parent} {
    @include module-spacing;
}

.#{$block} {
    max-width: pixelToRem(1920);
    margin-left: auto;
    margin-right: auto;

    .slick-slide {
        height: auto;
    }

    @include media-breakpoint-down(md) {
        overflow-x: hidden;
    }

    &__container {
        @include media-breakpoint-up(lg) {
            max-width: 100%;
            padding: 0 0;
        }
    }

    &__headline {
        font-size: pixelToRem(24);
        font-weight: 700;
        line-height: pixelToRem(35);
        letter-spacing: pixelToRem(0.9);
        text-align: left;

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(36);
            line-height: pixelToRem(41);
            text-align: center;
        }
    }

    &__carousel {
        margin-top: pixelToRem(21);
        margin-bottom: pixelToRem(20);

        @include media-breakpoint-down(sm) {
            width: calc(299px + 50vw);
        }

        @include media-breakpoint-down(md) {
            width: calc(400px + 50vw);
        }

        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(30);
        }

        @include media-breakpoint-up(lg) {
            margin-top: pixelToRem(40);
        }
    }

    &__carousel-item {
        margin: 0 pixelToRem(19) 0 0;

        @include media-breakpoint-up(lg) {
            margin: 0 pixelToRem(30.1);
        }
    }

    &__item {
        width: 299px;

        @include media-breakpoint-up(md) {
            width: pixelToRem(400);
        }

        @include media-breakpoint-up(lg) {
            width: fit-content;
            position: relative;
            padding-left: pixelToRem(61.2);
        }
    }

    &__image {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        position: relative;
        width: 900px;
        height: 564px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left;
        }
    }

    &__image-mobile {
        @include media-breakpoint-up(lg) {
            display: none;
        }

        position: relative;
        width: 100%;
        height: auto;

        img {
            width: 100%;
            height: pixelToRem(196.17);
            object-fit: cover;
            object-position: left;

            @include media-breakpoint-up(md) {
                height: pixelToRem(265.5);
            }
        }
    }

    &__label-mobile {
        padding: 9px 20px;
        width: fit-content;
        font-weight: 400;
        font-size: pixelToRem(16);
        line-height: pixelToRem(24);

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__label {
        position: absolute;
        top: 0;
        left: 0;

        padding: 9px 20px;
        font-weight: 400;
        font-size: pixelToRem(16);
        line-height: pixelToRem(24);
    }

    &__card {
        width: 100%;

        @include media-breakpoint-up(lg) {
            position: absolute;
            bottom: 50%;
            left: 0;
            transform: translateY(50%);
            width: 310px;
        }

        border: 1px solid $grey-20;

        &__inner {
            padding: pixelToRem(24);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            min-height: pixelToRem(242);

            @include media-breakpoint-up(lg) {
                min-height: pixelToRem(295);
            }
        }

        color: $grey-90;
        background-color: white;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__headline {
            font-weight: 700;
            font-size: pixelToRem(20);
            line-height: pixelToRem(29);
            letter-spacing: pixelToRem(0.9);
            margin-bottom: pixelToRem(16);

            @include media-breakpoint-down(md) {
                height: pixelToRem(60);
                display: -webkit-box;
                -webkit-line-clamp: var(--line-clamp);
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            @include media-breakpoint-up(lg) {
                font-size: pixelToRem(24);
            }

        }

        &__text {
            font-weight: 400;
            font-size: pixelToRem(16);
            line-height: pixelToRem(16);
            letter-spacing: pixelToRem(0.5);
            margin-bottom: pixelToRem(12);

            @include media-breakpoint-up(lg) {
                line-height: pixelToRem(23);
                margin-bottom: pixelToRem(19);
            }

            p {
                display: inline;
            }

            strong, em {
                font-weight: bold;
            }

            i {
                font-style: italic;
            }
        }

        &__link {
            @include cta-button-animated;

            width: fit-content;

            line-height: pixelToRem(24);
            color: $blue;
            text-decoration: none;
        }
    }


    // buttons
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: pixelToRem(32);
        margin-top: pixelToRem(10);
    }

    &__use-dots &__pageInfo {
        display: none;
    }

    &__dots {
        display: none;
    }

    &__use-dots &__dots {
        display: block;

        width: fit-content;
        margin: 0;

        .slick-dots {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            width: fit-content;
            margin: 0;

            & button {
                @include button-reset();
                color: transparent;
                border: 1px solid $grey-60;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin: $spacer*0.5;
            }

            & .slick-active {
                button {
                    background-color: $grey-60;
                }
            }
        }
    }

    &__pageInfo {
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    &__currentPage {
        align-self: flex-start;
        font-weight: bold;
    }

    &__totalPages {
        align-self: flex-end;
    }

    &__slash {
        height: 22px;
        border-left: 1px solid $grey-90;
        transform: rotate(30deg);
    }

    &__arrow {
        width: min-content !important;
        margin: 0;

        color: $grey-60;

        &--prev {
            margin-top: pixelToRem(6);
        }

        &--next {
            margin-top: pixelToRem(4);
        }
    }

    &__arrow-button {
        @include button-reset();
        font-size: pixelToRem(25);
    }

    .slick-disabled {
        visibility: hidden;
    }

}
