$block: "landingpage-shop-header";

.#{$block} {
    &__wrapper {
        width: 100%;
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: clip;

        @include media-breakpoint-up(lg) {
            height: pixelToRem(480);
        }
    }

    &__rectangle {
        display: flex;
        padding-bottom: pixelToRem(24);

        background-color: $grey-10;
        width: 100%;
        height: 100%;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            padding: pixelToRem(32);
        }

        @include media-breakpoint-down(lg) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 0;
            left: 0;

            height: 100%;
            width: calc(67% + 64px);

            clip-path: polygon(0 100%, calc(100% - 160px) 100%, 100% 0, 0 0);

            transform: translate(0, -5%) scaleY(90%);
        }
    }

    &__ctaContainer {
        margin-top: pixelToRem(24);

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;

        height: 100%;
        width: calc(33% + 64px);
        display: block;

        overflow: hidden;

        transform: translate(0, 5%) scaleY(90%);


        img {
            object-fit: cover;
            display: block;
            height: 100%;
            width: 100%;
        }

        clip-path: polygon(0 100%, 100% 100%, 100% 0, 160px 0);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__card-wrapper {
        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-15%, -50%);
        }
    }

    &__card {
        width: 400px;
        height: auto;

        @include media-breakpoint-up(xl) {
            width: 471px;
            height: auto;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__card-wrapper__mobile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: pixelToRem(8);

        max-width: 370px;

        .cta-button {
            font-size: pixelToRem(16);
            padding: pixelToRem(8) pixelToRem(24);
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr;

            .cta-button {
                display: none !important;
            }
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }

        &>div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            transform: translateY(-7px);
        }
    }

    &__card__mobile {
        width: 100%;
        height: auto;

        @include media-breakpoint-up(md) {
            width: 300px;
            height: auto;
        }
    }

    &__icon {
        display: flex;
        gap: pixelToRem(8);

        margin-left: pixelToRem(16);

        &__ico {
            font-size: pixelToRem(48);
            width: pixelToRem(48);
            height: pixelToRem(48);
        }
    }

    &__icon-car {
        color: $blue_hover;

        .#{$block}__icon__ico {
            &:before {
                content: $icon-ico_vehicle_car;
            }
        }
    }

    &__headline-wrapper {
        color: $grey-90;

        @include media-breakpoint-down(md) {
            max-width: 368px;
            display: flex;
            flex-direction: column;
            padding-top: pixelToRem(32);
            padding-bottom: pixelToRem(32);
        }

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 50%;
            left: pixelToRem(32);
            max-width: 436px;

            transform: translate(0, -50%);
        }

        @include media-breakpoint-up(xl) {
            left: pixelToRem(112);
        }
    }

    &__checklist {
        display: flex;
        flex-direction: column;
        gap: pixelToRem(24);

        margin-top: pixelToRem(24);
    }

    &__checklist li {
        display: flex;
        gap: pixelToRem(20);
        line-height: 1.5;
        letter-spacing: pixelToRem(1.2);
        align-items: top;

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(20);
        }
    }

    &__checklist .icon-ico_check {
        color: $blue;
        font-size: pixelToRem(20);

        line-height: 1.2;

        @include media-breakpoint-up(lg) {
            line-height: 1.5;
        }
    }

    &__headline {
        display: inline-block;
        font-size: pixelToRem(30);
        line-height: pixelToRem(35);
        letter-spacing: pixelToRem(1.2);
        font-weight: 300;

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(36);
            line-height: pixelToRem(40);
        }

        @include media-breakpoint-up(xl) {
            font-size: pixelToRem(48);
            line-height: pixelToRem(54);
        }

        em {
            font-weight: bold;
        }
    }

    &__subheadline {
        display: inline-block;
        word-break: break-word;

        font-size: pixelToRem(30);
        line-height: pixelToRem(35);
        letter-spacing: pixelToRem(1.2);
        font-weight: 700;

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(36);
            line-height: pixelToRem(40);
        }

        @include media-breakpoint-up(xl) {
            font-size: pixelToRem(48);
            line-height: pixelToRem(54);
        }

        em {
            font-weight: bold;
        }

        &__mobile {
            color: $grey-90;
            line-height: pixelToRem(24);
            margin-top: pixelToRem(16);
        }
    }
}