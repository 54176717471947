﻿$block: 'sprachwahl';

.#{$block} {
    visibility: hidden;
    position: absolute;
    background-color: white;
    width: 100vw;
    left: 0;
    padding-top: pixelToRem(28);
    opacity: 0;
    max-height: 0vh;
    transition: max-height .5s ease, opacity .5s ease;
    z-index: 1;
    overflow-y: scroll;
    overflow-x: hidden;

    padding-bottom: pixelToRem(160);
    @include media-breakpoint-up(md) {
      overflow-y: hidden;
      padding-bottom: 0;
    }
    &--transition {
      visibility: visible;
      opacity: 1;
      max-height: 100vh;

        &::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          pointer-events: none;

          @include media-breakpoint-up(md) {
            background-color: rgba(0, 0, 0, 0.486);
          }
      }
    }

    &__opener {
      @include button-reset();
      @include font;
      padding: 12px;
      font-size: 16px;
      letter-spacing: 0.58px;
      display: flex;
      align-items: center;
      @include media-breakpoint-only(lg) {
        font-size: pixelToRem(14);
      }
    }

    &__close-wrapper {
      order: 0;

      @include media-breakpoint-up(xl) {
        order: 1;
      }
    }

    &__closer {
      @include button-reset();
      padding: pixelToRem(12);
      font-size: pixelToRem(31);
      line-height: 1;
      color: $grey-90;
      position: relative;

      z-index: 10;

      &::before {
          @include font-icons;
          content: $icon-ico_clear;
      }

      @include media-breakpoint-up(xs) {
        right: pixelToRem(45);
        top: pixelToRem(-11);
      }

      @include media-breakpoint-up(sm) {
        right: pixelToRem(30);
        top: pixelToRem(-8);
      }

      @include media-breakpoint-up(md) {
        right: pixelToRem(0);
        top: pixelToRem(-5);
      }

      @include media-breakpoint-up(xl) {
        top: pixelToRem(-4);
      }

    }

    &__headline {
      @include h2;
      font-size: pixelToRem(28);
      font-weight: 500;
      color: $gray-40;
      padding-bottom: pixelToRem(26);

      @include media-breakpoint-up(xs) {
        top: 5px;
      }

      @include media-breakpoint-up(md) {
        padding-bottom: pixelToRem(15);
        top: 0px;
      }

      @include media-breakpoint-up(xl) {
        padding-right: 36px;
      }

      @media (max-width:380px) {
        font-size: pixelToRem(24);
      }

      @media (max-width:340px) {
        font-size: pixelToRem(22);
      }

      @media (max-width:320px) {
        font-size: pixelToRem(20);
      }
    }

    &__list {
      @include media-breakpoint-up(md) {
        padding-top: pixelToRem(10);
        padding-bottom: pixelToRem(24);
        // display: flex;
        // flex-direction: column;
        // flex-wrap: wrap;
        // height: pixelToRem(300);
        column-count: 3;
      }

      @include media-breakpoint-up(xl) {
        // height: pixelToRem(250);
        // height: pixelToRem(288);
        column-count: 4;
      }
    }

    &__item {
      @include text;
      color: $grey-90;
      margin-bottom: pixelToRem(0);

      @include media-breakpoint-up(lg) {
        margin-bottom: pixelToRem(1);
      }
    }

    &__link {
      @include cta-button-animated-no-arrow;
      font-size: pixelToRem(23);
      font-weight: normal;
      color: $grey-90!important;

      &::before {
        background: $grey-90;
      }

      &--active {
        color: $orange!important;
        font-weight: bold;

        &::before {
          display: none;
        }
      }

      @include media-breakpoint-up(xl) {
        font-size: pixelToRem(21);
      }

      @media (max-width:380px) {
        font-size: pixelToRem(24);
      }

      @media (max-width:340px) {
        font-size: pixelToRem(22);
      }

      @media (max-width:320px) {
        font-size: pixelToRem(20);
      }
    }

}
