$block: 'award-logos';

.#{$block} { 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: pixelToRem(40);
    padding: pixelToRem(30) 0;

    @include media-breakpoint-up(lg) {
        padding: pixelToRem(40) 0;
    }

    &__image {
        height: pixelToRem(70);
        width: auto;
    }
}