$block: 'text-teaser-news-pressekontakt';

.#{$block} {
    @include module-spacing;

    // padding-bottom: pixelToRem(71*.5);

    // @include media-breakpoint-up(md) {
    //     padding-bottom: pixelToRem(71*.75);
    // }

    // @include media-breakpoint-up(lg) {
    //     padding-bottom: pixelToRem(71);
    // }

    &__title {
        @include h2;
        font-weight: bold;
        margin-top: pixelToRem(2);
        margin-bottom: pixelToRem(26);
    }

    &__text {
        font-size: 20px !important;
        @include format(text);
        margin-bottom: pixelToRem(-16);
        overflow: auto;

        li {
            padding-bottom: pixelToRem(1);
        }

        .cta-button {
            margin-top: pixelToRem(15);
        }
    }

    &__cta-button {
        color: white !important;
        padding-bottom: 5px;
    }

    &__arrowLink {
        @include format(text);
        line-height: 1.5!important;
        font-size: pixelToRem(16)!important;
        font-weight: 500;
        margin-top: pixelToRem(9)!important;

    }

    &__list {
        @include format(text);
    }

    &--bg-gray {
        background-color: $background-grey;
    }

    & table tbody tr:first-child td {
        text-align: left;
        font-weight: 500;
        border: 1px solid $white;
        background-color: $blue_20;
        color: $blue;
        padding-top: 0.6875rem;
        padding-bottom: 0.6875rem;
        padding-left: 1rem;
        padding-right: 1rem;
        line-height: 1.5;
        letter-spacing: 0.4px;
        word-break: keep-all;
    }

    & table tbody tr td {
        padding-top: 0;
        word-break: break-word;
    }

    & table tbody tr:nth-child(odd) {
        background-color: $background-grey;
    }

    & table tbody ul.haken li::before {
        font-size: 20px;
        top: 3px;
        left: 40%;
        top: pixelToRem(15);
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    & table tbody tr:nth-child(2) td {
        padding-top: 1rem;
    }

}
