$block: 'input';

.#{$block} {
  &__default {
    line-height: 1.5rem;
    letter-spacing: 0.025rem;
    font-size: 1rem;
    color: $blue;
    padding: 0 1rem 0.375rem;
    width: 100%;
    border: none;
    outline: none;
    height: 1.875rem;
    background-color: transparent !important;
    border-bottom: 1px solid $grey-30;

    &--readonly {
      cursor: initial;
    }
  }

  &__label {
    display: none;
  }

  &__wrapper {
    color: $grey-90;
    margin-bottom: $spacer;
  }
}