$block: 'mega-vorteile';

.#{$block} {
	@include module-spacing;
    
	&--bg-gray {
		background-color: $background-grey;
	}

	&__title {
		@include format(headline);
		margin-bottom: pixelToRem(16);
	}

	&__facts {
		overflow: hidden;
	}
	
	&__item-title {
		@include format(text);
		font-weight: bold;
		margin-bottom: pixelToRem(24);
		border-top: $light-gray 1px solid;
		padding-top: pixelToRem(32);
		display: block;
		
		em {
			@include h2;
			font-weight: bold;
		}
		
		@include media-breakpoint-up(md) {
			margin-bottom: pixelToRem(40);
			padding-top: pixelToRem(24);
		}
	}

	&__text {
		@include format(text);
		margin-bottom: pixelToRem(50);
			
		@include media-breakpoint-up(md) {
			margin-bottom: pixelToRem(80);
		}
	}
	
	@include media-breakpoint-up(xl) {
		&__row-inner {
			margin-left: pixelToRem(-28);
			margin-right: pixelToRem(-28);
		}
		
		&__item {
			padding-right: pixelToRem(28);
			padding-left: pixelToRem(28);
		}
	}

	&__images {
		margin-bottom: pixelToRem(24);
		padding-top: pixelToRem(125);
		position: relative;
		overflow: hidden;
		
		@include media-breakpoint-up(md) {
			padding-top: pixelToRem(225);
			margin-bottom: pixelToRem(52);
		}
	}
	
	&__image {
		position: absolute;
		width: auto;
		bottom: 0;
		animation-fill-mode: forwards;
		animation-duration: .4s;
		animation-timing-function: linear;
		animation-delay: .325s;
		opacity: 0;
		left: 50%;
		transform: translateX(-50%);

		&:nth-child(1) {
			height: pixelToRem(125);
			z-index: 20;
			animation-delay: 0s;
			animation-duration: .4s;
			
			@include media-breakpoint-up(md) {
				height: pixelToRem(225);
			}
			
			@keyframes firstCardPresentation {
				from {
					transform: translate3d(-50%, -$aos-distance, 0);
				}
				to {
					opacity: 1;
				}
			}

			&.aos-animate {
				animation-name: firstCardPresentation;
			}
		}
		
		&:nth-child(2) {
			height: pixelToRem(110);
			z-index: 10;
			
			@include media-breakpoint-up(md) {
				height: pixelToRem(200);
			}
			
			@keyframes secondCardPresentation {
				from {
				}
				to {
					opacity: 1;
					left: 25%;
					transform: translateX(-30%);
				}
			}

			&.aos-animate {
				animation-name: secondCardPresentation;
			}
		}
		
		&:nth-child(3) {
			height: pixelToRem(110);
			z-index: 10;
			
			@include media-breakpoint-up(md) {
				height: pixelToRem(200);
			}

			@keyframes thirdCardPresentation {
				from {
				}
				to {
					opacity: 1;
					left: 75%;
					transform: translateX(-70%);
				}
			}

			&.aos-animate {
				animation-name: thirdCardPresentation;
			}
		}
		
		&:nth-child(4) {
			height: pixelToRem(100);
			z-index: 5;
			
			@include media-breakpoint-up(md) {
				height: pixelToRem(175);
			}

			@keyframes fourthCardPresentation {
				from {
				}
				to {
					opacity: 1;
					left: 0;
					transform: none;
				}
			}

			&.aos-animate {
				animation-name: fourthCardPresentation;
			}
		}
		
		&:nth-child(5) {
			height: pixelToRem(100);
			z-index: 5;

			@include media-breakpoint-up(md) {
				height: pixelToRem(175);
			}

			@keyframes fifthCardPresentation {
				from {
				}
				to {
					opacity: 1;
					left: 100%;
					transform: translateX(-100%);
				}
			}

			&.aos-animate {
				animation-name: fifthCardPresentation;
			}
		}
	}

	&__button {
		display: flex;
        justify-content: center;

		@include media-breakpoint-up(md) {
			justify-content: flex-start;
		}
	}
}
