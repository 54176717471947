$block: "switch-container";

.#{$block} {
	/* The switch - the box around the #{$block}__slider */
	.switch {
		position: relative;
		display: inline-block;
		width: $grid-switch-width;
		height: $grid-switch-width*0.5666667;
	}

	/* Hide default HTML checkbox */
	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	/* The #{$block}__slider */
	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: .4s;
		padding: 0;
		transition: .4s;

		&:before {
			position: absolute;
			content: "";
			height: $grid-switch-width*0.4;
			width: $grid-switch-width*0.4;
			top: 50%;
			transform: translateY(-50%);
			left: 10%;
			background-color: white;
			transition: .4s;
		}
	}

	input:checked+.slider {
		background-color: #2196F3;

		&:before {
			transform: translate($grid-switch-width*0.433333, -50%);
		}
	}

	input:focus+.slider {
		box-shadow: 0 0 1px #2196F3;
	}

	/* Rounded #{$block}__sliders */
	.slider.round {
		border-radius: 34px;

		&:before {
			border-radius: 50%;
		}
	}

	.grid {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		counter-reset: col;
		pointer-events: none;
		display: none;

		&__container,
		&__row {
			height: 100%;
		}

		&__col {
			background-color: $grid-col-bg-color-odd;
			height: 100%;
			counter-increment: col;

			&:nth-child(even) {
				background-color: $grid-col-bg-color-even;

				.grid__content {
					background-color: $grid-col-bg-color-even-inner;
				}
			}
		}

		&__content {
			background-color: $grid-col-bg-color-odd-inner;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 3rem;

			&::before {
				content: counter(col);
				color: rgba(0, 0, 0, .5);
				background-color: rgba(255, 255, 255, .5);
				border-radius: 5px;
			}
		}
	}

	input:checked ~ .grid {
		display: block;
	}
}
