@function pixelToEm($pPixel) {
    @return #{($pPixel / $em-size) + 'em'};
}

@function pixelToRem($pPixel) {
    @return #{($pPixel / $rem-size) + 'rem'};
}

@mixin full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin half-width {
    width: 50vw;
    position: relative;
    left: 25%;
    right: 25%;
    margin-left: -25vw;
    margin-right: -25vw;
}

@mixin overlap-col {
    position: relative;
    margin-left: calc(-1 * ((100% / 12) + 39px));
    margin-right: calc(-1 * ((100% / 12) + 39px));
    width: calc(100% + ((100% / 12) + 39px) * 1 * 2);
}

@mixin bg-gray {
    &--bg-gray {
        background-color: $background-grey;
    }
}
