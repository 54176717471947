$block: 'tech-stack-list';

.#{$block} {
  @include module-spacing();

  &__headline {
    font-weight: 700;
    font-size: pixelToRem(24);
    line-height: pixelToRem(40);
    letter-spacing: pixelToRem(1.2);
    margin-bottom: pixelToRem(20);

    @include media-breakpoint-up(lg) {
      font-size: pixelToRem(32);
    }
  }

  &__text {
    margin-bottom: pixelToRem(40);
    font-size: pixelToRem(16);
    line-height: 1.5;
  }

  &__item_wrapper {
    margin: pixelToRem(-10) pixelToRem(-5) 0 pixelToRem(-5);

    &>div[class^='col-'],
    div[class*=' col-'] {
      padding: 0 pixelToRem(5);
    }

    @include media-breakpoint-up(lg) {
      margin: pixelToRem(-50) pixelToRem(-25) 0 pixelToRem(-25);
      padding: 0 pixelToRem(15);

      &>div[class^='col-'],
      div[class*=' col-'] {
        padding: 0 pixelToRem(10);
      }
    }
  }

  &__item {
    display: block;
    color: $grey-90;
    text-decoration: none;
    padding: pixelToRem(5);
    background-color: $grey-40;
    text-align: center;
    font-weight: 500;
    font-size: pixelToRem(16);
    line-height: pixelToRem(30);
    letter-spacing: pixelToRem(0.5);
    margin-top: pixelToRem(10);
    border: 1px solid transparent;

    @include media-breakpoint-up(lg) {
      padding: pixelToRem(10);
      font-size: pixelToRem(20);
      margin-top: pixelToRem(25);
    }

    &-text {
      border: none;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      padding: 0;
      width: auto;
      text-decoration: none;

      &::before {
        bottom: 0;
        display: block;
        content: "";
        height: pixelToRem(2);
        left: 0;
        position: absolute;
        
        transition: width 0.3s ease 0s, right 0.3s ease 0s;
        width: 0;
        transform: scaleX(0);
      }
    }

    &:hover {
      background-color: white;
      color: $grey-90;
      border-color: $grey-90;

      .tech-stack-list__item-text {
        &::before {
          width: 100%;
          right: 0;
          transform: scaleX(1);
          background: $grey-90;
        }
      }
    }


    &.orange {
      border-color: $orange;
      background-color: $orange;
      color: white;

      .tech-stack-list__item-text {
        &::before {
          background: $orange;
        }
      }

      &:hover {
        color: $orange;
        border-color: $orange;
        background-color: white;
      }

      &:hover:focus,
      &:hover:focus-within {
        border-color: $orange;
        background-color: $orange;
      }

      &--inverted {
        border-color: $orange;
        background-color: $white;
        color: $orange;

        &:hover {
          border-color: $orange;
          background-color: $orange;
          color: white;

          .tech-stack-list__item-text {
            &::before {
              background: white;
            }
          }
        }

        &:hover:focus,
        &:hover:focus-within {
          border-color: $orange-focus;
          background-color: $orange-focus;
        }
      }
    }


    &.blue {
      color: white;
      background: $blue;
      border-color: $blue;

      .tech-stack-list__item-text {
        &::before {
          background: $blue;
        }
      }

      &:hover,
      &:hover:focus,
      &:hover:focus-within {
        color: $blue ;
        background: $white;
        border-color: $blue;
      }


      &--inverted {
        border-color: $blue;
        background-color: white;
        color: $blue;

        .tech-stack-list__item-text {
          &::before {
            background: white;
          }
        }

        &:hover,
        &:hover:focus,
        &:hover:focus-within {
          color: white;
          background: $blue;
          border-color: $blue;
        }
      }
    }
  }
}
