$block: 'career-image-text';

.#{$block} {
  @include module-spacing();
  background-color: $white;

  @include media-breakpoint-down(xs) {
    .container {
      max-width: 100%;
    }
  }

  .row {
    position: relative;
  }

  &__text {

    @include media-breakpoint-down(sm) {
      padding: 0 pixelToRem(24);
    }

    h3 {
      font-size:  pixelToRem(24);
      font-weight: 700;
      line-height: 1.7;
      margin-bottom: pixelToRem(12);
      letter-spacing: pixelToRem(1.2);
    }

    p {
      @include font;
      color: $font-color;
      font-size: pixelToRem(16);
      letter-spacing: pixelToRem(0.4);
      line-height: 1.5;
    }

    > *:last-child {
      margin-bottom: pixelToRem(20);

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    .cta-button {
      margin-top: pixelToRem(20);
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      width:310px;
      top: 50%;
      right: 0;
      z-index: 1;
      transform: translateY(-50%);
      padding: pixelToRem(20) pixelToRem(24);
      background-color: $white;
      border: 1px solid $grey-20;
    }

    &--transparent {
      background-color: transparent;
      border: 0;
    }
  }

  &__image {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding-left: $grid-gutter-width / 2;
      padding-right: $grid-gutter-width / 2;
    }

    picture {
      display: block;
      font-size:0;
    }

    img {
      width: 100%;
    }
  }
}