$block: 'diesel-prices-chart';

.#{$block} {
  @include module-spacing;

  &--bg-gray {
    background: $grey-10;
  }

  &__headline {
    @include h2;
    color: $grey-90;
    font-weight: 700;
    font-size: pixelToRem(24);
    line-height: pixelToRem(30);
    margin-bottom: pixelToRem(16);

    @include media-breakpoint-up(md) {
      font-size:pixelToRem(32);
      line-height: pixelToRem(41);
    }

    @include media-breakpoint-up(xl) {
      font-size: pixelToRem(36);
      line-height: pixelToRem(41);
    }
  }

  &__leading {
    @include format(text);
    color: $grey-90;
    font-weight: 400;
    font-size: pixelToRem(16);
    line-height: pixelToRem(24);
    letter-spacing: pixelToRem(0.5);
    margin-bottom: pixelToRem(26);

    @include media-breakpoint-up(md) {
      font-size: pixelToRem(18);
      line-height: pixelToRem(30);
      margin-bottom: pixelToRem(50);
    }

    @include media-breakpoint-up(xl) {
      font-size: pixelToRem(20);
    }
  }

  &__interface {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: pixelToRem(28);
    margin-bottom: pixelToRem(25);

    .react-common__radio-button-group__wrapper {
      label {
        display: inline-block;
        line-height: pixelToRem(22);
      }
    }

    .react-common__multiselect {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin-bottom: pixelToRem(44);

      .react-common__multiselect {
        width: pixelToRem(240);
      }
    }

    @include media-breakpoint-up(lg) {
      .react-common__multiselect {
        width: pixelToRem(490);
      }
    }

    @include media-breakpoint-up(xl) {
      .react-common__multiselect {
        width: pixelToRem(390);
      }
    }
  }

  &__rechart {

    .recharts-xAxis .xAxis {
      .recharts-cartesian-axis-tick {
        display: none !important;
      }
    }

    .recharts-tooltip-wrapper {
      z-index: 1 !important;
      outline: none !important;
    }

    &__tooltip {
      background-color: $grey-90;
      color: $white;
      padding: pixelToRem(8);
      font-size: pixelToRem(14);
      line-height: pixelToRem(20);
      display: flex;
      flex-direction: column;
      border-radius: pixelToRem(2);

      &__date {
        font-weight: 700;
        padding-bottom: pixelToRem(8);
        text-align: center;
      }

      &__list {
        display: grid;
        grid: auto-flow / auto 1fr;
        column-gap: pixelToRem(18);
        row-gap: pixelToRem(8);

        &__item {
          display: flex;
          column-gap: pixelToRem(8);
          font-weight: 400;
          font-size: pixelToRem(12);
          line-height: pixelToRem(18);
          justify-content: flex-end;
        }
      }
    }

    &__legend {
      padding-top: pixelToRem(16);

      &__list {
        row-gap: pixelToRem(12);
      }

      &__item {
        display: flex;
        align-items: center;
        gap: pixelToRem(8);
      }

      &__chip {
        display: block;
        width: pixelToRem(12);
        height: pixelToRem(6);
        border-radius: pixelToRem(5);
      }

      &__item-text {
        line-height: pixelToRem(18);
        display: block;
        flex-grow: 1;
        max-width: calc(100% - #{pixelToRem(12)} - #{pixelToRem(8)});
        color: $grey-60-alt !important;
      }
    }
  }
}
