﻿$block: 'usercentrics';
.#{$block} {
    @include module-spacing;
    &--bg-gray {
        background-color: $background-grey;
    }

    .usercentrics-consent.#{$block}__button {
        @include cta-button-primary;
    }

}