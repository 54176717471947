$block: "landingpage-shop-section2";

.#{$block} {
    margin-top: pixelToRem(40);

    @include media-breakpoint-up(lg) {
        margin-top: pixelToRem(80);
    }


    &__wrapper {
        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: row-reverse;
        }
    }

    &__headline {
        font-size: pixelToRem(24);
        font-weight: bold;
        line-height: pixelToRem(32);
        color: $grey-90;

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(30);
        }
    }

    &__headline-wrapper {
        p {
            margin-top: pixelToRem(16);
            line-height: pixelToRem(32);
        }
    }

    &__cta-wrapper {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;

            margin-top: pixelToRem(20);
        }

        @include media-breakpoint-up(lg) {
            margin-top: pixelToRem(32);
        }
    }

    &__cta-wrapper__mobile {
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 366px;
        min-height: 268px;

        @include media-breakpoint-up(lg) {
            min-width: 505px;
            min-height: 370px;
        }

        @include media-breakpoint-up(xl) {
            min-width: 563px;
            min-height: 414px;
        }
    }

    &__image {
        position: relative;

        width: 366px;
        height: 268px;

        @include media-breakpoint-up(lg) {
            transform: scale(1.38);
        }

        @include media-breakpoint-up(xl) {
            transform: scale(1.54);
        }
    }

    &__card {
        width: 200px;
        height: auto;

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__polygon1 {
        position: absolute;
        bottom: 12%;
        right: 8%;

        transform: translate(var(--offset-polygon), var(--offset-polygon));
    }

    &__polygon2 {
        position: absolute;
        top: 0%;
        left: 8%;

        transform: translate(calc(var(--offset-polygon) * -1), calc(var(--offset-polygon) * -1));
    }

    &__polygon3 {
        position: absolute;
        bottom: 15%;
        left: 10%;

        transform: translate(calc(var(--offset-polygon) * -1), var(--offset-polygon));
    }

    &__polygon4 {
        position: absolute;
        top: 6%;
        right: 12%;

        transform: translate(var(--offset-polygon), calc(var(--offset-polygon) * -1));
    }
}