$block: 'news-overview';

.#{$block} {
    @include module-spacing;

    &__col {
        display: flex;

        &--filter {
            border-top: 1px solid $grey-40;
            border-bottom: 1px solid $grey-40;

            @include media-breakpoint-down(sm) {
                // flex-direction: column;
                flex-wrap: wrap;

                & > *:first-child {
                    width: 100%;
                }
            }
        }
    }

    &__filter,
    &__result {
        @include h4;
        font-weight: 500;

        @include media-breakpoint-up(xl) {
            font-size: pixelToRem(23);
            line-height: pixelToRem(34);
            letter-spacing: pixelToRem(0.8);
        }

        padding-top: pixelToRem(23);
        padding-bottom: pixelToRem(23);

        &--single,
        &--multiple {
            display: none;
        }
    }

    &__filter {
        color: $grey-90;
        padding-right: pixelToRem(80);
        position: relative;
        cursor: pointer;

        &-item {
            display: flex;
            flex-direction: row;
            padding: pixelToRem(12) pixelToRem(16);
            position: relative;
            cursor: pointer;
            color: $devil;

            &:hover {
                background-color: $blue;
                color: white;
            }
        }

        &-title {
            display: flex;
            cursor: pointer;

            &::after {
                content: $icon-ico_down;
                @include font-icons();
            }
        }

        &:hover {
            color: $blue;

            .#{$block}-title::after {
                content: $icon-ico_up;
            }

            .#{$block}__filter-overlay {
                visibility: visible;
                opacity: 1;
                pointer-events: all;
            }
        }

        &-label {
            @include h6;
            width: 100%;
            font-size: pixelToRem(16);
            color: inherit;
            display: flex;
            cursor: inherit;
            user-select: none;

            input[type=radio],
            input[type=checkbox] {
                display: none;
            }
        }

        &-checkbox {
            display: none;

            & ~ .#{$block}__filter-label {
                &::before {
                    content: "";
                    width: pixelToRem(24);
                    height: pixelToRem(24);
                    margin-right: pixelToRem(8);
                    color: $devil;
                    border: 1px solid $grey-30;
                    border-radius: 2px;
                    background-color: white;
                }

                &::after {
                    @include font-icons();
                    color: $blue;
                    position: absolute;
                    left: pixelToRem(21);
                    top: pixelToRem(12);
                    content: "";
                }
            }

            &:checked ~ .#{$block}__filter-label::after {
                content: $icon-ico_check;
            }

            &:checked ~ .#{$block}__filter-label::before {
                background-color: $blue_20;
                border-color: $blue;
            }
        }

        &-overlay {
            position: absolute;
            top: 100%;
            left: pixelToRem(-14);
            opacity: 0;
            background-color: $white;
            transition: opacity .25s ease;
            display: flex;
            flex-direction: column;
            border: 1px solid $grey-40;
            visibility: hidden;
            box-shadow: 0 10px 20px 0 rgba(128, 128, 128, 0.2);
            z-index: 10;
            min-width: pixelToRem(356);
            pointer-events: none;

            @include media-breakpoint-down(lg) {
                min-width: pixelToRem(250);
            }
        }

        &--date {
            .#{$block}__filter-item {
                &:hover,
                &--selected {
                    background-color: $blue;
                    color: $white;
                }
            }
        }
    }

    &__result {
        margin-left: auto;
        margin-right: 0;
        color: $grey-40;
        text-align: right;
    }

    &__selected-filter {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        min-height: pixelToRem(48);
        margin-bottom: pixelToRem(-8);

        &-item {
            z-index: 9;
            display: flex;
            align-items: center;
            padding: pixelToRem(6) pixelToRem(8) pixelToRem(6) pixelToRem(16);
            margin-left: pixelToRem(16);
            min-width: pixelToRem(160);
            background-color: $blue_10;
            border: pixelToRem(1) solid #98bace;
            border-radius: pixelToRem(2);
            font-size: pixelToRem(14);
            line-height: 1.43;
            color: $grey-90;
            font-weight: 500;
            height: pixelToRem(32);
            margin-top: pixelToRem(16);
            cursor: pointer;

            i {
                margin-left: auto;
                pointer-events: none;
            }
        }
    }

    &__show-all {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &-button {
            @include button-reset;
            @include font-medium;
            color: $blue;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    // Analog zum HTML markup
    .paginationjs {
        margin-top: pixelToRem(40);
        margin-bottom: pixelToRem(40);
        width: 100%;

        &-pages {
            ul {
                display: flex;
                align-items: center;
                justify-content: center;

                .news-overview-next,
                .news-overview-prev {
                    color: white;
                    cursor: pointer;
                    font-size: pixelToRem(24);
                    line-height: pixelToRem(24);
                    border-radius: 50%;
                    padding: pixelToRem(10);
                    margin: 0 pixelToRem(30);

                    & > a {
                        display: none;
                    }

                    &::before {
                        @include font-icons();
                        color: $devil;
                    }

                    &:hover {
                        background-color: $grey-20;
                    }

                    &.disabled {
                        pointer-events: none;

                        &::before {
                            color: white;
                        }
                    }
                }

                .news-overview-next {
                    &::before {
                        content: $icon-ico_chevron-right;
                    }
                }

                .news-overview-prev {
                    &::before {
                        content: $icon-ico_chevron-left;
                    }
                }

                .news-overview-page {
                    border: 1px solid $grey-30;
                    padding: pixelToRem(10) 0;
                    @include font-medium;
                    font-size: pixelToRem(16);
                    line-height: pixelToRem(24);
                    min-width: pixelToRem(46);
                    cursor: pointer;
                    color: $blue;
                    user-select: none;

                    &:hover,
                    &:focus {
                        border-color: $blue;
                        color: $blue;
                    }

                    & > * {
                        text-decoration: none;
                        display: block;
                        text-align: center;
                        color: inherit;
                        user-select: inherit;
                    }

                    &.active {
                        background-color: $blue;
                        color: white;
                        border: 1px solid $blue;
                    }


                }
            }
        }
    }
}
