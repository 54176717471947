$block: 'schachbrett';

.#{$block} {
    @include module-spacing;

    &--bg-gray {
        background-color: $background-grey;
    }

    &__introtext {
        @include format(introtext);
        margin-bottom: pixelToRem(24);

        @include media-breakpoint-up(md) {
            @include h5;
            margin-bottom: pixelToRem(64);
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: pixelToRem(80);
        }
    }

    &__content {
        @include media-breakpoint-up(xl) {
            margin-left: pixelToRem(32);

            &--reverse {
                margin-left: 0;
                margin-right: pixelToRem(32);
            }
        }
    }

    &__title {
        @include h2;
        margin-top: pixelToRem(40);
        margin-bottom: pixelToRem(17) !important;
        color: $black;
        font-weight: bold;

        em {
            color: $grey-lighter;
            font-weight: normal;
        }

        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(0);
        }

        @include media-breakpoint-up(lg) {
            margin-top: pixelToRem(56);
        }

        &--top {
            margin-top: pixelToRem(40);

            @include media-breakpoint-up(md) {
                margin-top: pixelToRem(0);
            }

            @include media-breakpoint-up(xl) {
                margin-top: pixelToRem(16) !important;
            }
        }

        &--bottom {
            margin-top: pixelToRem(40);

            @include media-breakpoint-up(md) {
                margin-top: pixelToRem(0);
            }

            @include media-breakpoint-up(lg) {
                margin-top: pixelToRem(24) !important;
            }

            @include media-breakpoint-up(xl) {
                margin-top: pixelToRem(40) !important;
            }
        }
    }

    &__text {
        @include format(introtext);

        @include media-breakpoint-up(md) {
            @include h5;
        }
    }


    &__row:not(:first-child) {
        margin-top: pixelToRem(80);
    }

    &__link {
        padding-top: pixelToRem(24);

        @include media-breakpoint-up(md) {
            padding-top: pixelToRem(40);
        }

        @include media-breakpoint-up(lg) {
            padding-top: pixelToRem(40);
        }

        &--bottom {
            padding-top: pixelToRem(16) !important;

            @include media-breakpoint-up(md) {
                padding-top: pixelToRem(0) !important;
            }
        }
    }

    &__button {
        padding-top: pixelToRem(24);

        @include media-breakpoint-up(md) {
            padding-top: pixelToRem(24);
        }

        @include media-breakpoint-up(lg) {
            padding-top: pixelToRem(24);
        }
    }

    &__background {
        background-color: $background-grey;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: pixelToRem(164);

        @include media-breakpoint-up(md) {
            min-height: pixelToRem(187);
        }

        @include media-breakpoint-up(xl) {
            min-height: pixelToRem(250);
        }

        img {
            height: 100%;
            max-height: pixelToRem(90);
        }
    }

    &__image {
        display: block;
        width: 100%;
    }
}