$block: "led-wall";

.#{$block} {
  max-width: pixelToRem(2560);
  margin: 0 auto;
  padding: pixelToRem(100) 0;
  overflow: hidden;

  &__row,
  &__col {
    padding-right: pixelToRem(28);
    padding-left: pixelToRem(28);
  }

  &__col {
    padding-top: pixelToRem(28);
    padding-bottom: pixelToRem(28);
  }

  &__card {
    padding: pixelToRem(20);
    background: #262730;
    border-radius: pixelToRem(12);
    border: 3px solid #404049;

    &__img-wrapper {
      width: 100%;
      aspect-ratio: 449/379;
      overflow: hidden;

      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content-wrapper {

      &__headline {
        @include h2();
        color: $white;
        font-size: pixelToRem(32);
        font-weight: 500;
        line-height: 140% !important;
        margin: pixelToRem(24) 0;
      }

      &__text {
        @include font;
        color: $white;
        font-size: pixelToRem(18);
        letter-spacing: pixelToRem(0.4);
        line-height: 1.9;
      }
    }

    &__qr-wrapper {
      margin: pixelToRem(24) 0 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: pixelToRem(16);

      &__title {
        color: $white;
      }
    }
  }
}
