$block: 'news-related-articles';

.#{$block} {
    @include module-spacing;

    // module spacing Sonderabstand wird ausgeschaltet
    margin-top: 0 !important;

    &__text {
        @include format(introtext);

        @include media-breakpoint-up(md) {
            @include h5;
        }
    }

    &__row {
        margin-bottom: pixelToRem(-40);
    }

    &__container {
        :last-child {
            padding-bottom: 0 !important;
        }
    }
}