$block: "blog-sub-navigation";

.#{$block} {
    position: fixed;
    z-index: 2;
    background-color: $grey-20;

    @include media-breakpoint-down(sm) {
        right: 0;
        bottom: 0;
        left: 0;
        height: pixelToRem(60);
        border: 0.5px solid $grey-30;
        box-shadow: 0px -0.5px 2px rgba(0, 0, 0, 0.3);
    }

    @include media-breakpoint-up(md) {
        position: relative;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
    }

    ul {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        li {
            flex-grow: 1;
            padding: 0 pixelToRem(8);
        }

        @include media-breakpoint-up(md) {
            width: 100%;
            justify-content: center;
            align-items: flex-end;
            padding: pixelToRem(8) 0;
            row-gap: pixelToRem(36);

            li {
                max-width: pixelToRem(219);
                padding: 0 pixelToRem(4);
                display: block;
                flex-grow: initial;
                flex-basis: 24%;
            }
        }

        @include media-breakpoint-up(xl) {
            column-gap: pixelToRem(10);
        }
    }

    &__link {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: pixelToRem(12);
        line-height: pixelToRem(60);
        text-align: center;
        text-decoration: none;
        color: $grey-90;

        &:before {
            display: none;
            content: "";
            width: pixelToRem(25);
            height: pixelToRem(5);
            background-color: var(--bg-color);
        }

        &--active {
            &:before {
                display: block;
            }

            @include media-breakpoint-down(sm) {
                font-weight: 700;
                color: var(--bg-color);

                .#{$block}__title {
                    margin-top: pixelToRem(-5);
                }
            }
        }

        @include media-breakpoint-up(md) {
            line-height: pixelToRem(30);
            font-weight: 700;
            letter-spacing: pixelToRem(1.05);
            font-size: pixelToRem(16);
            background-color: var(--bg-color);
            width: 100%;
            transition: padding .11s ease-in;
            transition-property: padding, margin;
            color: white;
            padding-top: pixelToRem(29);
            padding-bottom: pixelToRem(22);
            flex-direction: column;
            gap: pixelToRem(16);
            border: pixelToRem(6) solid white;

            &:hover {
                padding-top: pixelToRem(57);
                margin-top: pixelToRem(-28);
            }

            &:before {
                display: none;
            }

            &--active {
                padding-top: pixelToRem(57);
                margin-top: pixelToRem(-28);

                &:hover {
                    padding-top: pixelToRem(66);
                    margin-top: pixelToRem(-37);
                }
            }
        }

        @include media-breakpoint-up(lg) {
            line-height: pixelToRem(40);
        }

        @include media-breakpoint-up(xl) {
            line-height: pixelToRem(47);
            letter-spacing: pixelToRem(1.2);
            font-size: pixelToRem(28);
        }
    }
}
