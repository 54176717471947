$block: 'vorteile';

.#{$block} {
    @include module-spacing;

    &--bg-gray {
        background-color: $background-grey;

        .#{$block}__content {
            background-color: white;
        }
    }

    &__headline {
        @include h2;
        margin-bottom: pixelToRem(8);
        font-weight: bold;
        color: $dark-gray;
    }

    &__subheadline {
        @include format(text);
        margin-bottom: pixelToRem(25);

        @include media-breakpoint-up(md) {
            margin-bottom: pixelToRem(48);
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: pixelToRem(51);
        }
    }

    &__item {
        margin-bottom: pixelToRem(24);

        @include media-breakpoint-down(sm) {
            &:last-child {
                margin-bottom: 0;
            }
        }

        &--image {
            display: block;

            .#{$block}__image {
                width: 100%;
                display: block;

                &--teaser {
                    padding-bottom: pixelToRem(23);
                }
            }

            .#{$block}__title {
                @include h3;
                font-weight: bold;
                margin-bottom: pixelToRem(18);

                @include media-breakpoint-up(md) {
                    margin-bottom: pixelToRem(31);
                }

                @include media-breakpoint-up(xl) {
                    padding-top: pixelToRem(15);
                }

                &--teaser {
                    margin-bottom: pixelToRem(10)!important;
                    font-size: pixelToRem(23) !important;

                    @include media-breakpoint-up(md) {
                        margin-bottom: pixelToRem(31);
                    }

                    @include media-breakpoint-up(xl) {
                        padding-top: 0px;
                    }
                }
            }

        }

        &--text {
            .#{$block}__icon {
                height: pixelToRem(55);
                margin-bottom: pixelToRem(16);

                @include media-breakpoint-up(md) {
                    margin-bottom: pixelToRem(24);
                }
            }

            .#{$block}__title {
                @include h3;
                margin-bottom: pixelToRem(8);
                
                @include media-breakpoint-up(md) {
                    font-size: pixelToRem(23) !important;
                    line-height: pixelToRem(32) !important;
                    letter-spacing: pixelToRem(0.58) !important;
                }

            }

            .#{$block}__text {
                @include format(introtext);
                margin-bottom: pixelToRem(40);

                @include media-breakpoint-up(md) {
                    @include h5;
                    margin-bottom: pixelToRem(24);
                }

                @include media-breakpoint-up(xl) {
                    margin-bottom: pixelToRem(40);
                }
            }

        }

        .#{$block}__link {
            @include cta-button-animated();
        }
    }

    &__content {
        background-color: $background-grey;
        height: 100%;
        padding: pixelToRem(32) pixelToRem(24);
        
        @include media-breakpoint-up(md) {
            min-height: pixelToRem(352);
            padding: pixelToRem(56) pixelToRem(24) pixelToRem(45) pixelToRem(24) ;
        }
        
        @include media-breakpoint-up(xl) {
            padding: pixelToRem(40);
            min-height: pixelToRem(375);
        }

        &--teaser {
            padding-bottom: pixelToRem(35);
            padding-top: pixelToRem(35);
        }
    }

}