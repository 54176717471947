$block: 'text-slider-right';

.#{$block} {
    @include module-spacing();
    max-width: pixelToRem(1920);
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;

    &__headline {
        font-weight: 700;
        font-size: pixelToRem(24);
        line-height: pixelToRem(40);
        letter-spacing: pixelToRem(1.2);
        margin-bottom: pixelToRem(8);

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(32);
            line-height: pixelToRem(40);
        }
    }

    &__subheadline {
        @include format(text);
        margin-bottom: pixelToRem(20);
    }

    &__content {
        transition: opacity 600ms ease-in;
        margin-bottom: pixelToRem(32);

        &__item {

            &:not([data-index="0"]) {
                display: none;
            }
        }

        &__headline {
            @include format(text);
            font-weight: 700;
            line-height: pixelToRem(16);
            padding-bottom: pixelToRem(5);
            max-width: fit-content;
            border-bottom: 2px solid $black;
            margin-bottom: pixelToRem(5);

            @include media-breakpoint-up(lg) {
                font-size: pixelToRem(16);
                margin-bottom: pixelToRem(32);
            }
        }

        &__text {
            @include format(text);
            margin-bottom: pixelToRem(16);
            color: $grey-90;

            @include media-breakpoint-up(md) {
                font-size: pixelToRem(16);
                line-height: pixelToRem(24);
                letter-spacing: pixelToRem(0.4);
            }

            @include media-breakpoint-up(lg) {
                font-size: pixelToRem(16);
                line-height: pixelToRem(30);
                letter-spacing: pixelToRem(0.5);
            }
        }
    }

    &__carousel-wrapper {
        padding-right: 0;
    }

    &__carousel {
        position: relative;

        &:after {
            content: '';
            background: $white;
            position: absolute;
            top: 0;
            right: -33.33vw;
            bottom: 0;
            left: calc(#{pixelToRem(180)} + #{pixelToRem(18.63)});
            z-index: 1;
            background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0)), linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0));
            pointer-events: none;

            @include media-breakpoint-up(lg) {
                left: calc(#{pixelToRem(250)} + #{pixelToRem(30)});
            }
        }

        .slick-list {
            overflow: visible;
            clip-path: inset( -100vw -100vw -100vw 0 );
        }

        &__item {
            margin-right: pixelToRem(18.63);
            transition: opacity 300ms ease-in-out;

            @include media-breakpoint-up(lg) {
                margin-right: pixelToRem(30);
            }
        }

        &__card {
            background-color: $background-grey;
            position: relative;
            width: pixelToRem(153);
            aspect-ratio: 0.75;

            @include media-breakpoint-up(lg) {
                width: pixelToRem(250);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &__content {
                position: absolute;
                top: pixelToRem(35);
                right: 0;
                bottom: pixelToRem(14);
                left: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                @include media-breakpoint-up(lg) {
                    top: pixelToRem(57.98);
                    bottom: pixelToRem(34.03);
                }

                label {
                    font-weight: 700;
                    font-size: pixelToRem(16);
                    line-height: pixelToRem(16);
                    letter-spacing: pixelToRem(1.2);
                    text-transform: uppercase;
                    text-align: center;
                    color: $grey-90;
                }

                a {
                    background: $white;
                    border: 1px solid $grey-30;
                    color: $grey-90;
                    font-size: 16px;
                    line-height: 24px;
                    padding-right: pixelToRem(16);
                    padding-left: pixelToRem(16);
                    
                    @include media-breakpoint-up(lg) {
                        padding-right: pixelToRem(24);
                        padding-left: pixelToRem(24);
                    }

                    @include media-breakpoint-up(lg) {
                        @include cta-button();
                    }
                }

                &--white {
                    color: $white !important;
                }
            }
        }
    }


    // buttons
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: pixelToRem(32);
        margin: pixelToRem(20) 0;

        @include media-breakpoint-up(lg) {
            margin: 0 0 pixelToRem(20) 0;
            width: fit-content;
        }
    }

    &__use-dots &__pageInfo {
        display: none;
    }

    &__dots {
        display: none;
    }

    &__use-dots &__dots {
        display: block;

        width: fit-content;
        margin: 0;

        .slick-dots {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            width: fit-content;
            margin: 0;

            & button {
                @include button-reset();
                color: transparent;
                border: 1px solid $grey-60;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin: $spacer*0.5;
            }

            & .slick-active {
                button {
                    background-color: $grey-60;
                }
            }
        }
    }

    &__pageInfo {
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    &__currentPage {
        align-self: flex-start;
        font-weight: bold;
    }

    &__totalPages {
        align-self: flex-end;
    }

    &__slash {
        height: 22px;
        border-left: 1px solid $grey-90;
        transform: rotate(30deg);
    }

    &__arrow {
        width: min-content !important;
        margin: 0;

        color: $grey-60;

        &--prev {
            margin-top: pixelToRem(6);
        }

        &--next {
            margin-top: pixelToRem(4);
        }
    }

    &__arrow-button {
        @include button-reset();
        font-size: pixelToRem(25);
    }

    .slick-disabled {
        visibility: hidden;
    }

}
