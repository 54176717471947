$margins: 80, 40, 20, 0;
$topBottom: top, bottom;

@mixin module-spacing($spacing: 80) {
    padding-top: pixelToRem($spacing*.5);
    padding-bottom: pixelToRem($spacing*.5);

    @include media-breakpoint-up(md) {
        padding-top: pixelToRem($spacing*.75);
        padding-bottom: pixelToRem($spacing*.75);
    }

    @include media-breakpoint-up(lg) {
        padding-top: pixelToRem($spacing);
        padding-bottom: pixelToRem($spacing);
    }

	/**
	 * Der Modifier --no-small-spacing definiert, dass die geringeren Abstände
	 * zwischen gleichfarbigen Templates nicht greifen soll. Dies soll bspw.
	 * bei bild-parallax-scrolling erfolgen.
	 */
	// Wenn grau auf grau folgt
	&[class*="--bg-gray"] + [class*="--bg-gray"]:not([class*="--no-small-spacing"]),
	// Wenn blau auf blau folgt
	&[class*="--bg-blue"] + [class*="--bg-blue"]:not([class*="--no-small-spacing"]),
	// Wenn weiß auf weiß folgt
	&:not([class*="--bg-gray"]):not([class*="--bg-blue"]):not([class^="spacer"]) + :not([class*="--bg-gray"]):not([class*="--bg-blue"]):not([class^="spacer"]):not([class*="--no-small-spacing"]) {
        @include module-spacing-top(40);
    }

	@each $margin in $margins {
	    @each $mod in $topBottom {
            &--#{$mod}-#{$margin} {
                padding-#{$mod}: pixelToRem($margin*.5) !important;
                margin-#{$mod}: 0 !important;

                @include media-breakpoint-up(md) {
                    padding-#{$mod}: pixelToRem($margin*.75) !important;
                    margin-#{$mod}: 0 !important;
                }

                @include media-breakpoint-up(lg) {
                    padding-#{$mod}: pixelToRem($margin) !important;
                    margin-#{$mod}: 0 !important;
                }
            }
        }
	}
}

@mixin module-spacing-top($spacing: 80) {
    padding-top: pixelToRem($spacing*.5);
    margin-top: pixelToRem(-$spacing*.5);

    @include media-breakpoint-up(md) {
        padding-top: pixelToRem($spacing*.75);
        margin-top: pixelToRem(-$spacing*.75);
    }

    @include media-breakpoint-up(lg) {
        padding-top: pixelToRem($spacing);
        margin-top: pixelToRem(-$spacing);
    }
}

/**
 * Achtung! Hier wurde bewusst derselbe Klassenname wie im module-spacing verwendet!
 * Das bezieht sich insbesondere auf das bild-parallax-scrolling Template.
 */
@mixin module-spacing-margin() {
    @each $margin in $margins {
        @each $mod in $topBottom {
            &--#{$mod}-#{$margin} {
                margin-#{$mod}: pixelToRem($margin*.5) !important;
    
                @include media-breakpoint-up(md) {
                    margin-#{$mod}: pixelToRem($margin*.75) !important;
                }
    
                @include media-breakpoint-up(lg) {
                    margin-#{$mod}: pixelToRem($margin) !important;
                }
            }
        }
    }
}
