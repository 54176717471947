$block: "instagram";

.#{$block} {
  @include module-spacing;

  &__headline {
    font-style: normal;
    font-weight: 700;
    font-size: pixelToRem(16);
    line-height: pixelToRem(24);
    letter-spacing: pixelToRem(1.2);
    margin-bottom: pixelToRem(17);

    @include media-breakpoint-up(lg) {
      font-size: pixelToRem(32);
      line-height: pixelToRem(33);
      margin-bottom: pixelToRem(53);
    }
  }
}
