$block: "navigation-landingpage";

.#{$block} {
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.3s ease;

    @include media-breakpoint-up(xl) {
        & ::-webkit-scrollbar {
            display: none;
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;
    }

    &__logos {
        margin: 16px 0;
        height: 100%;
        display: flex;
        margin-right: auto;
    }

    &__logo {
        padding-right: 37.33px;
        display: block;
    }

    &__partner-logo {
        &-img {
            height: 56px;
            display: block;
        }
    }

}