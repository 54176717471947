﻿$block: 'product-slider';

.#{$block} {
    @include module-spacing;
    overflow: hidden;

    &__carousel {
      margin-left: pixelToRem(-20);
      margin-right: pixelToRem(-20);
    }

    &__carousel-item {
        height: 100%;
        display: flex !important;
        flex-direction: column;
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
        padding-bottom: pixelToRem(20);
        transition: opacity 300ms ease-in-out;
    }


    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;
      

      & > div {
        height: 100%;
      }
    }

    @include media-breakpoint-up(lg) {
      .slick-list {
        overflow: visible;
        clip-path: inset(-100vw -100vw -100vw 0);
      }
    }

    @include media-breakpoint-up(xl) {
        .slick-track {
            position: relative;
        }
    }

    &__image {
        width: 100%;

        img {
           width: 100%;
           object-fit: cover;
           height: 200px;
        }

        @include media-breakpoint-up(xl) {
            img {
              width: 100%;
              height: 200px;
              object-fit: cover;
            }
        }
    }

    &__headline {
      @include h2;
      word-wrap: break-word;
      text-align: center;
      margin-bottom: pixelToRem(40);
    }

    &__content {
        opacity: 1!important;
        position: relative;
        flex: 1 1 auto;
        color: $grey-90;
        background: white;
        border-radius: 5px;
        border: 1px solid #DBDBDB;
        padding: pixelToRem(24);
        min-height: 252px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
      margin-top: pixelToRem(8);
      color: #323232;
      font-size: 16px;
      font-family: 'RedHatDisplay', Helvetica, Arial, sans-serif;
      font-weight: 700;
      word-wrap: break-word;

        em {
            font-weight: bold;
        }

    }
    &__text {
      margin-top: pixelToRem(8);
      flex: 1 1 auto;
      color: #323232;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      word-wrap: break-word;

        em {
            font-weight: bold;
        }
        a {
          cursor:pointer;
          color: $blue;

          &:visited {
            color: $blue;
          }
          &:hover {
            color: #2e6b90;
        }
         }
    }
    
    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: pixelToRem(32);
      margin: 0 auto;

      @include media-breakpoint-up(lg) {
          margin: 0 auto;
          width: fit-content;
      }
  }

  &__use-dots &__pageInfo {
      display: flex;
  }

  &__dots {
      display: none;
  }

  &__use-dots &__dots {
      display: none;
  }

  &__pageInfo {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;
  }

  &__currentPage {
      align-self: flex-start;
      font-weight: bold;
  }

  &__totalPages {
      align-self: flex-end;
  }

  &__slash {
      height: 22px;
      border-left: 1px solid $grey-90;
      transform: rotate(30deg);
  }

  &__arrow {
      width: min-content !important;

      color: $grey-60;

      &--prev {
          margin-top: pixelToRem(6);
      }

      &--next {
          margin-top: pixelToRem(4);
      }
  }

  &__arrow-button {
      @include button-reset();
      font-size: pixelToRem(25);
  }

  .slick-disabled {
      visibility: hidden;
  }

    
    @include media-breakpoint-up(md) {
      &__two-items, &__three-items{
        .#{$block}__image{
          img{
            height: 150px;
          }
        }
      }
    }
    @include media-breakpoint-up(xl) {
      &__two-items, &__three-items{
        .#{$block}__image{
          img{
            height: 200px;
          }
        }
      }
    }
    .cta-button, .link-styles {
        display: inline-block;
        margin: pixelToRem(32) auto 0;
    }

    &__two-items, &__three-items{  
      .#{$block}__content {
          padding: pixelToRem(32);
      
        .#{$block}__title {
          margin: pixelToRem(24) 0 pixelToRem(16) 0;    
        }
      }
    }
    &__more-items{  
      .#{$block}__image{
        img{
          height: 150px;
        }
      }
    }
}
