$block: "download";

.#{$block} {
  @include module-spacing;

  &__headline {
    @include format(headline);
    font-weight: bold!important;
    margin-bottom: pixelToRem(40);
  }

  &__line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #979797;
  }

  &__list-wrapper {
    margin-top: pixelToRem(24);
    margin-bottom: pixelToRem(24);
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;  

    li {
      margin-bottom: pixelToRem(17);
    }

  }

  &--bg-gray {
    background-color: $background-grey;
  }

}
