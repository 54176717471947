$block: "hero__ebene-eins";

.#{$block} {
  height: pixelToRem(240);
  background-color: $blue;

  @include media-breakpoint-up(md) {
    height: pixelToRem(350);
  }

  @include media-breakpoint-up(lg) {
    height: pixelToRem(480);
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
