$block: "bild-text-link-horizontal";

.#{$block} {
  @include module-spacing;

  &--spacer-small {
    padding-top: 2.5rem;
    padding-bottom: 0;
  }

  &__headline {
    @include h2;
    font-weight: bold;
    margin-bottom: pixelToRem(17);

    &--horizontal {
      @include h3;
      font-weight: bold;

      @include media-breakpoint-up(lg) {
        margin-left: pixelToRem(33);
      }

    }
  }

  &__text {
    @include format(text);
    margin-bottom: pixelToRem(20);
    color: $grey;
    @include media-breakpoint-up(lg) {
        margin-bottom: pixelToRem(40);
    }

    &--horizontal {
      @include media-breakpoint-up(lg) {
        margin-left: pixelToRem(33);
      }
    }
  }
  
  &__image {
    display: block;
    width: 100%;

    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }

  &__list-wrapper {
    @include media-breakpoint-up(lg) {
      margin-left: pixelToRem(33);
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &--horizontal {
      display: flex;
      flex-direction: column;
      margin-top: pixelToRem(0);

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

    }

    a {
      margin-bottom: pixelToRem(16);
    }
  }

  &__item {
    &--horizontal {
      margin-right: pixelToRem(41);
    }
  }

  &--bg-gray {
    background-color: $background-grey;
  }

}