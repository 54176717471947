﻿$block: 'maut-pro-land-select';

.#{$block} {
  padding-top: pixelToRem(100);
  padding-bottom: pixelToRem(40);

    &__left-col { 
      @include text;
    }

    &__right-col {
      padding-top: pixelToRem(40);

      @include media-breakpoint-up(md) {
        padding-top: 0;
      }
      
    }

    &__form-group {
      margin-top: pixelToRem(-13);
    }

    &__select {
        height: pixelToRem(44);
        padding-top: pixelToRem(13);
        font-size: pixelToRem(16);
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 60%;
        }
    }

    select {
      -moz-appearance: none !important; 
      -webkit-appearance: none !important;
      appearance: none !important;
      padding-left: 0.8125rem;
      height: 2.75rem;
      font-size: 1rem;
      border: 1px solid lightgray !important;
      border-radius: 0.25rem;
      cursor: pointer;
    }

    // OVERWRITE ".nice-select" DEFAULT STYLING
    .nice-select:after {
        border-bottom: 2px solid $grey-90;
        border-right: 2px solid $grey-90;
        height: pixelToRem(10);
        right: pixelToRem(15);
        top: 45%;
        width: pixelToRem(10);
    }

    .nice-select .option {
        cursor: pointer;
        line-height: pixelToRem(30);
        min-height: pixelToRem(30);
    }

    .nice-select .list {
        width: 100%;
        height: pixelToRem(350);
        overflow-y: scroll;
    }

}
