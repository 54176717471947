$block: 'career-2columns-teaser';

.#{$block} {
    @include module-spacing;

    .container {
        margin-top: pixelToRem(-20);

        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(-30);
        }
    }

    &__item {
        height: 100%;
        display: flex !important;
        flex-direction: column;
        margin-top: pixelToRem(20);

        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(30);
        }

        &__image {
            position: relative;
            z-index: 10;

            display: flex;
            justify-content: flex-end;
            flex: 0 1 auto;
            margin-bottom: -15%;

            img {
                width: pixelToRem(177);
                margin-right: pixelToRem(14);
            }

            @include media-breakpoint-up(lg) {
                img {
                    width: pixelToRem(200);
                    margin-right: pixelToRem(20);
                }
            }

            @include media-breakpoint-up(xl) {
                img {
                    width: pixelToRem(316);
                }
            }
        }

        &__text {
            position: relative;
            flex: 1 1 auto;

            color: $grey-90;
            background-color: #fafafa;

            padding: pixelToRem(16);
            padding-top: pixelToRem(72);
            min-height: 252px;

            @include media-breakpoint-up(lg) {
                padding: pixelToRem(32);
                padding-top: pixelToRem(100);
                min-height: 330px;
            }

            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__icon {
            position: absolute;

            top: pixelToRem(16);
            left: pixelToRem(16);

            @include media-breakpoint-up(lg) {
                top: pixelToRem(36);
                left: pixelToRem(32);
            }

            display: flex;
            gap: pixelToRem(8);

            &__ico {
                font-size: pixelToRem(48);
                width: pixelToRem(48);
                height: pixelToRem(48);
            }
        }

        &__icon-car {
            color: $blue_hover;

            .#{$block}__icon__ico {
                &:before {
                    content: $icon-ico_vehicle_car;
                }
            }
        }

        &__icon-truck {
            color: #F08300;

            .#{$block}__icon__ico {
                &:before {
                    content: $icon-ico_vehicle_truck;
                }
            }
        }

        &__title {
            font-size: pixelToRem(24);
            font-weight: lighter;

            display: inline-block;
            margin-top: pixelToRem(8);

            em {
                font-weight: bold;
            }

            @include media-breakpoint-up(lg) {
                font-size: pixelToRem(36);
                margin-top: pixelToRem(24);
            }
        }

        &__subtitle {
            font-size: pixelToRem(18);
            font-weight: 300;
            line-height: pixelToRem(28);

            display: inline-block;

            margin-top: pixelToRem(12);
            margin-bottom: auto;
        }

    }


    .cta-button {
        display: inline-block;
        margin-top: pixelToRem(24);
    }
}