$block: "timeline-slider";

.#{$block} {

    padding-top: $spacer*3;
    padding-bottom: $spacer*3;
    width: 90%;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
        width: 80%;
    }

    @include media-breakpoint-up(xl) {
        width: 75%;
    }

    &__slider {
        display: flex;
        flex-direction: row;
        align-items: center;

        .slick-arrow {
            @include button-reset();
            font-size: pixelToRem(50);
            height: auto;
            color: $dark-gray;
        }
    }

    &__slide {
        display: block;
        outline: none;
    }

    &-card {
        margin: 2rem $spacer*0.5;
        perspective: 1000px;
        background-color: transparent;

        &:hover {
            .#{$block}-card__front {
                transform: rotateY(180deg);
            }

            .#{$block}-card__back {
                transform: rotateY(0deg);
            }
        }

        &__inner {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__front {
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            transition: transform 0.6s ease-out;
        }

        &__back {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            color: white;
            padding: $spacer;
            background-color: $blue;
            transform: rotateY(-180deg);
            transition: transform 0.6s ease-out;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transform-style: preserve-3d;
        }

        .aspect-ratio {
            position: relative;
            width: 100%;
            padding-top: 56.25%;

            &__container {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                overflow: hidden;
            }
        }

        &__image {
            max-width: 100%;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 0;
        }

        &__content {
            background-color: $grey-20;
            padding: $spacer;
            padding-top: $spacer*3;
            display: flex;
            flex-direction: column;
            text-align: center;
            color: #323232;
            min-height: 16rem;

            .icon-ico_info {
                position: absolute;
                right: $spacer;
                bottom: $spacer;
                color: $blue;
                font-size: 2rem;

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }

        &__year {
            @include headline;
            color: $font-color;
            font-size: pixelToRem(24);
            line-height: pixelToRem(28);
            letter-spacing: pixelToRem(0.6);
            font-weight: 700;
            margin-bottom: $spacer;

            @include media-breakpoint-up(md) {
                font-size: pixelToRem(30);
                line-height: pixelToRem(40);
                letter-spacing: pixelToRem(0.8);
            }

            
        }

        &__title {
            @include font-medium;
            font-weight: 500;
            font-size: 19.2px;
            font-size: pixelToRem(20);
            line-height: 1.4;
            letter-spacing: normal;
            word-break: break-word;

            @include media-breakpoint-up(md) {
                font-size: pixelToRem(24);
            }
        }

        &__text {
            @include font;
            font-size: 14px;
            line-height: 1.4;
            letter-spacing: 0.7px;
        }
    }

    &__dots {
        margin-top: $spacer;

        .slick-dots {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            & button {
                @include button-reset();
                color: transparent;
                background-color: $grey-20;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin: $spacer*0.5;
            }

            & .slick-active {
                button {
                    background-color: $grey-90;
                }
            }
        }
    }
}