$block: "headline-tracker";

$primary-color: white;
$secondary-color: #2196F3;
$h1-color: #ffb3ba;
$h4-color: #ffdfba;
$h6-color: #ffffba;
$h2-color: #baffc9;
$h3-color: #bae1ff;
$h5-color: #eecbff;

.#{$block} {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .3);
    padding: .25rem .75*$spacer .25rem .75*$spacer;
    border-radius: 0 .5*$spacer 0 0;
    color: $primary-color;
    display: flex;
    align-items: center;
    z-index: 9999;

    &:before {
        margin-right: .5rem;
        font-size: 1rem;
        line-height: 1;
        display: block;
        content: "show headlines";
    }
    
    &__overlay {
		&-wrapper {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			z-index: 9998;
		}
        background-color: rgba(0, 0, 0, .7);
		display: flex;
		flex-direction: column;
		overflow: auto;
		padding: 20px;
		border-radius: .5*$spacer;
		max-width: 75%;
		max-height: 90%;
		font-size: 0.9rem;
		color: $primary-color;
		font-weight: normal;
		&__header {
			display: flex;
			flex-direction: column;
			&-statusbar {
				display: flex;
				margin-bottom: 2px;
				font-weight: bold;
				padding-left: 5px;
				div {
					margin-right: 15px;
					span.H1-element {
						color: $h1-color;
					}
					span.H2-element {
						color: $h2-color;
					}
					span.H3-element {
						color: $h3-color;
					}
					span.H4-element {
						color: $h4-color;
					}
					span.H5-element {
						color: $h5-color;
					}
					span.H6-element {
						color: $h6-color;
					}
				}
			}
			&-message {
				display: flex;
				width: 100%;
				justify-content: center;
				@keyframes flash {
					0%, 100% { border-color: red; }
					50% { border-color: transparent; }
				  }
				p {
					font-weight: bold;
					color: red;
					margin-bottom: 15px;
					padding: 5px;
					border: 3px solid red;
					animation: flash 2s infinite;
				}
			}
		}
		&__main {
			&::before {
				content: "";
				display: block;
				border-top: 2px solid rgba($primary-color, 0.2);
				width: 100%;
				margin-bottom: 15px;
			}
			&-wrapper {
				flex-grow: 1;
				overflow: auto;
				padding: 0 5px;
				&::-webkit-scrollbar {
					width: 10px;
					border-radius: 5px;
				  }
				  &::-webkit-scrollbar-track {
					border-radius: 10px;
					border: 1px solid rgba($primary-color, .2);
				  }
				  &::-webkit-scrollbar-thumb {
					background-color: rgba($primary-color, .4);
					border-radius: 10px;
				  }
			}

			.page-element {
				&-wrapper {
					&:not(:last-child) {
						margin-bottom: 15px;
					}
					&:hover .page-element{
						border: 2px solid $secondary-color;
						background-color: rgba(0, 0, 0, .2);			
					}
					&:hover p {
						color: $secondary-color;
					}
					p {
						font-weight: bold;
						margin-bottom: 2px;
					}
				}
				border: 2px solid rgba($primary-color, .2);
				border-radius: 0 .5*$spacer .5*$spacer .5*$spacer;
				padding: 10px;
				.headline-element {
					&:not(:last-child) {
						margin-bottom: 5px;
					}
					span:first-child {
						font-weight: bold;
					}
					span.H1-element {
						color: $h1-color;
					}
					span.H2-element {
						color: $h2-color;
					}
					span.H3-element {
						color: $h3-color;
					}
					span.H4-element {
						color: $h4-color;
					}
					span.H5-element {
						color: $h5-color;
					}
					span.H6-element {
						color: $h6-color;
					}
					a {
						text-decoration: none;
						color: $primary-color;
						&:hover {
							color: $secondary-color;
						}
					}
				}
			}
		}
    }

    input:checked ~ &__overlay-wrapper {
		display: flex;
	}
}
