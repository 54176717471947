$block: 'tankkarten-vergleich';

.#{$block} {
    @include module-spacing;

    &__grid {

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: row;
        }
    }

    &__attributes {

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            margin-bottom: 0;
        }

        & .#{$block}__attribute {

            @include media-breakpoint-up(lg) {
                text-align: left;
                display: flex;
                justify-content: flex-start;
                line-height: normal;
            }

            &-icon {
                font-size: 20px;
                padding: 5px;
                margin-right: .75rem;
            }

            &-text {
                font-weight: 700;
            }
        }
    }

    &__item-list {

        // & .#{$block}__contentbox {

        //     @include media-breakpoint-up(lg) {
        //         margin-left: 20px;
        //     }

        // }

        @include media-breakpoint-up(lg) {
            overflow-x: scroll;
            overflow-y: hidden;
            padding-top: 50px;
        }
    }

    &__item-attributes-wrapper {
        margin-top: 2rem;
        margin-bottom: 2rem;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__items {

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: row;
            padding-bottom: 30px;
        }
    }


    &__contentbox {
        border-radius: 10px;
        overflow: auto;

        &:before {
            @include media-breakpoint-up(lg) {
                content: " ";
                position: absolute;
                display: block;
                top: 0;
                bottom: 80px;
                width: 100%;
                left: 0;
                right: 0;
                background-color: $background-grey;
                border-radius: 10px;
            }
        }
        &:nth-child(even) {
            @include media-breakpoint-down(md) {
                background-color: $background-grey;
            }
        }


        @include media-breakpoint-up(lg) {
            min-width: 300px;
            max-width: 350px;
            position: relative;
            display: inline-flex;
            flex-direction: column;
            padding: 0;
            text-align: center;
            margin-left: 20px;
            overflow: visible;
        }

        &--attribute-list {
            display: none;

            &:before {
                box-shadow: 11px 9px 9px 0 #0000000d;
            }

            @include media-breakpoint-up(lg) {
                display: inline-flex;
                margin-bottom: 46px;
                margin-right: 0;
                margin-top: 50px;
                padding: 0 10px;
            }
        }

        & li {
            line-height: 35px;
            font-weight: 700;
            padding-left: 30px;
            position: relative;
            display: flex;
            align-items: center;

            @include media-breakpoint-up(lg) {
                border-bottom: 1px solid rgba(0, 0, 0, .13);
                text-align: center;
                height: 60px;
                padding-left: 0;
                justify-content: center;
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        &.#{$block}__contentbox--featured {
            border: 1px solid $orange;
            border-top: none;
            margin-bottom: 50px;

            @include media-breakpoint-up(lg) {
                border: none;
                margin-bottom: 0;
            }

            &:before {
                @include media-breakpoint-up(lg) {
                    border: 1px solid $orange;
                    top: 5px;
                }
            }

            & .#{$block}__item-feature {
                background-color: $orange;
                display: block;
                text-align: center;
                color: $white;
                font-weight: 500;
                line-height: 60px;
                width: 100%;
                border-radius: 10px 10px 0 0;

                @include media-breakpoint-up(lg) {
                    line-height: 50px;
                    height: 50px;
                    position: absolute;
                    top: -40px;
                    visibility: visible;
                    left: 0;
                    right: 0;
                    top: -40px;
                    padding-top: 0;
                }
            }
        }
    }

    &__item-content {
        z-index: 1;
        padding-top: 60px;
        padding: pixelToRem(70) pixelToRem(20) 0;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }

    &__item-image {
        text-align: center;
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            max-width: 300px;
        }

        &-wrapper {
            display: inline-block;
            max-width: 100%;

            @include media-breakpoint-up(lg) {
                border-top: 1px solid #b56300;
                border-radius: 10px 10px 10px 10px;
                box-shadow: 15px 15px 25px rgba(0, 0, 0, .1);
            }

            & img {
                max-width: 100%;
                display: block;
                height: auto;
            }
        }
    }

    & .#{$block}__item-title {
        font-weight: 700;
        margin-bottom: 10px;
    }

    & .#{$block}__item-attribute-icon {
        font-size: 20px;
        color: $grey-60;

        &--blue {
            color: $blue;
        }

        &.icon-ico_info_full {
            color: #444;
            margin-right: pixelToRem(10);

            @include media-breakpoint-down(md) {
                &::before {
                    content: $icon-ico_check_status;
                }
            }
        }
    }

    &__flex-grow {
        flex-grow: 1;
    }

    &__cta-spacer {
        margin-bottom: 50px;
        height: 50px;
        margin-top: 30px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    & i {
        font-family: "DKV" !important;
    }
}