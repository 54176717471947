$block: "timeline";

.#{$block} {
  margin-bottom: pixelToRem(40);

  &__title {
    display: flex;
    flex-direction: column;
    gap: pixelToRem(12) pixelToRem(28);
    margin-bottom: pixelToRem(20);

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: pixelToRem(40);
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: pixelToRem(56);
    }
  }

  &__headline {
    font-weight: 700;
    font-size: pixelToRem(24);
    line-height: pixelToRem(40.5);
    letter-spacing: pixelToRem(1.2);
    @include media-breakpoint-up(lg) {
      font-size: pixelToRem(32);
    }
  }

  &__logo {
    width: pixelToRem(100);
  }

  &__scroll-container {
    display: flex;
    position: relative;
    max-height: 60vh;
  }

  &__scroll-line {
    width: pixelToRem(1);
    height: 100%;

    background: $gray-200;

    position: absolute;
    left: pixelToRem(8);

    @include media-breakpoint-up(md) {
      left: 50%;
      transform: translateX(-50%);
    }

    @include media-breakpoint-up(lg) {
      left: calc(calc(100% - 32px) / 2);
    }
  }

  &__scroll-body {
    height: percentageToRem(100);
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    flex-grow: 1;

    @include media-breakpoint-up(lg) {
      padding: pixelToRem(0) 5%;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &__overlay {
      height: 50%;
      width: 100%;
      pointer-events: none;
      background: linear-gradient(
          to top,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0.6) 50%,
          rgba(255, 255, 255, 0) 100%
        )
        no-repeat;
      position: absolute;
      bottom: pixelToRem(0);
      opacity: 1;
      transition: 0.5s;
    }
  }

  &__item {
    position: relative;
    color: $blue;
    font-weight: 700;
    letter-spacing: pixelToRem(1.2);
    display: flex;

    &:not(:first-child) {
      margin-top: pixelToRem(40);

      @include media-breakpoint-up(md) {
        margin-top: pixelToRem(16);
      }
    }

    @include media-breakpoint-up(md) {
      width: 50%;

      &:nth-child(2n) {
        left: 50%;
      }
    }

    &__icon {
      height: pixelToRem(50);
      aspect-ratio: 1;
      z-index: -1;

      position: absolute;

      @include media-breakpoint-up(lg) {
        height: pixelToRem(192);
        top: pixelToRem(-12);
      }

      &--odd {
        right: pixelToRem(0);
        left: auto;

        @include media-breakpoint-up(md) {
          right: pixelToRem(35);
        }

        @include media-breakpoint-up(lg) {
          left: pixelToRem(0);
          right: auto;
        }
      }

      &--even {
        right: pixelToRem(0);
        left: auto;

        @include media-breakpoint-up(lg) {
          right: pixelToRem(0);
          left: auto;
        }
      }
    }

    &__content {
      padding-left: pixelToRem(35);
      width: calc(100% - 66px);

      @include media-breakpoint-up(md) {
        &--odd {
          width: calc(100% - 66px - 35px);
          padding-left: pixelToRem(0);
        }
      }
      @include media-breakpoint-up(lg) {
        padding: pixelToRem(0);
        width: 75%;

        &--even {
          padding-left: pixelToRem(35);
          padding-right: pixelToRem(0);
        }
      }
    }

    &--odd {
      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }

    &__headline {
      font-size: pixelToRem(24);
      line-height: pixelToRem(24);
      letter-spacing: pixelToRem(1.2);

      @include media-breakpoint-up(lg) {
        font-size: pixelToRem(48);
        line-height: pixelToRem(38);
        width: 100%;
        margin-bottom: pixelToRem(8);
      }
    }

    &__subject {
      font-size: pixelToRem(16);
      line-height: pixelToRem(25);
      letter-spacing: pixelToRem(1.2);

      margin-bottom: pixelToRem(40);
      width: calc(100% - 66px);

      @include media-breakpoint-up(lg) {
        width: 100%;
        margin-bottom: pixelToRem(80);
      }
    }

    &__subline {
      font-size: pixelToRem(16);
      line-height: pixelToRem(24);
      font-weight: 400;
      letter-spacing: pixelToRem(1.2);

      position: absolute;
      right: 0;
      left: auto;
      bottom: pixelToRem(3);

      @include media-breakpoint-up(md) {
        &--odd {
          right: pixelToRem(35);
        }
      }

      @include media-breakpoint-up(lg) {
        bottom: pixelToRem(8);

        &--odd {
          right: pixelToRem(35);
        }

        &--even {
          left: pixelToRem(35);
          right: auto;
        }
      }
    }

    &__bullet {
      width: pixelToRem(17);
      aspect-ratio: 1;

      border-radius: pixelToRem(9);
      background: $orange;
      position: absolute;
      top: pixelToRem(9);
      bottom: auto;

      @include media-breakpoint-up(lg) {
        top: auto;
        bottom: pixelToRem(-6);
      }

      &--odd {
        @include media-breakpoint-up(md) {
          right: pixelToRem(-8.5);
          left: auto;
        }
      }

      &--even {
        @include media-breakpoint-up(md) {
          left: pixelToRem(-8.5);
          right: auto;
        }

        @include media-breakpoint-up(lg) {
          /* left: pixelToRem(-47); */
        }
      }
    }

    &__line {
      position: absolute;
      left: pixelToRem(35);
      right: pixelToRem(0);
      height: pixelToRem(3);
      background: $orange;

      @include media-breakpoint-up(md) {
        &--odd {
          left: pixelToRem(0);
          right: pixelToRem(35);
        }
      }

      @include media-breakpoint-up(lg) {
        width: calc(75% - 35px);
        bottom: pixelToRem(1);

        &--odd {
          left: auto;
          right: pixelToRem(35);
        }
      }
    }
  }

  &__scrollbar {
    display: none;
    height: percentageToRem(100);
    width: pixelToRem(32);
    position: relative;

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: center;
    }

    &__track {
      width: percentageToRem(100);
      position: absolute;
      top: pixelToRem(0);
      right: pixelToRem(0);
      bottom: pixelToRem(0);
      left: pixelToRem(0);
      border-radius: pixelToRem(16);
      background: $gray-200;
    }

    &__thumb {
      min-height: pixelToRem(51);
      width: pixelToRem(24);
      margin: pixelToRem(4);
      border-radius: pixelToRem(12);
      background: $blue;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: pixelToRem(8);
      top: pixelToRem(0);

      &--line {
        height: pixelToRem(1);
        width: pixelToRem(16);
        background: $white;
      }
    }
  }
}
