$block: 'top-links';

.#{$block} {
    display: none;
    border-top: 2px solid $light-gray2;
    padding-top: pixelToRem(40);
    padding-bottom: pixelToRem(87);
    white-space: initial;
    padding-left: pixelToRem(12);
    margin-top: pixelToRem(28);
    position: relative;

    &::before {
        position: absolute;
        bottom: 100%;
        right: 100%;
        border-top: 2px solid $light-gray2;
        content: "";
        width: 50vw;

        @include media-breakpoint-up (xl) {
            content: none;
        }
    }

    &::after {
        position: absolute;
        bottom: 100%;
        left: 100%;
        border-top: 2px solid $light-gray2;
        content: "";
        width: 50vw;

        @include media-breakpoint-up (xl) {
            content: none;
        }
    }

    @include media-breakpoint-up(md) {
        display: block;
    }

    @include media-breakpoint-up(xl) {
        margin: 0;
        border: none;
        padding-top: pixelToRem(12);
        padding-bottom: 0;
    }

    .container {
        height: 100%;
    }


    &__title {
        @include font-medium;
        font-size: pixelToRem(16);
        line-height: 1.75;
        letter-spacing: 0.58px;
        font-weight: 500;
        display: block;
        color: $grey-90;
    }

    &__link {
        @include font;
        font-size: pixelToRem(16);
        line-height: 1.75;
        letter-spacing: 0.58px;
        padding: pixelToRem(8) 0;
        display: block;
        color: $grey-90;
        text-decoration: none;
    }

    &__teaser {
        width: pixelToRem(320);
        max-width: 100%;
        text-decoration: none;
        display: block;

        @include media-breakpoint-up(xl) {
            width: auto;
        }

        &-image {
            margin: pixelToRem(16) 0;
            display: block;
            max-width: 200px;
        }

        &-text {
            @include font;
            line-height: 1.75;
            letter-spacing: 0.58px;
            color: $grey-90;
            white-space: normal;

            em {
                color: $blue;
                font-weight: bold
            }
        }
    }
}