$block: "quote-header";

.#{$block} {
  max-width: pixelToRem(1920);
  margin-right: auto;
  margin-left: auto;
  position: relative;

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: pixelToRem(32) 0;

    @include media-breakpoint-up(md) {
      width: 50%;
      min-height: auto;
      height: pixelToRem(330);
    }

    @include media-breakpoint-up(lg) {
      height: pixelToRem(450);
    }

    @include media-breakpoint-up(xl) {
      height: pixelToRem(500);
    }
  }

  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: pixelToRem(24);
    line-height: pixelToRem(36);
    letter-spacing: pixelToRem(1.2);
    margin-bottom: pixelToRem(16);

    @include media-breakpoint-up(lg) {
      font-style: normal;
      font-weight: 300;
      font-size: pixelToRem(40);
      line-height: pixelToRem(50);
    }

    @include media-breakpoint-up(xl) {
      font-style: normal;
      font-weight: 300;
      font-size: pixelToRem(48);
      line-height: pixelToRem(60);
    }

    em {
      font-weight: 700;
    }
  }

  &__paragraph {
    font-style: normal;
    font-weight: 400;
    font-size: pixelToRem(16);
    line-height: pixelToRem(23);
    letter-spacing: pixelToRem(0.5);
    display: -webkit-box;
    -webkit-line-clamp: var(--max-lines);
    -webkit-box-orient: vertical;
    overflow: hidden;

    em {
      font-weight: 700;
    }
  }

  &__ctaContainer {
    .cta-button {
      margin-top: pixelToRem(19);
    }
  }

  &__image {
    overflow: hidden;
    height: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
      position: absolute;
      right: 0;
      top: 0;

      clip-path: polygon(18% 0, 100% 0%, 100% 100%, 0% 100%);
    }

    picture {
      display: block;
      font-size: 0;
      @include media-breakpoint-up(md) {
        height: 100%;
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in-out;
    }
  }

  &:hover {
    img {
      transform: scale(1.025);
    }
  }
}
