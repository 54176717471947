$block: 'video-youtube';

.#{$block} {
	width: 100%;
	position: relative;
	// padding-bottom: 56.25%;
	// padding-top: 0;
	height: auto;
	overflow: hidden;

	&>iframe,
	&>object,
	&>embed {
		display: block;
		width: 100%;
		height: auto;
		aspect-ratio: 16 / 9;
	}

}