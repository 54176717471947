$block: 'pre-footer';

.#{$block} {
	padding: pixelToRem(34) 0;
	min-height: pixelToRem(242);
	background: $blue;
	margin-bottom: 0;

	@include media-breakpoint-up(md) {
		min-height: pixelToRem(292);
		padding: pixelToRem(64) 0;
	}

	&__content-wrapper {
		height: 100%;
		max-width: 100%;

		@include media-breakpoint-up(md) {
			max-width: pixelToRem(747);
		}
	}

	&__label {
		color: $white;
		font-weight: 400;
		font-size: pixelToRem(16);
		line-height: pixelToRem(18);
		letter-spacing: pixelToRem(0.4);
		margin-bottom: pixelToRem(18);
	}

	&__headline {
		color: $white;
		font-weight: 700;
		font-size: pixelToRem(24);
		line-height: pixelToRem(30);
		letter-spacing: pixelToRem(0.9);
		margin-bottom: pixelToRem(15);

		@include media-breakpoint-up(md) {
			font-size: pixelToRem(36);
			line-height: pixelToRem(41);
			margin-bottom: pixelToRem(25);
		}
	}

	&__paragraph,
	&__email {
		color: $white;
		font-weight: 700;
		font-size: pixelToRem(16);
		line-height: pixelToRem(18);
		letter-spacing: pixelToRem(0.4);
		text-decoration: none;
	}

	&__email {
		margin-right:auto;

		&::before {
			background: $white;
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		&::after {
			display: none
		}

		&:hover {
			color: $white
		}
	}
}
