$block: "landingpage-shop-video";

.#{$block} {
    margin-top: pixelToRem(40);

    @include media-breakpoint-up(lg) {
        margin-top: pixelToRem(80);
    }

    &:not(.fullwidth) {
        .#{$block}__container {
            padding: 0 pixelToRem(30);
        }
    }

    &__container {
        background-color: $grey-20;
    }

    &__wrapper {
        padding: pixelToRem(32) 0;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: pixelToRem(20);

        @include media-breakpoint-up(md) {
            padding: pixelToRem(40) 0;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row-reverse;
            justify-content: space-evenly;
            align-items: center;
            gap: pixelToRem(40);

            padding: pixelToRem(72) 0;

            * {
                flex-grow: 1;
                width: 100%;
            }
        }

        @include media-breakpoint-up(xl) {
            gap: pixelToRem(72);
            padding: pixelToRem(72) 0;
        }
    }

    &__video {
        width: 100%;
    }

    &__headline__mobile {
        font-size: pixelToRem(24);
        font-weight: bold;
        line-height: pixelToRem(32);

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__headline {
        font-size: pixelToRem(30);
        font-weight: bold;
        line-height: pixelToRem(32);
        margin-bottom: pixelToRem(40);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__text {
        line-height: pixelToRem(32);
    }
}