﻿$block: 'querverlinkung';

.#{$block} {
  border-top: solid 2px $grey-20;
  padding-top: pixelToRem(22);

  @include media-breakpoint-up(md) {
    margin-top: pixelToRem(0);
    padding-top: pixelToRem(30);
  }

  @include media-breakpoint-up(xl) {
    border: none;
    padding-left: pixelToRem(12);
    padding-top: pixelToRem(24);
  }

  &__text {
    @include text;
    color: $grey-40;
    
    padding-bottom: pixelToRem(8);
  }
}
