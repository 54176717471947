$block: "diesel-prices-map";

.#{$block} {
  @include module-spacing;
  color: $grey-90;

  &--bg-gray {
    background: $grey-10;
  }

  &__container {
    @include media-breakpoint-up(xl) {
      overflow-x: hidden;
    }
  }

  &__headline {
    @include h2;
    color: $grey-90;
    font-weight: 700;
    font-size: pixelToRem(24);
    line-height: pixelToRem(30);
    letter-spacing: pixelToRem(1);
    margin-bottom: pixelToRem(16);

    @include media-breakpoint-up(md) {
      font-size: pixelToRem(40);
      line-height: pixelToRem(50);
      margin-bottom: pixelToRem(32);
    }
  }

  &__leading {
    @include format(text);
    color: $grey-90;
    font-weight: 400;
    font-size: pixelToRem(16);
    line-height: pixelToRem(24);
    letter-spacing: pixelToRem(0.5);
    margin-bottom: pixelToRem(26);

    @include media-breakpoint-up(md) {
      font-size: pixelToRem(18);
      line-height: pixelToRem(30);
      margin-bottom: pixelToRem(50);
    }

    @include media-breakpoint-up(xl) {
      font-size: pixelToRem(20);
    }
  }

  &__selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: pixelToRem(16);
    margin-bottom: pixelToRem(16);

    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin-bottom: calc(#{pixelToRem(8)} + #{pixelToRem(20)} + #{pixelToRem(48)});
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: calc(#{pixelToRem(8)} + #{pixelToRem(24)} + #{pixelToRem(48)});
    }
  }

  &__form {
    width: 100%;

    &__label {
      display: block;
      margin-bottom: pixelToRem(8);
      font-weight: 500;
    }

    &__country {
      display: flex;
      flex-direction: row;
      gap: pixelToRem(8);
    }

    &__dropdown {
      position: relative;
      width: 100%;
      flex-shrink: 1;
      border: pixelToRem(1) solid $grey-30;
      border-radius: pixelToRem(6);
      background-color: $white;

      select {
        height: 100%;
        width: 100%;
        padding-left: pixelToRem(16);
        padding-right: pixelToRem(56);
        display: flex;
        align-items: center;
        font-size: pixelToRem(16);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        border: none;
        background: transparent;
      }

      div {
        position: absolute;
        font-size: pixelToRem(24);
        top: 50%;
        right: pixelToRem(16);
        transform: translateY(-47.5%);
        pointer-events: none;
      }
    }

    &__price {
      width: pixelToRem(86);
      height: pixelToRem(48);
      border: pixelToRem(1) solid $grey-30;
      border-radius: pixelToRem(6);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    fieldset {
      margin-top: pixelToRem(16);
      display: flex;
      flex-wrap: wrap;
      gap: pixelToRem(16);

      input,
      label {
        cursor: pointer;
      }

      label {
        margin-left: pixelToRem(4);
      }
    }

    &__deviation {
      position: relative;

      &__description {
        width: pixelToRem(75);
        position: absolute;
        bottom: calc(100% + 8px);
        right: 0;
        text-align: right;
        font-size: pixelToRem(8);
        line-height: pixelToRem(12);
        color: $grey-60;

        @include media-breakpoint-up(lg) {
          font-size: pixelToRem(12);
          line-height: pixelToRem(16);
        }
      }

      &__percentage {
        height: pixelToRem(48);
        padding: 0 pixelToRem(14);
        border: pixelToRem(1) solid $grey-30;
        border-radius: pixelToRem(6);
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
      }
    }
  }

  &__swap-button {
    min-width: pixelToRem(40);
    height: pixelToRem(40);
    background-color: $white;
    border: pixelToRem(1) solid $grey-30;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      position: relative;
      top: pixelToRem(-27);
    }

    svg {
      transform: rotate(90deg);

      @include media-breakpoint-up(md) {
        transform: rotate(0deg);
      }

      path {
        fill: $grey-90;
      }
    }
  }

  &__graphs {
    margin-bottom: pixelToRem(40);

    @include media-breakpoint-up(xl) {
      margin-bottom: pixelToRem(74);
    }

    &__buttons {
      display: flex;
      margin-bottom: pixelToRem(20);
    }

    &__button {
      width: 100%;
      position: relative;
      background: none;
      border: none;
      border-bottom: pixelToRem(1) solid $grey-30;
      padding: pixelToRem(20) pixelToRem(32);
      font-weight: 700;
      text-align: center;
      cursor: pointer;

      &-active {
        color: $blue;

        &::after {
          content: "";
          height: pixelToRem(8);
          border-radius: pixelToRem(4);
          background: $blue;
          width: 80%;
          position: absolute;
          bottom: pixelToRem(-4);
          left: 10%;
        }
      }
    }

    &__content {
      @include media-breakpoint-up(md) {
        display: flex;
        gap: pixelToRem(20);
      }

      &__table {
        width: 100%;
        padding-bottom: pixelToRem(43);
        max-height: pixelToRem(815);
        position: relative;
        display: flex;
        flex-direction: column;
        font-size: pixelToRem(14);
        border: pixelToRem(1) solid $grey-30;
        border-radius: pixelToRem(4);
        overflow-x: hidden;
        overflow-y: hidden;

        @include media-breakpoint-up(md) {
          padding-bottom: 0;
          transition: none;
          max-width: 50%;
          max-height: pixelToRem(548);
          overflow-y: auto;
        }

        @include media-breakpoint-up(xl) {
          max-height: pixelToRem(528);
          max-width: pixelToRem(356);
          overflow-y: auto;
        }

        &__row {
          box-sizing: border-box;
          position: relative;
          background: $white;
          display: grid;
          grid-template-columns: 2.5fr 2fr 2.5fr;
          gap: pixelToRem(4);
          border-top: pixelToRem(1) solid $grey-30;

          &:first-child {
            border-top: none;
          }

          &-span {
            grid-template-columns: 1fr;
            background: $grey-10;
            border-top: pixelToRem(1) solid $grey-30;
          }
        }

        &__cell {
          display: flex;
          justify-content: flex-end;
          text-align: right;
          padding: pixelToRem(14) 0;

          &:first-child {
            justify-content: flex-start;
            text-align: left;
            padding-left: pixelToRem(12);
          }

          &:nth-child(3) {
            padding-right: pixelToRem(12);
          }
        }

        &__trigger {
          position: absolute;
          width: 100%;
          height: 100%;
          border: none;
          background: none;
        }

        &__price-bullet {
          width: pixelToRem(12);
          height: pixelToRem(12);
          border-radius: pixelToRem(6);
          margin-right: pixelToRem(10);
        }

        &__toggle {
          font: inherit;
          width: 100%;
          padding: pixelToRem(14);
          position: absolute;
          bottom: 0;
          background: $white;
          display: flex;
          justify-content: center;
          border: none;
          border-top: pixelToRem(1) solid $grey-30;
          cursor: pointer;
        }
      }

      &__map {
        height: pixelToRem(520);
        position: relative;

        @include media-breakpoint-up(md) {
          width: 100%;
          height: pixelToRem(548);
        }

        @include media-breakpoint-up(xl) {
          height: pixelToRem(528);
        }

        &__svg {
          display: block;
          position: absolute;
          height: pixelToRem(520);

          @include media-breakpoint-up(md) {
            width: 100%;
            overflow: visible;
          }

          @include media-breakpoint-up(md) {
            width: 100%;
            height: pixelToRem(548);
          }

          @include media-breakpoint-up(xl) {
            height: pixelToRem(528);
          }
        }

        &__tooltip {
          display: none;
          pointer-events: none;
          font-size: pixelToRem(16);
          background: rgba(0, 0, 0, 0.8);
          padding: pixelToRem(4) pixelToRem(8);
          margin: 0 auto;
          color: $white;
          position: fixed;
        }

        &__legend {
          position: absolute;
          z-index: 1;

          @include media-breakpoint-up(md) {
            top: calc(-#{pixelToRem(8)} - #{pixelToRem(20)});
          }

          @include media-breakpoint-up(xl) {
            top: calc(-#{pixelToRem(8)} - #{pixelToRem(24)});
          }

          &__label {
            display: inline-block;
            width: 100%;
            margin-bottom: pixelToRem(8);
            color: $grey-90;
            font-size: pixelToRem(14);
            line-height: pixelToRem(20);

            @include media-breakpoint-up(xl) {
              font-size: pixelToRem(16);
              line-height: pixelToRem(24);
            }
          }

          &__items {
            width: pixelToRem(140);
            padding: pixelToRem(8);
            background: $white;
            border: pixelToRem(1) solid $grey-30;
            border-radius: pixelToRem(4);
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: pixelToRem(2);

            @include media-breakpoint-up(md) {
              width: pixelToRem(184);
            }

            &__item {
              display: flex;
              flex-direction: column;
              gap: pixelToRem(8);

              font-size: pixelToRem(14);
              color: $grey-60;

              &:nth-child(3) {
                text-align: right;
              }

              div {
                height: pixelToRem(10);
              }
            }
          }
        }
      }
    }

    &__footnote {
      margin-top: pixelToRem(8);
      font-size: pixelToRem(14);
      color: $grey-90;
      text-align: right;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: calc(50% - 10px);
      }

      @include media-breakpoint-up(xl) {
        width: pixelToRem(356);
      }
    }
  }

  &__text {
    font-size: pixelToRem(16);
    line-height: pixelToRem(23.5);
    letter-spacing: pixelToRem(0.5);

    @include media-breakpoint-up(md) {
      font-size: pixelToRem(18);
      line-height: pixelToRem(30);
    }

    @include media-breakpoint-up(xl) {
      font-size: pixelToRem(20);
    }
  }

  &__link-list {
    display: flex;
    flex-direction: column;

    &__link {
      width: fit-content;
      font-weight: 400;
      color: $blue_60;
    }
  }
}
