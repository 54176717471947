$text-color: $blue;
$line-color: #535353;

hr {
  margin-block-start: auto!important;
  margin-block-end: auto!important;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.hr-text {
  font-family: 'Courier New', Courier, monospace;
  font-size: 20px;
  position: relative;
  border: 0;
  text-align: center;
  margin: 0px!important;
  padding: 0px!important;

  // &:before {
  //   content: '';
  //   background: linear-gradient(to right, transparent, $line-color, transparent);
  //   position: absolute;
  //   left: 0;
  //   top: 50%;
  //   width: 100%;
  //   height: 1px;
  // }

  &:after {
    content: '- ' '\00a0' attr(data-content) '\00a0' ' -';
    position: relative;
    display: inline-block;
    color: white;
    color: $text-color;
    background-color: white;
  }

}
 