﻿$block: 'contact-form-hubspot';

.#{$block} {
	padding: pixelToRem(24) pixelToRem(16);

	@include media-breakpoint-up(md) {
		padding: pixelToRem(32) pixelToRem(24);
	}

	@include media-breakpoint-up(xl) {
		padding: pixelToRem(48);
	}

	&.#{$block}--bg-gray {
		background-color: $background-grey;

		& .#{$block}__textarea textarea {
			background-color: white;
		}
	}

	&__headline {
		@include h2;
		margin-bottom: pixelToRem(40);
	}

	&__form {
		form>* {
			margin: pixelToRem(24) 0;
		}
	}

	/* Submit button */
	.hs_submit,
	.hs-submit {
		input[type="submit"] {
			@include cta-button-primary-small;
			border-radius: 50px;
			font-size: pixelToRem(16) !important;
			line-height: 1.5 !important;
			display: block;
			margin: auto;
			margin-bottom: 2rem;
		}
	}

	/* Generic form field */
	.hs-form-field {
		position: relative;

		.hs-error-msgs {
			@include error;
			pointer-events: none;
		}
	}

	/* Input text */
	.hs-fieldtype-text {
		&>label {
			@include font;
			padding: 0 $spacer;
			position: absolute;
			left: 0;
			top: 0;
			transition: all 0.2s ease-out;
			font-size: pixelToRem(16);
			line-height: pixelToRem(24);
			pointer-events: none;
			user-select: none;
		}

		& input {
			@include h6;
			font-size: pixelToRem(16);
			color: $blue;
			padding: 0 $spacer;
			width: 100%;
			border: none;
			outline: none;
			padding-bottom: pixelToRem(6);
			height: pixelToRem(30); // Fix für IE height == line-height
			// background: transparent;
			background-color: transparent !important;
			border-bottom: 1px solid $grey-30;

			&:focus {
				border-bottom-color: $blue;
			}
		}

		& .hs-error-msgs {
			border-top: 1px solid $error-color;
			margin-top: -1px;
			position: relative;
			padding-left: 1rem;
		}
	}

	/* Select */
	.hs-fieldtype-select {
		&>label {
			@include font;
			padding: 0 $spacer;
			margin-bottom: $spacer*0.6;
			display: block;
			font-size: pixelToRem(16);
			line-height: pixelToRem(24);
		}

		& .input {
			display: flex;
		}

		& .nice-select {
			flex: 1 1 100%;
			max-width: none;
			display: flex;
			align-items: center;
			border-radius: 0;
			@include font;
			color: $font-color;
			font-size: pixelToRem(16);
			line-height: pixelToRem(24);
			letter-spacing: pixelToRem(0.4);
			padding: 0 pixelToRem(22);
			justify-content: space-between;
			height: auto;

			&>.current {
				text-align: center;
				margin: pixelToRem(12) 0;

				@include media-breakpoint-up(lg) {
					min-width: pixelToRem(20.406);
				}
			}

			& .list {
				width: calc(100% + 2px);
				left: pixelToRem(-1);
				border-radius: 0;
				border: 1px solid $grey-40;
				box-shadow: 0 pixelToRem(10) pixelToRem(20) 0 rgba(128, 128, 128, 0.2);

				& li {
					padding: pixelToRem(7) pixelToRem(16);
					width: 100%;
					text-align: center;
				}
			}

			&::after {
				font-family: 'DKV', 'Courier New', Courier, monospace;
				content: $icon-ico_down;
				border: none;
				height: auto;
				width: auto;
				transform: none;
				transform-origin: center;
				position: static;
				font-size: pixelToRem(20);
				color: $devil;
				margin: 0;
				top: auto;
				right: auto;
				left: auto;
				bottom: auto;
			}

			&.open {
				&::after {
					transform: rotate(180deg);
				}
			}
		}

		& .hs-error-msgs {
			position: relative;
			padding-left: 1rem;
		}
	}

	.legal-consent-container {
		&>*+* {
			margin-top: pixelToRem(24);
		}

		.field.hs-form-field {
			margin-bottom: 0;
		}
	}

	/* Checkbox */
	.hs-fieldtype-booleancheckbox {
		padding: 0 pixelToRem(16);
		position: relative;

		& .hs-form-booleancheckbox-display {
			padding: 0 pixelToRem(24);
			display: block;
			cursor: pointer;
			user-select: none;

			&>span {
				@include font;
				font-size: pixelToRem(16);
				line-height: pixelToRem(24);
				margin: 0;

				&::before {
					@include font-icons();
					color: $blue;
					font-weight: bold;
					font-size: .75rem;
					content: "";
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					width: pixelToRem(17);
					height: pixelToRem(17);
					border: 1px solid $grey-30;
					position: absolute;
					top: pixelToRem(3);
					left: pixelToRem(16);
				}
			}

			&:hover {
				&>span::before {
					border-color: $devil;
				}
			}

			& input:checked+span::before {
				content: $icon-ico_check;
				background-color: $blue_20;
				border-color: $blue;
			}

			.hs-input {
				display: none;
			}
		}

		& .hs-error-msgs {
			&::before {
				content: "";
				width: pixelToRem(17);
				height: pixelToRem(17);
				border: 1px solid $error-color;
				position: absolute;
				top: pixelToRem(3);
				left: pixelToRem(16);
			}
		}
	}

	& .hs-fieldtype-radio {
		@include font;
		font-size: pixelToRem(16);
		line-height: pixelToRem(24);
		display: block;
		padding: 0 1rem;
		margin: 1.5rem 0;

		& > label {
			display: block;
			margin-bottom: pixelToRem(8);
			color: $grey-80;
		}

		.inputs-list {
			display: flex;
			flex-direction: column;
			
			@include media-breakpoint-up(md) {
				flex-direction: row;
			}
			
			.hs-form-radio {
				width: 100%;

				& + .hs-form-radio {
					margin-top: pixelToRem(10);
					
					@include media-breakpoint-up(md) {
						margin-top: 0;
					}
				}

				label {
					height: pixelToRem(24);
					position: relative;
					color: $devil;
					padding-left: $spacer*2;
					margin-bottom: 0;
					font-size: pixelToRem(16);
					line-height: pixelToRem(24);
					padding-right: pixelToRem(10);

					span::before {
						content: "";
						position: absolute;
						left: 0;
						top: 50%;
						transform: translate(0, -50%);
						width: pixelToRem(24);
						height: pixelToRem(24);
						border-radius: 50%;
						border: 1px solid $grey-30;
						font-size: 0.9rem;
					}

					&:hover {
						span::before {
							border-color: $devil;
						}
					}

					span::after {
						position: absolute;
						top: 50%;
						left: 4px;
						transform: translate(0, -50%);
						width: pixelToRem(16);
						height: pixelToRem(16);
						background-color: $blue;
						border-radius: 50%;
						// font-size: 0.9rem;
					}

					input {
						display: none;
					}

					input:checked {
						& + span::before {
							background-color: $blue_20;
							border-color: $blue;
						}

						& + span::after {
							content: "";
						}
					}
				}

			}

			@include media-breakpoint-down(sm) {
				&>.mwf-error {
					padding-left: 0;
				}
			}
		}
	}
}