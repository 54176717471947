$block: "tile-slider";

.#{$block} {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem;
    margin-left: pixelToRem(24);

    &__slider {
        display: block;
    }

    &__slide {
        display: block;
        outline: none;
    }

    .slick-track {
        display: flex;
    }

    .slick-slide {
        height: inherit;
    }

    .slick-slide>div {
        height: 100%;
    }

    .tile-item {
        margin-left: -24px;
        padding-right: pixelToRem(8);
        display: inline-flex !important;

        @include media-breakpoint-up(sm) {
            padding-right: pixelToRem(12);
        }
    }

    .tile-item__content {
        flex-grow: 1;
    }

    .tile-item__image-wrapper {
        margin-top: auto;
    }

    .slick-dots {
        margin-top: $spacer;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-right: pixelToRem(24);

        & button {
            @include button-reset();
            color: transparent;
            background-color: $grey-slider;
            width: pixelToRem(14);
            height: pixelToRem(14);
            border-radius: 50%;
            margin: $spacer * 0.3;

            @include media-breakpoint-up(sm) {
                margin: $spacer * 0.4;
            }
        }

        & .slick-active {
            button {
                border: 2px solid $grey-60-alt;
                background-color: #fff;
            }
        }

    }
}