$block: 'video-html';

.#{$block} {
	width: 100%;

	& > video {
		display: block;
		width: 100%;
		height: auto;
	}
}
