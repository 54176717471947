@mixin button-reset() {
    // Button reset
    background: none;
    text-decoration: none;
    border: 0;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    outline: none;
}

// Gemeinsamkeiten, nicht geeignet zur Einbindung
@mixin cta-button-similarities {
    @include font;
    color: $font-color;
    font-size: pixelToRem(16);
    line-height: pixelToRem(24);
    letter-spacing: pixelToRem(0.4);
    display: inline-block;
    padding: pixelToRem(7) pixelToRem(25.5);
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;
    text-decoration: none;
    cursor: pointer;
    -webkit-appearance: none;

    &.disabled,
    &[disabled] {
        pointer-events: none;
        border-color: $button-border-color-disabled;
        background-color: white;
        color: $button-font-color-disabled;
    }
}

@mixin cta-button {
    @include cta-button-similarities;

    &--primary {
        @include cta-button-primary;
    }

    &--secondary {
        @include cta-button-secondary;
    }

    &--hollow {
        @include cta-button-hollow;
    }

    &--small {
        @include cta-button-small;
    }

    &--animated {
        @include cta-button-animated;
    }

    &--animated-no-arrow {
        @include cta-button-animated-no-arrow;
    }

    &--inline {
        @include cta-button-inline;
    }

    &--download {
        @include cta-button-download;
    }

    &--blue {
        @include cta-button-blue;
    }

    &--blue-bg {
        @include cta-button-blue-bg;
    }
}

// Einbindung
@mixin cta-button-primary {
    @include cta-button-similarities;
    border-color: $orange;
    background-color: $orange;
    color: white;

    &:hover {
        border-color: $orange-hover;
        background-color: $orange-hover;
    }

    &:hover:focus,
    &:hover:focus-within {
        border-color: $orange-focus;
        background-color: $orange-focus;
    }
}

// Einbindung
@mixin cta-button-secondary {
    @include cta-button-similarities;
    background-color: white;
    border-color: $button-border-color-disabled;
    color: $button-font-color-secondary;

    &:hover {
        border-color: $button-font-color-secondary;
    }

    &:hover:focus,
    &:hover:focus-within {
        background-color: $button-font-color-secondary;
        color: white;
    }
}

@mixin cta-button-hollow {
    @include cta-button-similarities;
    border-color: $orange;
    color: $orange;
    border-width: 2px;
    padding-top: pixelToRem(6);
    padding-bottom: pixelToRem(6);

    &:hover {
        border-color: $orange-hover;
        color: $white;
        background-color: $orange-hover;
    }

    &:hover:focus,
    &:hover:focus-within {
        border-color: $orange-focus;
        background-color: $orange-focus;
        color: $white;
    }
}

// Einbindung
@mixin cta-button-animated {
    @include font-medium;
    border: none;
    color: $blue;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 0;
    width: auto;
    text-decoration: none;
    font-size: pixelToRem(16);
    line-height: 1.5;

    // arrow
    &::after {
        @include font-icons();
        content: $icon-ico_chevron-right;
        margin-right: -17px; // underline not under the ">"-symbol
        font-weight: bold;
    }

    // underline styling
    &::before {
        bottom: 0;
        display: block;
        content: "";
        height: pixelToRem(2); //2px
        left: 0;
        position: absolute;
        background: $blue_hover;
        transition: width 0.3s ease 0s, right 0.3s ease 0s;
        width: 0;
        transform: scaleX(0);
    }

    &:hover {
        color: $blue;

        &::before {
            width: 100%;
            right: 0;
            transform: scaleX(1);
        }
    }
}

@mixin cta-button-download {
    @include font-medium;
    border: none;
    color: $blue;
    align-items: center;
    position: relative;
    width: auto;
    text-decoration: none;
    font-size: pixelToRem(16);
    line-height: 1.5;
    display:inline-block;

    // arrow
    &::after {
        @include font-icons();
        content: $icon-ico_download;
        margin-left: 4px;
        margin-right: -21px;
        font-size: 18px;
        display: inline-block;        
    }

    // underline styling
    &::before {
        bottom: 0;
        display: block;
        content: "";
        height: pixelToRem(2); //2px
        left: 0;
        position: absolute;
        background: $blue;
        transition: width 0.3s ease 0s, right 0.3s ease 0s;
        width: 0;
        transform: scaleX(0);
    }

    &:hover {
        color: $blue;

        &::before {
            width: 100%;
            right: 0;
            transform: scaleX(1);
            transform: scaleY(1);
        }
    }
}

@mixin cta-button-animated-no-arrow {
    @include cta-button-animated;

    &::after {
        content: none;
    }
}

@mixin cta-button-small {
    padding: pixelToRem(3) pixelToRem(21.5);
}

@mixin cta-button-primary-small {
    @include cta-button-primary;
    @include cta-button-small;
}

@mixin cta-button-secondary-small {
    @include cta-button-secondary;
    @include cta-button-small;
}

@mixin cta-button-inline {
    padding: 0px !important;
}

@mixin cta-button-blue {
    color: $blue !important;
    background: $white;
    border-color: $blue;

    &:hover,
    &:hover:focus,
    &:hover:focus-within {
        color: #004B78 !important;
        background: $white;
        border-color: #004B78;
    }
}

@mixin cta-button-blue-bg {
    color: $white !important;
    background: $blue;
    border-color: $blue;

    &:hover,
    &:hover:focus,
    &:hover:focus-within {
        background: #004B78;
        border-color: #004B78;
    }
}