html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    font-size: pixelToRem($rem-size);
    @include font;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    color: $font-color;
    background-color: white;
    @include font;
}

.no-scroll {
    overflow: hidden;
}

main {
    // spacer for navigation
    & > :first-child {
        margin-top: 88px;
    }

    // & > [class*="--bg-gray"] + [class*="--bg-gray"],
    // & > :not([class*="--bg-gray"]) + :not([class*="--bg-gray"]) {
    //     @include module-spacing-top(40);
    // }

    // & > [class*="--bg-gray"] + :not([class*="--bg-gray"]),
    // & > :not([class*="--bg-gray"]) + [class*="--bg-gray"] {
    //     @include module-spacing-top(80);
    // }
}

