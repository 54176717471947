$block: 'video';

.#{$block} {
	@include module-spacing;

	&--small-spacing {
		@include module-spacing(40);
	}

	&--bg-gray {
		background-color: $background-grey;
	}

	&__title {
		@include h2;
		font-weight: bold;
		margin-bottom: pixelToRem(32);
	}

	&__video {
		width: 100%;
	}
}