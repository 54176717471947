$block: "intro-header-fullscreen";

.#{$block} {
    height: calc(100vh - 88px);
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &:before,
    &:after {
        content: '';
        display: block;
    }

    @media(max-height:380px) {
        &:after {
            height: pixelToRem(45); 
        }
    }

    @include media-breakpoint-down(sm) {
        &--video {
            height: 380px;
            
            .#{$block}__video {
                max-width: pixelToRem(767);
                min-height: pixelToRem(380);
                min-width: 100%;
                max-height: auto;
            }

            &:after {
                height: pixelToRem(45); 
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        &--video {            
            .#{$block}__video {
                min-width: pixelToRem(413);
                min-height: 100%;
                max-width: auto;
                max-height: pixelToRem(380);
            }
        }
    }



    &__header-link {
        color: white;
        margin-top: pixelToRem(40);

        &::before {
            background: white;
        }

        &:hover {
            color: white;
        }

        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(80);
            text-transform: uppercase;
        }
    }

    &__video,
    &__image {
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        // height: calc(100vh - 88px);
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-up(lg) {
            min-width: 100%;
            height: calc(100vh - 88px);
            height: auto;
        }

        &--fullscreen {
            position: fixed;
            height: 100vh;
            width: 100vw;
            background-color: black;
            z-index: 101;
            transform: none;
            top: auto;
            left: auto;
        }
    }

    &__video-play-button {
        @include button-reset();
        visibility: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        // transform: translate(-50%, -50%);
        // using negative margin because of aos uses transform and overwrites above line
        margin-left: -40px;
        margin-top: -40px;
        color: white;
        font-size: 3rem;
        border: 2px solid white;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;

        &::before() {
            font-family: 'DKV';
            content: $icon-ico_chevron-right;
        }
    }

    &__videoCloseButton {
        @include button-reset();
        right: 1rem;
        top: 1rem;
        color: black;
        font-size: 3rem;
        line-height: 25px;
        display: none;
        position: fixed;
        z-index: 102;
    }

    &__wrapper {
        position: relative;
        z-index: 2;
        width: 100%;
        // position: relative;
        // top: 40%;
        // transform: translateY(-50%);

        @include media-breakpoint-down(md) {
            @include make-container();
        }

        @each $breakpoint, $container-max-width in $container-max-widths {
            @if ($breakpoint!=lg and $breakpoint!=xl) {
                @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
                    max-width: $container-max-width;
                }
            }
        }

        @include media-breakpoint-up(md) {
            margin-bottom: pixelToRem(80);
        }
        
        @include media-breakpoint-up(lg) {
            // position: absolute;
            // top: 40%;
            // transform: translateY(-50%);
            left: 25%;
            max-width: 40%;
        }
    }

    &__col {
        display: flex;
        flex-direction: column-reverse;
    }

    &__button {
        margin-bottom: 1rem;
    }

    &__headline {
        @include format('headline');
        line-height: 1.17;
        letter-spacing: 1.2px;
        // text-align: right;
        font-weight: 300;
        word-break: break-word;
        
        @include media-breakpoint-up(md) {
            font-size: pixelToRem(48);
        }

        &-top {
            color: $white;
            display: inline-block;
        }
        
        &-bottom {
            display: inline-block;
            color: $text-emphasized;
            font-weight: bold;
        }
    }

    &__subline {
        @include h2;
        margin-top: pixelToRem(16);
        color: white;
        
        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(40);
        }
    }

    &__teaser-button {
        margin-bottom: pixelToRem(16);
        padding: pixelToRem(12) pixelToRem(51) pixelToRem(12) pixelToRem(50);
        border-radius: pixelToRem(7);
        border: none;
        box-shadow: 0 pixelToRem(2) pixelToRem(3) 0 rgba(0, 0, 0, 0.1);
        background-color: white;

        & + & {
            @include media-breakpoint-up(md) {
                margin-left: pixelToRem(32);
            }
        }

        &-wrapper {
            margin-top: pixelToRem(16);
            display: flex;
            flex-wrap: wrap;
            
            @include media-breakpoint-up(md) {
                flex-wrap: nowrap;
                margin-top: pixelToRem(24);
            }

            & ~ .#{$block}__headline {
                font-weight: bold;
            }
        }

        @include media-breakpoint-down(sm) {
            display: inline-block;
            margin-right: 0;
            width: 100%;
            text-align: center;
        }
    }

    &__badge {
        position: absolute;
        right: $spacer;
        bottom: $spacer;
        height: pixelToRem(70);

        @include media-breakpoint-only(md) {
            bottom: $spacer*5;
            right: $spacer*2;
            height: pixelToRem(125);
        }

        @include media-breakpoint-up(lg) {
            height: auto;
            top: auto;
            right: pixelToRem(128);
        }

        @include media-breakpoint-up(xl) {
            bottom: $spacer*8;
            right: pixelToRem(128);
        }

        @media (min-width: 1920px) {
            bottom: 3rem;
            right: 3rem;
        }
    }

    &__down-button {
        @include button-reset();
        color: white;
        font-size: pixelToRem(36);
        animation: pulse 2s infinite;

        position: absolute;
        left: 50%;
        margin-left: pixelToRem(-18);
        bottom: $spacer;
        
        @include media-breakpoint-up(md) {
            bottom: $spacer*2;
        }

        @keyframes pulse {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(0, 10px);
            }

            100% {
                transform: translate(0, 0);
            }
        }
    }

    &__ctaContainer {
        width: 100%;
        color: white !important;
        font-size: 16px;
        color: inherit;
        position: relative;
        z-index: 2;
        text-align: center;

        @include media-breakpoint-up(md) {
            position: absolute;
            left: 0;
            bottom: 2rem;
            margin-bottom: pixelToRem(66);
        }

        &-button {
            margin-bottom: pixelToRem(24);
        }

        &-link {
            display: inline-block;
            color: white;
            font-size: 16px;
            text-decoration: underline;
            display: block;
        }
    }

    &__curtain {
        width: 150%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        animation-duration: 1.5s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        // required for Image Cropper in FS
        pointer-events: none;
        transform-origin: top left;

        @include media-breakpoint-up(lg) {
            left: 60%;
            top: 0;
        }

        &--top-down {
            background: linear-gradient(0deg, rgba(0, 128, 178, 0) 0%, $card-button-blue 100%);
            opacity: 0.34;
            animation-name: bottomUp;
            left: 35%;

            @include media-breakpoint-up(lg) {
                left: 50%;
            }

            @keyframes bottomUp {
                from {
                    transform: skew(-18.5deg) translateY(150%);
                }

                to {
                    transform: skew(-18.5deg) translateY(0);
                }
            }
        }

        &--bottom-up {
            transform: skew(-16.5deg);
            background: linear-gradient(180deg, rgba(0, 73, 123, 0) 0%, $card-button-blue 100%);
            opacity: 0.75;
            animation-name: topDown;

            @keyframes topDown {
                from {
                    transform: skew(-16.5deg) translateY(-150%);
                }

                to {
                    transform: skew(-16.5deg) translateY(0);
                }
            }
        }
    }
}