$block: 'slider-bild-text';

.#{$block} {
    @include module-spacing;
    
    &--bg-gray {
        background-color: $background-grey;
    }

    &__image {
        margin-bottom: pixelToRem(16);
        display: flex;
        justify-content: center;
        img {
            border-radius: 50%;
            height: pixelToRem(88);
        }
    }

    &__person {
        @include h5;
        color: $black;
        text-align: center;
        margin-bottom: pixelToRem(16);

        &-name {
            font-weight: bold;
            margin: 0;
        }

        &-position {
            font-style: normal;
            margin: 0;
        }
    }

    &__comment {
        @include format(text);
        text-align: center;
        margin: 0 10px;

        & * {
            line-height: 1.33;
            font-size: pixelToRem(18);

            @include media-breakpoint-up(md) {
                font-size: pixelToRem(20);
            }

            @include media-breakpoint-up(md) {
                font-size: pixelToRem(24);
            }
        }
    }

    // &__carousel {}

    &__navigation {
        position: relative;
    }

    &__arrow {
        position: absolute;
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }

        &--prev {
            top: 100%;
            margin-top: pixelToRem(16);
            transform: translate(0, -50%);
            left: 0;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                top: 50%;
                left: pixelToRem(-48);
            }

            @include media-breakpoint-up(xl) {
                left: pixelToRem(-88);
            }
        }

        &--next {
            top: 100%;
            margin-top: pixelToRem(16);
            transform: translate(0, -50%);
            right: 0;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                top: 50%;
                right: pixelToRem(-48);
            }

            @include media-breakpoint-up(xl) {
                right: pixelToRem(-88);
            }
        }
    }

    &__arrow-button {
        @include button-reset();
        font-size: pixelToRem(25);
    }

    &__dots {
        .slick-dots {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            & button {
                @include button-reset();
                color: transparent;
                background-color: $grey-20;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin: $spacer*0.5;
            }

            & .slick-active {
                button {
                    background-color: $grey-90;
                }
            }
        }
    }
}