$block: "text-bild-quadratisch";

.#{$block} {
  @include module-spacing;

  &__headline {
    @include format(headline);
    font-weight: bold!important;
    margin-bottom: pixelToRem(41);
  }

  &__title {
    @include h3;  
    font-weight: bold!important;
    line-height: 1.21!important;
    letter-spacing: 0.7px!important;
    margin-bottom: pixelToRem(16);
  }

  &__text {
    @include format(text);

    p {
      margin-bottom: pixelToRem(16);
    }
  }

  &__image {
    width: 100%;
      & * {
        display: block;
      }
  }

  &__button {
    text-align: right;
    margin-top: pixelToRem(23);
    margin-bottom: pixelToRem(60);

    @include media-breakpoint-up(md) {
      margin-bottom: pixelToRem(40);
    }
  }

  &--bg-gray {
    background-color: $background-grey;
  }

 }
 
