#version-info {
  position: fixed;
  bottom: 90px;
  left: 0;
  background-color: rgba(0, 0, 0, .3);
  padding: .25rem .75*$spacer .25rem .75*$spacer;
  border-radius: 0 .5*$spacer .5*$spacer 0;
  color: white;
  display: flex;
  align-items: center;
  z-index: 9999;

  &:before {
    margin-right: .5rem;
    font-size: 1rem;
    line-height: 1;
    display: block;
    content: "version";
  }
}