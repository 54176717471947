$block: 'zweier-vierer-sechser-text';

.#{$block} {
  @include module-spacing;

    &__title {
      @include format(headline);
      font-weight: bold!important;
    }

    &__row {
      padding-top: pixelToRem(40);
      display: grid;
      overflow: hidden;
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: 1fr;
      grid-column-gap: 24px;
      grid-row-gap: 24px;

      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(2, 1fr);
      }

    }

    &__col {
      display: flex;
    }

    &__tile {
      @include format(text);
      background-color: $background-grey;
      padding: 32px;
      height: 100%;
      width: 100%;

      &--title {
        color: $grey-90;
        font-weight: 500;
        line-height: 1.39;
        letter-spacing: 0.58px;
      }

      &--text {
        color: $grey;
        line-height: 1.5;
        letter-spacing: 0.5px;
      }
    }

    &--bg-gray {
        background-color: $background-grey;

        .#{$block}__tile {
            background-color: $white;
        }
    }
  }