$block: 'share';

.#{$block} {

    &__button {
        position: relative;
        display: flex;
        cursor: pointer;
        padding-bottom: pixelToRem(14);

        &:hover {
            .#{$block} {
                &__overlay {
                    visibility: visible;
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }

    &__text {
        @include format(text);
        font-size: pixelToRem(16) !important;
        color: $blue;
    }

    &__link {
        @include h5;

        font-size: pixelToRem(16) !important;
        line-height: pixelToRem(24) !important;
        letter-spacing: pixelToRem(0.4) !important;

        text-decoration: none;
        padding:  pixelToRem(8) pixelToRem(16);
        background-color: white;
        color: $devil;
        display: flex;

        & &-icon {
            margin-right: pixelToRem(8);
            font-size: pixelToRem(24) !important;
            font-style: normal;
        }

        &:hover {
            background-color: $blue;
            color: white;
        }
    }

    &__icon {
        //font-size: pixelToRem(20);
        font-size: pixelToRem(26);
        line-height: pixelToRem(30);
        color: $blue;
        margin-right: pixelToRem(8);
    }

    &__overlay {
        position: absolute;
        top: 100%;
        left: pixelToRem(-14);
        opacity: 0;
        transition: opacity .25s ease;
        display: flex;
        flex-direction: column;
        border: 1px solid $grey-40;
        visibility: hidden;
        box-shadow: 0 10px 20px 0 rgba(128, 128, 128, 0.2);
        z-index: 10;
        min-width: pixelToRem(356);
        pointer-events: none;

        @include media-breakpoint-down(lg) {
            min-width: pixelToRem(250);
        }

        @include media-breakpoint-down(sm) {
            min-width: pixelToRem(170);
        }
    }
}
