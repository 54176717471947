$block: 'news-flash';

.#{$block} {
  padding-top: pixelToRem(40);
  padding-bottom: pixelToRem(40);

  &--bg-gray {
    background-color: $background-grey;
  }

  &__headline {

    font-size: ($font-size-text*1.875) *$smaller;
    line-height: 1.4;
    letter-spacing: 1.8px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      font-size: $font-size-text*1.875;
    }

    word-break: break-word;
    color: $blue;
    margin-bottom: 40px;

    .#{$block}__subheadline {
      margin-left: $spacer*4;
    }

    &--orange {
      color: $orange;
    }
  }

  &__text {
    @include format(text);
    margin-bottom: 40px;
    font-size: 18px !important;
    line-height: 1.4 !important;
    letter-spacing: normal !important;

    a[href^="mailto:"] {
      text-decoration: none;
    }
  }
}
