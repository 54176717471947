$block: 'logo-simple';
$carousel: 'logo-karussell';

.#{$block} {

  &__headline {
    font-weight: 500;
    font-size: pixelToRem(16);
    line-height: pixelToRem(24);
    text-align: center;
    color: $grey-90;
    margin-bottom: pixelToRem(42);

    @include media-breakpoint-up(md) {
      margin-bottom: pixelToRem(54);
    }

    @include media-breakpoint-up(lg) {
      font-size: pixelToRem(20);
      margin-bottom: pixelToRem(75);
    }
  }

  & .#{$carousel}__carousel-item {
    @include make-col(4);
    margin-bottom: pixelToRem(52);

    &--mobile-half {
      @include make-col(6)
    }

    @include media-breakpoint-up(md) {
      @include make-col(2.4);
    }
  }

  & .#{$carousel}__image--label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    img {
      flex-grow: 1;
      max-height: pixelToEm(52);
    }

    .#{$carousel}__image__label {
      margin-top: pixelToRem(14);
      @include font;
      color: $font-color;
    }

    &[href] {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      .#{$carousel}__image__label {
        cursor: pointer;
      }
    }
  }

  &__cta-container {
    margin-top: pixelToRem(-7);
  }

  &__cta {
    margin-top: 0;
    text-align: center;
    font-weight: 400;
  }
}
