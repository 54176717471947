﻿$block: 'slider';

.#{$block} {
    @include module-spacing;

    &--bg-gray {
        background-color: $background-grey;
    }

    &__image {
        display: flex;
        justify-content: center;

        &--small {
            img {
                height: pixelToRem(300);
            }
        }
        &--medium {
            img {
                height: pixelToRem(380);
            }
        }
        img {
            width: 100%;
            object-fit: cover;
        }
    }

    &__person {
        @include h5;
        color: $black;
        text-align: center;


        &-name {
            font-weight: bold;
            margin: 0;
        }

        &-position {
            font-style: normal;
            margin: 0;
        }
    }

    &__comment {
        @include format(text);
        text-align: center;
        margin: 0 10px;

        & * {
            line-height: 1.33;
            font-size: pixelToRem(18);

            @include media-breakpoint-up(md) {
                font-size: pixelToRem(20);
            }

        }
    }

    &__carousel {
      &--bottom {
        background-color: $background-grey;
        padding-top: 15px;
        margin-top: -3px;
      }
    }

    &__navigation {
        position: relative;
    }

    &__arrow {
        position: absolute;
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }

        &--prev {
            top: 100%;
            margin-top: pixelToRem(16);
            transform: translate(0, -50%);
            left: 0;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                top: 50%;
                left: pixelToRem(-48);
            }

            @include media-breakpoint-up(xl) {
                left: pixelToRem(-38);
            }
        }

        &--next {
            top: 100%;
            margin-top: pixelToRem(16);
            transform: translate(0, -50%);
            right: 0;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                top: 50%;
                right: pixelToRem(-48);
            }

            @include media-breakpoint-up(xl) {
                right: pixelToRem(-38);
            }
        }
    }

    &__arrow-button {
        @include button-reset();
        font-size: pixelToRem(36);
    }

    &__dots {
        .slick-dots {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding-top: 14px;

            & button {
                @include button-reset();
                color: transparent;
                background-color: $grey-20;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin: $spacer*0.5;
            }

            & .slick-active {
                button {
                    background-color: $grey-90;
                }
            }
        }
    }
}
