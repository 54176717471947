$block: "hl-contentliste";

.#{$block} {
  @include module-spacing;

  &__headline {
    @include format(headline);
    font-weight: bold!important;
    margin-bottom: pixelToRem(33);
  }

  &__content:not(:first-of-type) {
    margin-top: 0;

    @include media-breakpoint-up(md) {
      margin-top: 20px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }
  }

  &__title {
    @include h3;
    font-weight: bold!important;
    margin-top: pixelToRem(3);
    margin-bottom: pixelToRem(17);
  }

  &__top-image {
    margin-bottom: pixelToRem(23);
    width: 100%;
      & * {
        display: block;
      }
  }

  &__top-text {
    @include format(text);
    margin-bottom: pixelToRem(25);

    @include media-breakpoint-up(lg) {
      margin-bottom: pixelToRem(41);
    }
  }

  &__bottom-image {
    margin-bottom: pixelToRem(23);
    width: 100%;
      & * {
        display: block;
      }
  }

  &__bottom-text {
    @include format(text);
    margin-bottom: pixelToRem(25);
    font-size: pixelToRem(16)!important;
    line-height: 1.5!important;
    letter-spacing: 0.4px!important;

    ul {
      padding-top: 0px!important;
    }
    
    li {
      margin-bottom: pixelToRem(12);
    }
  }

  &--bg-gray {
    background-color: $background-grey;
  }
  
 }
 