$block: "text-clipped-image";

.#{$block} {
  max-width: pixelToRem(1920);
  margin-right: auto;
  margin-left: auto;

  @include module-spacing;

  &__container {
    @include media-breakpoint-up(md) {
      flex-basis: 40%;
    }

    @include media-breakpoint-up(lg) {
      flex-basis: 45%;
    }
  }

  &__text {
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: pixelToRem(0.9);
    margin-bottom: pixelToRem(27);
    margin-left: pixelToRem(4);

    @include media-breakpoint-up(md) {
      margin-bottom: pixelToRem(37);
      max-width: pixelToRem(450) !important;
    }

    &__headline {

      &__large {
        font-size: pixelToRem(36);

        @include media-breakpoint-up(md) {
          font-size: pixelToRem(90);
        }
      }

      &__medium {
        font-size: pixelToRem(24);

        @include media-breakpoint-up(md) {
          font-size: pixelToRem(64);
        }
      }

      &__small {
        font-size: pixelToRem(16);

        @include media-breakpoint-up(md) {
          font-size: pixelToRem(48);
        }
      }

    }

    &__subheadline {

      &__large {
        margin-top: 1rem;

        font-size: pixelToRem(36);

        @include media-breakpoint-up(md) {
          font-size: pixelToRem(90);
        }
      }

      &__small {
        margin-top: 1rem;

        font-size: pixelToRem(16);

        @include media-breakpoint-up(md) {
          font-size: pixelToRem(16);
        }
      }

    }
  }

  &__image-wrapper {
    width: 100%;
    height: pixelToRem(194);
    overflow: hidden;

    @include media-breakpoint-up(md) {
      height: pixelToRem(500);
      flex-basis: 60%;
      clip-path: polygon(18% 0, 100% 0%, 100% 100%, 0% 100%);
    }

    @include media-breakpoint-up(lg) {
      flex-basis: 55%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__cta-read-more {
    font-size: pixelToRem(16);
    line-height: pixelToRem(24);
  }
}