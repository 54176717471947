$block: 'schritt-fuer-schritt';

.#{$block} {
	@include module-spacing;

	&--bg-gray {
		background-color: $background-grey;
	}

	&__title {
		@include h2;
		font-weight: bold;
		margin-bottom: pixelToRem(24);
	}
	
	&__text {
		@include format(text);
		margin-bottom: pixelToRem(8);
	}

	&__steps {
		margin-top: pixelToRem(26);
		margin-bottom: pixelToRem(24);
		
		@include media-breakpoint-down(sm) {
			margin-top: pixelToRem(16);
			margin-bottom: pixelToRem(14);
		}
	}

	&__step {
		&+&::before {
			content: $icon-ico_status_down;
			@include font-icons();
			width: pixelToRem(33);
			height: pixelToRem(41);
			margin: pixelToRem(8) auto pixelToRem(28) auto;
			display: block;
			font-size: pixelToRem(32);
			line-height: 1.28;
			letter-spacing: 0.8px;
			color: $grey;
			
			@include media-breakpoint-down(sm) {
				margin: 0 auto 1rem auto;
				font-size: pixelToRem(24);
				height: pixelToRem(28);
			}
		}

		&-wrapper {
			display: flex;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}
		}

		&-image-wrapper {
			min-width: pixelToRem(140);
			max-width: pixelToRem(140);
			overflow: hidden;
			
			@include media-breakpoint-down(md) {
				min-width: pixelToRem(100);
				max-width: pixelToRem(100);
			}

			@include media-breakpoint-down(sm) {
				min-width: auto;
				max-width: auto;
			}
		}
		
		&-image {
			display: block;
			max-width: 100%;
			height: pixelToRem(55);
			margin: auto;

			@include media-breakpoint-down(sm) {
				margin-left: 0;
				margin-bottom: 0.5rem;
			}
		}
		
		&-content {
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		&-title {
			@include h5;
			font-weight: bold;
		}

		&-text {
			@include format(text);
		}
	}
	
	&__footnote {
		@include format(caption);
	}
}