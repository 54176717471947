$block: 'accordion';

.#{$block} {
    @include module-spacing;

    &--bg-gray {
        background-color: $background-grey;
    }

    &__headline {
        @include h2;
        margin-bottom: pixelToRem(8);
        font-weight: bold;
        color: $dark-gray;

        @include media-breakpoint-up(xl) {
            margin-bottom: pixelToRem(16);
        }
    }

    &__text {
        @include h5;
        font-size: pixelToRem(18);
    }

    &__items {
        margin-top: pixelToRem(40);

        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(53);
        }

        @include media-breakpoint-up(xl) {
            margin-top: pixelToRem(60);
        }
    }

    &__item {
        & + & {
            margin-top: pixelToRem(16);

            @include media-breakpoint-up(md) {
                margin-top: pixelToRem(30);
            }

            @include media-breakpoint-up(xl) {
                margin-top: pixelToRem(48);
            }
        }
    }

    &__button {
        @include button-reset();
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        text-align: left;

        &-title {
            @include h4;

            @include media-breakpoint-between(md, lg) {
                font-weight: 500;
            }

            @include media-breakpoint-up(xl) {
                font-size: pixelToRem(23) !important;
                line-height: pixelToRem(32) !important;
                letter-spacing: pixelToRem(0.58) !important;
            }
        }

        &-icon {
            transition: transform 0.5s ease;
            font-size: pixelToRem(25);
            margin-left: pixelToRem(38);
            color: $gray;
            height: auto;

            @include media-breakpoint-up(md) {
                font-size: pixelToRem(36);
            }
        }

        &:not(.collapsed) {
            .#{$block}__button-icon {
                transform: rotate(45deg);
                transition: transform 0.5s ease;
            }
        }
    }

    &__spacer {
        @include format(text);
        padding-top: pixelToRem(8);
        color: $gray-lighter;
        padding-right: pixelToRem(45);

        & > *:last-child {
            margin-bottom: 0 !important;
        }

        @include media-breakpoint-up(md) {
            color: $gray;
            margin-top: pixelToRem(15);
        }

        @include media-breakpoint-up(xl) {
            padding-top: pixelToRem(8);
        }
    }

    &--career {
        &.#{$block}--bg-gray {
            background: $grey-10;

            @include media-breakpoint-up(md) {
                background: $white;
                padding-top: pixelToRem(20);
                padding-bottom: pixelToRem(20);
            }

            @include media-breakpoint-up(lg) {
                background: $grey-10;
                padding-top: pixelToRem(80) !important;
                padding-bottom: pixelToRem(60);
            }

            .container {
                @include media-breakpoint-down(md) {
                    padding: pixelToRem(20) pixelToRem(25) pixelToRem(46);
                    background: $grey-10;
                }

                @include media-breakpoint-down(sm) {
                    padding: pixelToRem(20) pixelToRem(12) pixelToRem(30);
                    background: $grey-10;
                }
            }
        }

        &:not(.#{$block}--bg-gray) {
            @include module-spacing();
            background: $white;
        }

        .offset-xl-2 {
            margin-left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include media-breakpoint-up(lg) {
                @include make-col-auto();
                width: 100%;
            }
        }

        .#{$block}__headline {
            width: 100%;
            font-weight: 700;
            font-size: pixelToRem(24);
            line-height: pixelToRem(40);
            letter-spacing: pixelToRem(1.2);
            color: $grey-90;

            @include media-breakpoint-down(sm) {
                line-height: pixelToRem(30);
            }

            @include media-breakpoint-up(md) {
                margin-bottom: pixelToRem(20);
            }
        }

        .#{$block}__text {
            @include text();
            width: 100%;
            color: $grey-90;
            font-size: pixelToRem(16);
            line-height: pixelToRem(40);
            letter-spacing: pixelToRem(1.2);
        }

        .#{$block}__items {
            margin-top: pixelToRem(16);

            @include media-breakpoint-up(md) {
                margin-top: pixelToRem(20);
                width: 75%;
            }

            @include media-breakpoint-up(lg) {
                margin-top: pixelToRem(40);
            }
        }

        .#{$block}__button-title,
        p {
            @include text();
            color: $grey-90;
            font-weight: 400;
            font-size: pixelToRem(16) !important;
            line-height: pixelToRem(32);
            letter-spacing: pixelToRem(0.575);

            @include media-breakpoint-up(lg) {
                font-size: pixelToRem(20) !important;
            }
        }

        p {
            color: $grey;
            line-height: pixelToRem(24);
            letter-spacing: pixelToRem(0.4);
            font-size: pixelToRem(16) !important;
        }

        .#{$block}__button {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .#{$block}__button-icon {
            font-size: pixelToRem(25);

            @include media-breakpoint-up(md) {
                font-size: pixelToRem(30);
            }
        }
    }
}
