$block: 'footer';

.#{$block} {
	background-color: $background-grey;
	overflow: hidden;

	&__wrapper-cols {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;

		@include media-breakpoint-up(md) {
			flex-direction: row;
			justify-content: space-between;

			&:after {
				@include full-width;
				position: absolute;
				bottom: 0;
				border: 1px solid white;
				content: ' ';
			}
		}

		@include media-breakpoint-up(xl) {
			padding-right: pixelToRem(55);

			&:after {
				content: none;
			}
		}
	}

	&__upperSpacing {
		padding-top: pixelToRem(32);

		@include media-breakpoint-up(md) {
			padding-top: pixelToRem(40);
		}
	}

	&__col {
		margin-bottom: 1rem;

		@include media-breakpoint-up(md) {
			margin-bottom: pixelToRem(24);
		}

		&-title {
			@include h6;
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: pixelToRem(4) 0;
			border-bottom: 1px solid $light-gray;
			text-transform: uppercase;
			cursor: pointer;

			@include media-breakpoint-up(md) {
				padding: 0;
				border: none;
				white-space: nowrap;
				cursor: default;
				pointer-events: none;
			}
		}

		&-icon {
			width: 21px;
			height: 21px;
			font-size: 21px;
			transition: transform .25s;
			position: relative;
			display: inline-block;

			@include media-breakpoint-up(md) {
				display: none;
			}
		}

		.collapsed &-icon {
			transform: rotate(180deg);
		}

		&-nav {
			display: flex;
			flex-direction: column;

			@include media-breakpoint-up(md) {
				display: flex !important;
			}

			&-item {
				@include h6;
				margin-top: pixelToRem(12);
				color: $gray;
				text-decoration: none;
				cursor: pointer;

				&:hover {
					color: $orange;
				}

				@include media-breakpoint-up(md) {
					margin-top: pixelToRem(11);
					white-space: nowrap;
				}
			}
		}
	}

	&__contact {
		// position: relative; DS

		@include media-breakpoint-up(xl) {
			padding-top: pixelToRem(40);
			padding-left: pixelToRem(55);
		}

		&-title {
			@include h6;
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: pixelToRem(4) 0;
			text-transform: uppercase;
			cursor: default;

			@include media-breakpoint-up(md) {
				padding: 0;
				margin-top: pixelToRem(23);
			}

			@include media-breakpoint-up(xl) {
				margin-top: 0;
			}
		}

		&-nav {
			display: flex;
			flex-direction: column;
			@include clearfix;

			@include media-breakpoint-between(md, lg) {
				max-height: pixelToRem(84);
				flex-flow: row wrap;
				writing-mode: vertical-lr;
				align-content: space-between;
				width: 100%;
				display: inline-flex;
			}

			@include media-breakpoint-up(md) {
				margin-bottom: pixelToRem(24);
			}

			&-item {
				@include h6;
				margin-top: pixelToRem(12);
				color: $gray;
				text-decoration: none;
				cursor: pointer;
				@include clearfix;

				&:hover {
					color: $orange;
				}

				@include media-breakpoint-up(md) {
					margin-top: pixelToRem(16);
					max-width: pixelToRem(255);
					line-height: pixelToRem(17);
					letter-spacing: 0px;
					writing-mode: horizontal-tb;
				}

				@include media-breakpoint-up(xl) {
					margin-top: pixelToRem(16);
					max-width: none;
					line-height: pixelToRem(20);
					letter-spacing: pixelToRem(0.4);
				}

				&-title {
					margin-right: pixelToRem(8);
					display: block;
					float: left;

					@include media-breakpoint-up(md) {

						&:first-child:last-child {
							margin: 0;
							float: none;
							display: inline;
							// margin-right: pixelToRem(21);
						}
					}

					&+* {
						white-space: nowrap;
						display: block;
						float: left;
					}
				}

				&:last-child {
					margin-bottom: pixelToRem(24);

					@include media-breakpoint-up(md) {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&__general {
		display: flex;
		flex-direction: column-reverse;
		padding-top: pixelToRem(24);
		padding-bottom: pixelToRem(24);
		position: relative;
		@include clearfix;

		@include media-breakpoint-up(xl) {
			display: block;
		}

		&:after,
		&:before {
			@include full-width;
			position: absolute;
			bottom: 0;
			border: 1px solid white;
			content: ' ';
		}

		&:before {
			bottom: auto;
			top: 0;
		}

		&-copyright {
			@include h6;
			color: $gray-lighter;
			float: left;
		}

		&-nav {
			float: right;
			display: flex;
			flex-flow: row wrap;
			margin-bottom: 1rem;

			@include media-breakpoint-up(md) {
				margin-bottom: pixelToRem(8);
			}

			@include media-breakpoint-up(xl) {
				margin-bottom: 0;
			}

			&-item {
				@include h6;
				margin-right: 1rem;
				color: $gray;
				text-decoration: none;
				cursor: pointer;
				font-weight: bold;

				&:hover {
					color: $orange;
				}

				@include media-breakpoint-up(md) {
					font-weight: normal;
				}
			}
		}
	}

	&__award-logos {
		&:after {
			@include full-width;
			position: absolute;
			bottom: 0;
			border: 1px solid white;
			content: ' ';
		}
	}

	&__punchline {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: pixelToRem(24);
		padding-bottom: pixelToRem(24);

		@include media-breakpoint-up(md) {
			padding-top: pixelToRem(40);
			padding-bottom: pixelToRem(40);
		}

		&-title {
			@include h5;
			color: $blue;
			font-weight: 500;
			text-transform: uppercase;
			display: block;

			& em {
				color: $orange;
			}

			@include media-breakpoint-up(md) {
				font-size: pixelToRem(21);
			}
		}
	}

	.to-top {
		display: block;
		position: fixed;
    z-index: 10;
		right: 14%;
		top: 100%;
		visibility: hidden;
		-webkit-transform: translate(50%,50%);
		transform: translate(50%,-13%);

		@include media-breakpoint-up(md) {
			right: 64px;
			transform: translate(-20px, -50%);
		}

		&.show {
			animation-name: showButton;
			animation-iteration-count: 1;
			animation-timing-function: ease-in;
			animation-duration: 0.5s;
			animation-fill-mode: forwards;

			@keyframes showButton {
				0% {
					top: 100%;
					opacity: 0;
					display: none;
					visibility: hidden;
				}

				100% {
					top: 85%;
					opacity: 1;
					display: block;
					visibility: visible;
				}
			}
		}

		&.hide {
			animation-name: hideButton;
			animation-iteration-count: 1;
			animation-timing-function: ease-in;
			animation-duration: 0.5s;
			animation-fill-mode: forwards;

			@keyframes hideButton {
				0% {
					top: 85%;
					opacity: 1;
					display: block;
					visibility: visible;
				}

				100% {
					top: 100%;
					opacity: 0;
					display: none;
					visibility: hidden;
				}
			}
		}

		&__button {
			@include button-reset();
			border-radius: 100px;
			min-width: pixelToRem(40);
			min-height: pixelToRem(40);
			padding: 0.5rem;
			background-color: #d8d8d8;
			color: $dark-gray;
			display: flex;
			align-items: center;

			&:hover {
				@include media-breakpoint-up(lg) {
					.to-top__button-text {
						max-width: 150px;
						transition: max-width 0.25s ease-in;
					}
				}
			}

			i {
				font-size: 2rem;
			}

			&-text {
				font-size: pixelToRem(18);
				max-width: 0;
				overflow: hidden;
				transition: max-width 0.25s ease-in;
				white-space: nowrap;
				box-sizing: border-box;

				// for a margin to the right for animation and
				// a true circle
				&::after {
					content: "";
					display: inline-block;
					width: 1rem;
					height: 1rem;
				}
			}
		}
	}
}
