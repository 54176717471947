$block: 'beratungsmodul-app';

.#{$block} {
	&__bg {
		overflow: hidden;
		padding-top: pixelToRem(40);
		padding-bottom: pixelToRem(40);
		background-color: $blue;
	}

	&__progress {
		counter-reset: progress;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: pixelToRem(50);
		
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			margin-bottom: 0;
		}

		&-item {
			@include h6;
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: pixelToRem(30);
			min-height: pixelToRem(30);
			max-width: pixelToRem(30);
			max-height: pixelToRem(30);
			border-radius: 50%;
			background-color: $green;
			color: white;
			text-align: center;
			vertical-align: middle;
			line-height: pixelToRem(30);
			transition: .5s;

			&::before {
				counter-increment: progress;
				content: ""counter(progress);
			}

			&-spacer {
				display: none;
				width: 100%;
				height: pixelToRem(4);
				background-color: $green;
				margin: 0 pixelToRem(7);
				transition: .5s;
				
				@include media-breakpoint-down(sm) {
					width: pixelToRem(4);
					height: 100%;
					margin: pixelToRem(7) 0;
					min-height: pixelToRem(23);
				}
			}
			
			& + &-spacer {
				display: block;
			}
			
			&--active {
				background-color: $orange;
				color: white;

				& ~ .#{$block}__progress-item,
				& ~ .#{$block}__progress-item-spacer {
					background-color: $grey-20;
					color: $grey-90;
				}
			}
		}
	}
	
	&__slides {
		padding: 0 pixelToRem(38);
		min-height: calc(489px - 160px);
		height: 100%;

		@include media-breakpoint-down(lg) {
			padding: 0;
		}
	}

	&__slide {
		display: none;
		height: 100%; 
		flex-direction: column;

		&:nth-child(1) {
			display: flex;

			& .#{$block}__back-button {
				visibility: hidden;
			}
		}

		// Sortierung geändert, back-button nach oben
		// Zur Übersichtlichkeit hier ausgelagert
		@include media-breakpoint-down(sm) {
			& > *:nth-child(1) {
				order: 2;
			}

			& > *:nth-child(2) {
				order: 3;
			}

			& > *:nth-child(3) {
				order: 1;
			}
		}
	}

	&__header {
		display: block;
		width: 100%;
		// height: 100%;
		margin-bottom: pixelToRem(40);
	}
	
	&__title {
		@include h6;
		color: white;
	}
	
	&__question {
		@include h2;
		color: white;
		font-weight: bold;
		// margin-bottom: pixelToRem(40);
	}

	&__answers {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		// align-items verbuggt, daher statt center lieber einheitlich flex-start
		align-items: flex-start;
		width: 100%;
		flex-grow: 1;

		@include media-breakpoint-down(lg) {
			flex-flow: column nowrap;
			justify-content: flex-start;
		}
	}

	&__answer {
		@include button-reset;
		@include font;
		color: $font-color;
		font-size: pixelToRem(16);
		line-height: pixelToRem(24);
		letter-spacing: pixelToRem(0.4);
		background-color: white;
		border-radius: pixelToRem(7);
		padding: pixelToRem(13) pixelToRem(10) pixelToRem(11) pixelToRem(10);
		margin-bottom: 1rem;
		width: 46%;

		@include media-breakpoint-down(lg) {
			width: 75%;
		}

		&:hover {
			background-color: $orange-hover;
			color: white;
		}
		
		&:focus {
			background-color: $orange-focus;
			color: white;
		}
	}

	&__back-button {
		@include button-reset;
		@include h6;
		color: white;
		display: flex;
		justify-content: flex-end;
		
		&:hover {
			color: $orange-hover;
		}

		&:focus {
			color: $orange-focus;
		}

		&::before {
			content: $icon-ico_back;
			@include font-icons();
			margin-right: pixelToRem(16);
			font-size: pixelToRem(30);
			
			@include media-breakpoint-down(sm) {
				font-size: pixelToRem(20);
				margin-right: pixelToRem(8);
			}
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: pixelToRem(12);
		}
	}

	&__result-button {
		margin-top: 10px;
	}

	// &__views {

	// }

	&__view {
		display: none;
	}
}
