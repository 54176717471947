$block: "bild-unterschrift-link";

.#{$block} {
  @include module-spacing;

  &__headline {
    @include h2;
    font-weight: bold!important;
    margin-bottom: pixelToRem(40);
  }

  &__image {
    width: 100%;
  }

  &__button {
    margin-top: pixelToRem(30);
  }
  
  &__text {
    @include format(text);
    font-size: pixelToRem(14)!important;
    margin-top: pixelToRem(13);
  }
 }