$block: "landingpage-shop-section1";

.#{$block} {
    margin-top: pixelToRem(40);

    @include media-breakpoint-up(lg) {
        margin-top: pixelToRem(80);
    }

    &__headline__mobile {
        font-size: pixelToRem(24);
        font-weight: 700;
        color: $grey-90;
        line-height: pixelToRem(32);

        @media screen and (min-width: 950px) {
            display: none;
        }
    }

    &__headline {
        font-size: pixelToRem(24);
        font-weight: 700;
        color: $grey-90;
        line-height: pixelToRem(32);

        margin-bottom: pixelToRem(16);

        display: none;

        @media screen and (min-width: 950px) {
            display: block;
        }

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(30);
            margin-bottom: pixelToRem(24);
        }
    }

    &__text {
        color: $grey-90;
        line-height: pixelToRem(32);

        max-width: 500px;

        @media screen and (min-width: 950px) {
            max-width: initial;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: pixelToRem(16);

        margin: pixelToRem(24);

        @media screen and (min-width: 950px) {
            flex-direction: row;
            margin: pixelToRem(28);
        }

        @include media-breakpoint-up(lg) {
            max-width: 1140px;
            margin: auto;
        }

        @include media-breakpoint-up(xl) {
            max-width: 1230px;
        }
    }


    &__image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 366px;
        min-height: 320px;

        @include media-breakpoint-up(md) {
            min-width: 477px;
        }

        @include media-breakpoint-up(lg) {
            min-width: 550px;
            min-height: 450px;
        }
    }

    &__image {
        position: relative;

        width: 366px;
        height: 320px;

        @include media-breakpoint-up(md) {
            transform: scale(1.3);
        }

        @include media-breakpoint-up(lg) {
            transform: scale(1.5);
        }
    }

    &__card {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        width: 250px;
        height: auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__car-wrapper {
        position: absolute;
        bottom: 20%;
        left: 10%;

        transform: translateX(var(--offset));
    }

    &__car {
        width: 196px;
        height: auto;
    }

    &__wheel {
        animation: rotation 2s infinite linear;
        transform-origin: center;
        transform-box: fill-box;
    }

    &__paused {
        animation-play-state: paused;
    }

    &__reverse {
        animation-direction: reverse;
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    &__polygon1 {
        position: absolute;
        bottom: 10%;
        left: 5%;

        transform: translate(var(--offset-polygon), calc(var(--offset-polygon) * -1));
    }

    &__polygon2 {
        position: absolute;
        top: 8%;
        right: 4%;

        transform: translate(calc(var(--offset-polygon) * -1), calc(var(--offset-polygon)));
    }

    &__cta-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        @media screen and (min-width: 950px) {
            justify-content: flex-start;
        }
    }

    .cta-button {
        display: inline-block;
        margin-top: pixelToRem(20);

        @include media-breakpoint-up(xl) {
            margin-top: pixelToRem(40);
        }
    }
}