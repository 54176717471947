$block: "tile";

.#{$block} {

  &-container {
    @include module-spacing;
  }

  &-container__headline {
    @include format(headline);
    font-weight: bold;
    margin-bottom: pixelToRem(20);
    font-size: pixelToRem(24);
    line-height: 1.3;
    letter-spacing: 0.9px;

    @include media-breakpoint-up(md) {
      font-size: pixelToRem(32);
      line-height: pixelToRem(40);
      letter-spacing: pixelToRem(0.8);
    }

    @include media-breakpoint-up(xl) {
      font-size: pixelToRem(36);
      line-height: pixelToRem(41);
      letter-spacing: pixelToRem(0.9);
    }

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }
  }

  &__col {
    margin-bottom: 24px;
  }

  &-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    &:hover {
      .cta-button--animated {
        &::before {
          width: 100%;
          right: 0;
          transform: scaleX(1);
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding: pixelToRem(24);

      @include media-breakpoint-up(md) {
        width: 100%;
        height: 100%;
      }

      @include media-breakpoint-up(lg) {
        width: 50%;
      }

      @include media-breakpoint-up(xl) {
        padding: pixelToRem(70) pixelToRem(35);
      }

      &--orange {
        background-color: $orange;
        color: white;

        .cta-button {
          color: white;

          &::before {
            background: white;
          }
        }
      }

      &--blue {
        background-color: $blue;
        color: white;

        .cta-button {
          color: white;

          &::before {
            background: white;
          }
        }
      }

      &--grey {
        background-color: $grey-10;
        color: $grey-90;

        .cta-button {
          color: $blue_70;

          &::before {
            background: $blue_70;
          }
        }
      }

      .cta-button {
        margin-top: pixelToRem(16);
        width: fit-content;
      }
    }

    &__headline {
      font-size: pixelToRem(24);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 8px;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;

      @include media-breakpoint-up(md) {
        margin-bottom: pixelToRem(16);
      }
    }

    &__text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
    }

    &__image-wrapper {
      width: 100%;
      height: pixelToRem(180);

      @include media-breakpoint-up(md) {
        width: 100%;
        margin-top: auto;
        min-height: pixelToRem(180);
      }

      @include media-breakpoint-up(lg) {
        width: 50%;
        height: 100%;
      }
    }

    &__image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__col {
    &.col-md-4 {
      .#{$block}-item {

        @include media-breakpoint-up(lg) {
          flex-direction: column;

          &__content {
            width: 100%;
            min-height: pixelToRem(240);
          }

          &__image-wrapper {
            width: 100%;
            height: pixelToRem(180);
          }
        }

        @include media-breakpoint-up(xl) {
          flex-direction: row;

          &__content {
            width: 50%;
            padding: pixelToRem(44) pixelToRem(24);
          }

          &__image-wrapper {
            width: 50%;
            height: 100%;
          }
        }
      }
    }
  }
}