$block: "link-styles";
.#{$block} {
    display: inline-block;
    &__inputDom {
        @include format(text);
    }
    &--animated {
        @include cta-button-animated;
        margin-top: pixelToRem(24);
    }
    &--cta {
        @include cta-button-primary;
        color: white!important;
        margin-top: pixelToRem(24);
    }
    &--download {
        border: none;
        align-items: center;
        position: relative;
        width: auto;
        line-height: 1.5;
        display: inline-block;
        // arrow
        &::after {
            @include font-icons();
            content: $icon-ico_download;
            margin-left: 4px;
            font-size: 18px;
            display: inline-block;
        }
        &:hover {
            color: $blue;
            right: 0;
            transform: scaleX(1);
        }
    }
    &--cta-hollow {
        @include cta-button-hollow;
        margin-top: pixelToRem(24);
    }
}
