$block: 'mega-vorteile-landingpage';

.#{$block} {
	@include module-spacing;

	&__title {
		@include format(headline);
		
		@include media-breakpoint-up(md) {
			font-size: pixelToRem(36)!important;
		}

		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}

	&__facts {
		overflow: hidden;
	}
	
	&__item-title {
		@include format(text);
		font-size: pixelToRem(20)!important;
		margin-bottom: pixelToRem(24);
		border-top: $light-gray 1px solid;
		padding-top: pixelToRem(32);
		display: block;
		
		em {
			@include h2;
			font-size: pixelToRem(32)!important;
			font-weight: bold;
		}
		
		@include media-breakpoint-up(md) {
			@include format(text);
			margin-bottom: pixelToRem(40);
			padding-top: pixelToRem(24);
		}
	}
	
	@include media-breakpoint-up(xl) {
		&__row-inner {
			margin-left: pixelToRem(-28);
			margin-right: pixelToRem(-28);
		}
		
		&__item {
			padding-right: pixelToRem(28);
			padding-left: pixelToRem(28);
		}
	}
	
	&__images {
		margin-bottom: pixelToRem(54);
		padding-top: pixelToRem(36);
		position: relative;
		overflow: hidden;
	}
	
	&__image {
		width: auto;
		height: pixelToRem(125);
		margin-left: auto;
		margin-right: auto;
		display: block;
		
		@include media-breakpoint-up(md) {
			height: pixelToRem(225);
			display: inline;

			&--center {
				display: block;
			}
		}
	}

	&__button {
		display: flex;
        justify-content: center;

		@include media-breakpoint-up(md) {
			justify-content: flex-start;
		}
	}

	&--bg-gray {
		background-color: $background-grey;
	}

}
