$block: "card-container";

.#{$block} {
    padding-bottom: $spacer*7;
    padding-top: $spacer*5;
    overflow: hidden;

    & &__headline {
        @include h2;
        font-weight: 500;
        color: $blue!important;
        margin-bottom: 2rem;
    }

    & .row {
        margin-right: -7.5px;
        margin-left: -7.5px;
    }

    & .col-12,
    & .col-md-6 {
        padding-right: 7.5px;
        padding-left: 7.5px;
    }
}