$block: "landingpage-shop-counter";

.#{$block} {
    margin-top: pixelToRem(40);

    @include media-breakpoint-up(lg) {
        margin-top: pixelToRem(80);
    }

    &__wrapper {
        ul {
            display: grid;
            grid-template-columns: 1fr;
            gap: pixelToRem(40);

            text-align: center;

            li {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            @include media-breakpoint-up(md) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }

    &__icons {
        font-size: pixelToRem(40);

        display: flex;
        gap: pixelToRem(8);

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(60);
        }
    }

    &__value {
        margin-top: pixelToRem(30);

        font-size: pixelToRem(36);
        font-weight: bold;
        line-height: pixelToRem(21);
        text-align: center;

        @include media-breakpoint-up(lg) {
            margin-top: pixelToRem(36);
        }

        @include media-breakpoint-up(xl) {
            margin-top: pixelToRem(36);
            font-size: pixelToRem(48);
        }
    }

    &__label {
        margin-top: pixelToRem(16);

        font-size: pixelToRem(20);
        font-weight: bold;
        line-height: pixelToRem(30);
        color: $grey-60;

        max-width: pixelToRem(115);

        @include media-breakpoint-up(lg) {
            margin-top: pixelToRem(32);
        }
    }

    &__headline {
        font-size: pixelToRem(24);
        font-weight: bold;
        line-height: pixelToRem(32);

        margin-bottom: pixelToRem(40);

        @include media-breakpoint-up(md) {
            text-align: center;
            margin-bottom: pixelToRem(60);
        }

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(30);
        }
    }
}