$block: 'press-releases';

.#{$block} {
	@include module-spacing;

	@include media-breakpoint-down(sm) {
		padding-bottom: 0;
	}

	@include media-breakpoint-up(md) {

		&--bg-gray {
			background: $background-grey-light;
		}
	}

	&__headline-wrapper {

		h2 {
			@include h2;
			font-weight: 700;
			padding-bottom: pixelToRem(8);
			margin-bottom: pixelToRem(30);
			width: 100%;
			border-bottom: pixelToRem(0.5) solid $grey-60;

			@include media-breakpoint-up(md) {
				width: auto;
				padding-bottom: 0;
				margin-bottom: pixelToRem(40);
				border-bottom: none;
			}
		}
	}

	&__items-wrapper {
		padding-bottom: pixelToRem(15);
	}

	&__item {
		&:nth-child(1) {
			padding-bottom: pixelToRem(20);

			@include media-breakpoint-up(md) {
				padding-bottom: pixelToRem(35);
			}
		}

		.news-teaser {
			padding: 0;
		}

		.news-teaser__container {
			height: pixelToRem(150);

			@include media-breakpoint-up(md) {
				height: pixelToRem(220);
			}
		}

		.news-teaser__figure-infobox {
			align-items: center;
			padding: pixelToRem(11) pixelToRem(8) 0;

			@include media-breakpoint-up(md) {
				background: $grey-20;
			}

			.news-teaser__date {
				margin: 0 pixelToRem(16) pixelToRem(11) 0;
				padding: 0;
			}

			.news-teaser__share {
				margin: 0;

				.share__button {
					padding: 0 0 pixelToRem(11);
				}

				.share__overlay {
					left: auto;
					right: 0;
					min-width: 0;
					width: pixelToRem(170);

					@include media-breakpoint-up(sm) {
						left: 0;
						width: pixelToRem(250);
					}

					@include media-breakpoint-between(md, lg) {
						left: -9.5%;
						width: pixelToRem(240);
					}

					@include media-breakpoint-between(lg, xl) {
						width: pixelToRem(200);
					}

					@include media-breakpoint-up(xl) {
						width: pixelToRem(300);
					}
				}
			}
		}

		.news-teaser__title {
			height: auto;
			margin: pixelToRem(16) 0;
			font-weight: 700;
			font-size: 16px;
			line-height: 22px;
			letter-spacing: 0.9px;

			@include media-breakpoint-up(lg) {
				font-weight: 700;
				font-size: 24px;
				line-height: 41px;
				letter-spacing: 0.9px;
			}
		}

		.news-teaser__teaser-text {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
				height: auto;
				margin-bottom: pixelToRem(16);
				font-weight: 400;
				font-size: 16px;
				line-height: 23px;
				letter-spacing: 0.5px;
			}
		}

		.news-teaser__detail-link {
			margin: 0;
		}
	}

	&__detail-link,
	&__cta-show-all {
		font-size: 16px;
		line-height: 24px;
	}

	&__border-bottom {
		margin: pixelToRem(44) pixelToRem(12) 0;
		border-bottom: pixelToRem(0.5) solid $grey-60;
	}
}