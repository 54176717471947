/* BEGIN: Main colors */
$orange: #f18400;
$orange-hover: #f39a2e;
$orange-focus: #c66d00;

// $dark-orange: #c66d00; //Link pressed
$blue: #002b56; // Verwendung für Link
$blue_hover: #2e6b90;
$blue-gradient-from: #195DA2;
$blue-gradient-to: #003E63;
$blue-gradient: radial-gradient(68.22% 68.22% at 0% 0%, $blue-gradient-from 0%, $blue-gradient-to 100%);
$blue_10: #E9F3F9;
$blue_20: #ccdbe4;
$blue_60: #2E72A8;
$blue_70: #195E8F;
$white: #FFFFFF;
$green: #56c85e;
$green_note: #0A8800;
$grey: #666; // Verwendung für Fließtext
$black: #000000;
$gray: $grey;
$devil: $grey;
$grey-10: #fafafa;
$gray-10: $grey-10;
$grey-20: #efefef;
$gray-20: $grey-20;
$grey-30: #dbdbdb;
$gray-30: $grey-30;
$grey-40: #c1c1c1;
$gray-40: $grey-40;
$grey-60: #989898;
$gray-60: $grey-40;
$grey-60-alt: #818181;
$gray-60-alt: $grey-60-alt;
$grey-80: #414141;
$gray-80: $grey-80;
$grey-90: #323232;
$gray-90: $grey-90;
$light-grey: $grey-40; // Verwendung für Hintergrund, Schrift in Footer
$grey-lighter: #8c8c8c; // Verwendung für Text
$gray-lighter: $grey-lighter;
$grey-slider: #bcbcbc;
// $rot: #e44c4d;
// $green: #58be58;
$light-gray: $light-grey;
$dark-grey: $grey-90; // Verwendung für Headline
$dark-gray: $dark-grey;
$light-gray2: $grey-20; // Verwendung für Hintergrund
$light-grey2: $light-gray2; // Verwendung für Hintergrund
$background-grey: #f7f7f7;
$background-grey-light: rgba(239, 239, 239, 0.5);
$background-gray-light: $background-grey-light;
$button-border-color-disabled: $grey-30;
$button-font-color-disabled: $grey-40;
$button-font-color-secondary: $grey;

$error-color: #ed5653;
$card-button-blue: #00497b;
// $dkv-font-dark: #595959;
// $dkv-background-light-grey: #efefef;

$text-emphasized: $orange;

$form-field-text: $blue;
$form-active: $blue;
/* END: Main colors */

/* Add your SCSS variables here */
$em-size: 16;
$rem-size: 16;
$spacer: 1rem;
$font-size-text: 24px; // Default font-size at text
$smaller: 0.8; // Faktor for xs and sm size - 1.0 is same as MD <
$font-color: $dark-grey;
$font-color-highlight: $blue;
$z-index-modal: 100;
$z-index-modal-overlay: 150;
$z-index-modal-wrapper: 200;

/* BEGIN Grid helper */
$grid-switch-width: 30px;
$grid-col-bg-color-odd: rgba(196, 214, 0, .25);
$grid-col-bg-color-even: rgba(196, 214, 0, .35);
$grid-col-bg-color-odd-inner: rgba(67, 78, 90, .25);
$grid-col-bg-color-even-inner: rgba(67, 78, 90, .20);
/* END Grid helper */

/* BEGIN Comfort address input */
$modal-height: 700px;
$modal-width: 900px;
/* END Comfort address input */

/* BEGIN Bootstrap */
$grid-columns: 12;
$grid-gutter-width: 24px;

$grid-breakpoints: (
    xs: 0,
    sm: 414px,
    md: 768px,
    lg: 1024px,
    xl: 1430px,
    bsSM: 576px,
    bsMD: 768px,
    bsLG: 992px,
    bsXL: 1200px,
    bsXXL: 1400px,
);

$container-max-widths: (
    xs: calc(100% - 24px),
    sm: 390px, // = 414 -2*(24px - $grid-gutter-width / 2)
    md: 728px, // = 768 -2*(32px - $grid-gutter-width / 2)
    lg: 984px, // = 1024-2*(32px - $grid-gutter-width / 2)
    xl: 1326px // = 1430-2*(64px - $grid-gutter-width / 2) padding auf jeder Seite
);
/* END Bootstrap */

/* BEGIN FontAwesome */
$fa-font-path: "../assets/fonts/fontawesome";
/* END FontAwesome */

@import 'font-properties';
