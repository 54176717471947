$block: "contact-form";

.#{$block} {
    &__wrapper {
        background-color: $blue;
        padding: 4rem;
    }

    &__inner-wrapper {
        max-width: fit-content;
        margin: auto;
        background-color: $white;
    }

    padding: pixelToRem(24) pixelToRem(16);

    @include media-breakpoint-up(md) {
        padding: pixelToRem(32) pixelToRem(24);
    }

    @include media-breakpoint-up(xl) {
        padding: pixelToRem(48);
    }

    &.#{$block}--bg-gray {
        background-color: $background-grey;

        & .#{$block}__textarea textarea {
            background-color: white;
        }
    }

    &__headline {
        @include h2;
        margin-bottom: pixelToRem(40);
    }

    @mixin contact-form__columns($n) {
        display: grid;
        grid-template-columns: repeat($n, 1fr);

        .contact-form__full-width,
        .mwf-captcha {
            grid-column: auto / span $n;
        }

        column-gap: pixelToRem(24);

        @include media-breakpoint-up(lg) {
            column-gap: pixelToRem(36);
        }
    }

    @include media-breakpoint-up(md) {
        &__columns-2>ul:first-of-type {
            @include contact-form__columns(2);
        }

        &__columns-3>ul:first-of-type {
            @include contact-form__columns(3);
        }

        &__columns-4>ul:first-of-type {
            @include contact-form__columns(4);
        }

        &__span-2 {
            grid-column: auto / span 2;
        }

        &__span-3 {
            grid-column: auto / span 3;
        }

        &__span-4 {
            grid-column: auto / span 4;
        }

    }

    .mwf-captcha {
        .mwf-input input {
            color: white;
            border: 1px solid white;
            border-radius: 0;
        }

        .mwf-input input::placeholder {
            color: white;
            opacity: 1;
        }

        img {
            object-fit: contain;
            background-color: white;

        }
    }

    .mwf-field.#{$block}__title {
        p {
            hyphens: auto;

            margin-top: pixelToRem(32);
            margin-bottom: pixelToRem(16);

            padding: pixelToRem(24) pixelToRem(32);

            font-size: pixelToRem(18);
            font-weight: 700;

            color: rgb(50, 50, 50);
            background-color: rgb(247, 247, 247);

            border-top: 1px solid rgb(219, 219, 219);
        }

    }

    &__form {

        // Style for success text after form-element is gone
        &>div {
            color: $grey-90;
            font-size: 18px;
            line-height: 1.5rem;
            margin-top: 1rem;
        }

        .mwf-space-between-items {
            display: flex;
            justify-content: space-between;
        }

        .mwf-field {

            h2,
            h3 {
                border: 1px solid $form-field-text;
                border-radius: 2px;
                color: $form-field-text;
                margin-bottom: $spacer * 2;
                padding: 10px;
                text-align: center;
            }

            h4 {
                font-size: pixelToRem(16);
                margin-bottom: $spacer;
            }
        }

        .mwf-table {
            line-height: 1.5;
            width: 100%;

            tbody tr td {
                padding: pixelToRem(5);
                word-break: break-word;
            }

            tbody tr:nth-child(odd) {
                background-color: $background-grey;
            }
        }

        .add-margin-bottom {
            margin-bottom: $spacer * 2;
        }
    }

    &__select {
        border-color: $grey-30;

        &>.mwf-input {
            display: flex;
        }

        &>.mwf-label {
            margin-left: 1rem;
            margin-top: $spacer*1.4;
            margin-bottom: $spacer*0.6;
            display: block;
        }

        & & {
            flex: 1 1 100%;
            max-width: none;
            display: flex;
            align-items: center;
            border-radius: 0;
            @include font;
            color: $font-color;
            font-size: pixelToRem(16);
            line-height: pixelToRem(24);
            letter-spacing: pixelToRem(0.4);
            padding: 0 pixelToRem(22);
            justify-content: space-between;
            margin-bottom: 1.4rem;
            height: auto;

            &>.current {
                text-align: center;
                margin: pixelToRem(12) 0;

                @include media-breakpoint-up(lg) {
                    min-width: pixelToRem(20.406);
                }
            }

            & .list {
                width: calc(100% + 2px);
                left: pixelToRem(-1);
                border-radius: 0;
                border: 1px solid $grey-40;
                box-shadow: 0 pixelToRem(10) pixelToRem(20) 0 rgba(128, 128, 128, 0.2);

                & li {
                    padding: pixelToRem(7) pixelToRem(16);
                    width: 100%;
                    text-align: center;
                }
            }

            &::after {
                font-family: 'DKV', 'Courier New', Courier, monospace;
                content: $icon-ico_down;
                border: none;
                height: auto;
                width: auto;
                transform: none;
                transform-origin: center;
                position: static;
                font-size: pixelToRem(20);
                color: $devil;
                margin: 0;
                top: auto;
                right: auto;
                left: auto;
                bottom: auto;
            }

            &.open {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }

        &+& {
            .mwf-label {
                margin-top: 0;
            }
        }
    }

    &__action-link {
        @include font();

        background-color: transparent;
        border: none;
        color: $blue-hover;
        cursor: pointer;
        font-size: pixelToRem(16);
        font-weight: 500;
        margin: pixelToRem(16) 0;
        text-transform: uppercase;
    }

    &__step-title {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    &__subtitle {
        margin-bottom: pixelToRem(32);
    }

    .row {
        &--mb-4 {
            &>*:last-child {
                margin-bottom: pixelToRem(40);
            }
        }

        .mwf-field.mwf-captcha {
            padding: 0 12px;

            .mwf-input>a {
                min-width: 9rem;
            }
        }
    }

    &__title {
        @include h5;
    }

    & &__radio {
        @include format(text);
        color: $dark-gray;
        margin-top: $spacer;
        margin-bottom: $spacer;
        position: relative;

        .mwf-label {
            @include font;
            font-size: pixelToRem(16);
            line-height: pixelToRem(24);
            display: block;
            padding: 0 1rem;
            margin-bottom: pixelToRem(12);

            & * {
                color: $dark-gray;
            }
        }

        &--error {
            .mwf-input fieldset .mwf-option label::before {
                border-color: $error-color;
            }
        }

        .mwf-input {
            margin-left: 1rem;

            fieldset {
                // @include media-breakpoint-up(md) {
                //     margin-left: $spacer;
                // }

                .mwf-option {
                    display: block;
                    float: left;
                    width: 100%;

                    @include media-breakpoint-up(md) {
                        width: 50%;
                    }

                    &:not(:last-of-type) {
                        margin-bottom: pixelToRem(10);
                    }

                    label {
                        height: pixelToRem(24);
                        position: relative;
                        color: $dark-gray;
                        padding-left: $spacer*2;
                        margin-bottom: 0;
                        font-size: pixelToRem(16);
                        line-height: pixelToRem(24);
                        padding-right: pixelToRem(10);

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translate(0, -50%);
                            width: pixelToRem(24);
                            height: pixelToRem(24);
                            border-radius: 50%;
                            border: 1px solid $grey-30;
                            font-size: 0.9rem;
                        }

                        &:hover {
                            &::before {
                                border-color: $devil;
                            }
                        }

                        &::after {
                            position: absolute;
                            top: 50%;
                            left: 4px;
                            transform: translate(0, -50%);
                            width: pixelToRem(16);
                            height: pixelToRem(16);
                            background-color: $form-active;
                            border-radius: 50%;
                            // font-size: 0.9rem;
                        }

                        input {
                            display: none;
                        }

                        &[checked="true"] {
                            &::before {
                                background-color: adjust-color($color: $form-active, $alpha: -0.8);
                                border-color: $form-active;
                            }

                            &::after {
                                content: "";
                            }
                        }
                    }
                }

            }

            @include media-breakpoint-down(sm) {
                &>.mwf-error {
                    padding-left: 0;
                }
            }
        }
    }

    & &__radio--salutation {
        width: 100%;
        color: $dark-gray;

        fieldset {
            margin-bottom: $spacer;
            border: none;

            @include media-breakpoint-up(xl) {
                border-radius: 2px;
                position: relative;
            }

            .mwf-option {
                display: block;
                // border: solid 1px $grey-30;
                // border-radius: 2px;
                margin-bottom: $spacer;
                width: 50%;
                float: left;

                @include media-breakpoint-up(xl) {
                    margin-bottom: 0;
                }

                &:not(:first-of-type) {
                    margin-bottom: 0;

                    @include media-breakpoint-up(xl) {
                        position: absolute;
                        top: 0;
                        left: 50%;
                    }
                }

                input {
                    display: none;
                }

                label {
                    @include font;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    display: flex;
                    height: 30px;
                    justify-content: center;

                    &[checked="true"] {
                        color: $blue;
                        border: 1px solid $blue;
                    }

                    &[checked="false"] {
                        border: solid 1px $grey-30;
                    }
                }
            }
        }
    }

    & &__subtext {
        @include format(caption);
        font-size: pixelToRem(16);
        padding-top: pixelToRem(32);

        & * {
            color: $dark-gray;
        }

        a[href] {
            @include cta-button-animated-no-arrow;
        }

        .text-small {
            @include font;
            font-size: pixelToRem(12);
            line-height: 2;
            letter-spacing: 0.3px;
            color: $grey-lighter;
        }
    }

    & &__input {
        color: $dark-gray;
        position: relative;

        label {
            @include font;
            padding: 0 $spacer;
            position: absolute;
            top: pixelToRem(16);
            transition: all 0.2s ease-out;
            font-size: pixelToRem(16);
            line-height: pixelToRem(24);
        }

        &--date {
            label {
                display: none;
            }
        }

        .mwf-input {
            margin-bottom: $spacer*1.2;
            padding-top: $spacer*0.8;
            position: relative;

            input {
                @include h6;
                font-size: pixelToRem(16);
                color: $form-field-text;
                padding: 0 $spacer;
                width: 100%;
                max-width: 100%;
                border: none;
                outline: none;
                padding-bottom: pixelToRem(6);
                height: pixelToRem(30); // Fix für IE height == line-height
                // background: transparent;
                background-color: transparent !important;
                border-bottom: 1px solid $grey-30;

                &+.mwf-error {
                    border-top: 1px solid $error-color;
                    margin-top: -1px;
                }

                &:focus {
                    border-bottom-color: $form-field-text;

                    &+.mwf-error {
                        margin-top: -2px;
                        border-top-width: 2px;
                    }
                }
            }
        }
    }

    & &__textarea {
        color: $dark-gray;


        .mwf-label {
            @include font;
            margin-left: $spacer;
            display: none;
        }

        textarea {
            @include font;
            height: pixelToRem(96);
            box-sizing: border-box;
            background-color: transparent;
            color: $form-field-text;
            width: 100%;
            outline: none;
            border-color: $grey-30;
            border-radius: pixelToRem(4);
            padding: $spacer*0.7;
            font-size: 16px;
            line-height: 1;
            max-width: 100%;
            min-width: 100%;
            min-height: 146px;
            display: block;

            &:focus {
                border: 2px solid $grey-30;
                margin-bottom: 0;
                margin-top: 0;
            }

            &::placeholder {
                @include font;
                color: $dark-gray;
            }
        }
    }

    & &__checkbox {
        @include format(text);
        color: $dark-gray;
        //margin-top: $spacer;
        margin-bottom: $spacer;
        position: relative;

        .mwf-label {
            @include font;
            display: block;
            font-weight: bold;

            & * {
                color: $dark-gray;
            }
        }

        &--error {
            .mwf-input fieldset .mwf-option label::before {
                border-color: $error-color;
            }
        }

        .mwf-input {

            fieldset {
                margin-top: 20px;

                @include media-breakpoint-up(md) {
                    margin-left: $spacer;
                }

                input {
                    display: none;
                }

                label {
                    @include format(text);
                    display: block;
                    position: relative;
                    width: 100%;
                    cursor: pointer;
                    color: $dark-gray;
                    padding-left: $spacer*2;
                    margin-bottom: $spacer / 2;
                    font-size: pixelToRem(16);
                    line-height: pixelToRem(24);
                    user-select: none;

                    @include media-breakpoint-up(md) {
                        font-size: pixelToRem(16);

                        &::after {
                            top: pixelToRem(2);
                        }
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: pixelToRem(15);
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: pixelToRem(24);
                        height: pixelToRem(24);
                        color: $grey-30;
                        border: 1px solid $grey-30;
                    }

                    &:hover {
                        &::after {
                            border-color: $devil;
                        }
                    }

                    &[checked="true"]::after {
                        content: $icon-ico_check;
                        @include font-icons();
                        color: $form-active;
                        background-color: $white;
                        border-color: $form-active;
                        font-weight: bold;
                        font-size: 1.2rem;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                &>.mwf-error {
                    padding-left: 0;
                }
            }
        }
    }

    & .mwf-captcha {
        color: $dark-grey;
        position: relative;
        margin: 16px 0;

        label {
            @include font;
            display: none;
        }

        &--error {
            .mwf-input a+input {
                border-color: $error-color;
            }
        }

        .mwf-input {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;

            a {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                background-color: #999;
                border-radius: pixelToRem(2);
                margin-bottom: pixelToRem(8);
                flex-basis: 100%;
                margin-bottom: pixelToRem(9);
                overflow: hidden;

                @include media-breakpoint-up(md) {
                    flex-basis: 40%;
                    min-width: 5rem;
                    margin-bottom: 0;
                }

                img {
                    width: 100%;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            input {
                @include h6;
                font-size: pixelToRem(16);
                color: $form-field-text;
                background-color: transparent;
                height: 50px;
                border: 1px solid $grey-30;
                outline: none;
                text-align: center;
                padding: $spacer;
                border-radius: pixelToRem(2);
                min-width: pixelToRem(150);
                flex-basis: 100%;
                margin-bottom: pixelToRem(4);

                @include media-breakpoint-up(md) {
                    flex-basis: 56%;
                    margin-left: 4%;
                    margin-bottom: 0;
                }

                &::placeholder {
                    @include font;
                    font-size: pixelToRem(12);
                    color: $grey-40;
                    line-height: 1;
                    letter-spacing: 0.3px;
                    font-weight: normal;

                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }
                }
            }

            &>.mwf-error {
                padding-left: 0;
                flex-basis: 100%;

                @include media-breakpoint-up(md) {
                    flex-basis: 56%;
                }
            }

            /** INES Captcha Style */
            &>div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // flex-flow: row-reverse;
                flex-wrap: wrap;

                &>p:nth-of-type(1) {
                    // flex: 0.6 0 0%;
                    width: 100%;
                    // padding-left: 1rem;
                    display: block;
                    order: 1;

                    @include media-breakpoint-up(md) {
                        width: 50%;
                    }

                    &>input {
                        width: 100%;
                        margin-left: 0;
                    }
                }

                &>p:nth-of-type(2) {
                    // flex: 0.4 0 0%;
                    width: 100%;
                    display: block;
                    order: 0;
                    padding-bottom: 1rem;

                    @include media-breakpoint-up(md) {
                        width: 45%;
                        padding-bottom: 0;
                    }

                    &>img {
                        max-width: 100%;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    & .mwf-pager,
    & .mwf-button.mwf-cancel {
        display: none;
    }

    & .mwf-button.mwf-next {
        margin-top: pixelToRem(32);
        display: flex;
        flex-direction: row;
        justify-content: center;

        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(24);
        }


        input {
            @include cta-button-primary-small;
            border-radius: 50px;
            font-size: pixelToRem(16) !important;
            line-height: 1.5 !important;
        }
    }

    .contact-form__blue_text_button {
        & .mwf-button.mwf-next {
            @include cta-button-animated;
            display: flex;
            justify-content: flex-end;
            margin-left: auto;
            margin-top: pixelToRem(32);
            padding-right: pixelToRem(12);

            @include media-breakpoint-up(md) {
                margin-top: pixelToRem(24);
            }

            input {
                @include cta-button-animated;
                cursor: pointer;
                background-color: transparent;
                text-transform: uppercase;
            }
        }

        & .mwf-button {
            &.mwf-back {
                cursor: pointer;
                margin-top: pixelToRem(32);
                display: flex;
                justify-content: center;

                @include media-breakpoint-up(md) {
                    margin-top: pixelToRem(24);
                }

                input {
                    @include cta-button-secondary-small;
                    border-radius: 50px;
                    cursor: pointer;
                    font-size: pixelToRem(16) !important;
                    line-height: 1.5 !important;
                }
            }
        }
    }

    .mwf-error {
        @include error;

        position: relative;
        padding-left: 1rem;
        pointer-events: none;
    }

    &__confirmation {
        @include format(text);
        display: none;
    }

    &--bg-blue {
        background-color: $blue;

        .contact-form__headline {
            color: $white;
        }

        p {
            color: $white;
        }

        // Style for success text after form-element is gone
        .contact-form__form>div {
            color: $white;
        }

        .contact-form__checkbox .mwf-required {
            color: $white;
        }

        .contact-form__subtext {

            a[href],
            a[href]:hover {
                color: $grey-60;
            }
        }

        .contact-form__radio {
            .mwf-label {
                .mwf-required {
                    color: $white;
                }
            }

            .mwf-input {
                fieldset {
                    .mwf-option {
                        label {
                            color: $white;
                        }

                        label[checked="true"]::after {
                            background-color: $white;
                        }
                    }
                }
            }
        }

        .contact-form__radio--salutation {
            fieldset {
                .mwf-option {
                    label[checked="true"] {
                        background-color: $white;
                    }

                    label[checked="false"] {
                        color: $white;
                    }
                }
            }
        }

        .contact-form__checkbox {
            .mwf-input {
                fieldset {
                    label {
                        color: $white;
                    }
                }
            }
        }

        .contact-form--ines {
            background-color: $white;
        }

        .contact-form__input {
            .mwf-input {
                input {
                    color: white;
                }
            }
        }

        .mwf-captcha {
            .mwf-input input {
                color: white;
            }
        }

        .contact-form__textarea {
            textarea {
                color: white;
            }

            input::placeholder,
            textarea::placeholder {
                color: white;
            }
        }

        .text-teaser__title--h2,
        .text-teaser__text {
            color: white;
        }

        .contact-form__subtext {
            p {
                color: white;
            }
        }

        .mwf-label {
            color: white;
        }
    }

}