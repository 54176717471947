$block: 'quick-links';

.#{$block} {
	// @include media-breakpoint-up(xs) {
	// 	padding-bottom: 450px;
	// }

	@include media-breakpoint-up(md) {
		position: fixed;
		right: 0;
		top: 50%;
		transform: translate(0, -50%);
		// override container
		max-width: initial;
		width: auto;
		padding: 0;

		&--closed {
			.#{$block} {

				&__link {
					padding-right: pixelToRem(10);
					width: pixelToRem(56);
					transition: width 0.5s ease-out;

					&-icon {
						margin-bottom: 0;
						height: 100%;
						transition: height 0.5s ease-in;
					}

					&-title {
						opacity: 0;
						left: 100%;
					}

					&-arrow {
						opacity: 0;
						right: -100%;
					}
				}
			}
		}
	}

	&__opener {
		height: 100%;
		width: 100%;
	}

	&__link {
		padding: pixelToRem(10) 0;
		display: block;
		text-decoration: none;

		@include media-breakpoint-up(md) {
			display: flex;
			flex-direction: column;
			position: relative;
			height: pixelToRem(56);
			min-width: pixelToRem(56);
			border: solid 1px $orange;
			background-color: white;
			padding: pixelToRem(8);
			padding-right: 2rem;
			margin-bottom: pixelToRem(4);

			width: pixelToRem(226);
			transition: width 0.5s ease-out;
		}

		&-icon {
			display: none;
			
			&-image {
				display: block;
				position: absolute;
				top: 50%;
				max-height: 100%;
				max-width: 100%;
				height: 26px;
				width: 26px;
				transform: translate(20%, -50%);

				&--hover {
					display: none;
				}
			}

			@include media-breakpoint-up(md) {
				display: block;
				position: relative;
				height: 60%;
				transition: height 0.5s ease-out;
			}
		}

		&-title {
			@include font;
			color: $gray;
			font-size: pixelToRem(20);
			letter-spacing: 0.64px;

			@include media-breakpoint-up(md) {
				position: absolute;
				left: 4px;
				top: 24px;
				bottom: 0;
				color: $orange;
				font-size: pixelToRem(13);
				line-height: 1;
				opacity: 1;
				width: 100%;
				transition: opacity 0.5s ease, left 0.5s ease;
				margin: pixelToRem(10);
				white-space: nowrap;
			}
		}

		&-arrow {
			display: none;
			color: $orange;
			position: absolute;
			top: 50%;
			right: 8px;
			transform: translateY(-50%);
			font-size: pixelToRem(20);
			opacity: 1;
			transition: opacity 0.5s ease, right 0.5s ease;


			@include media-breakpoint-up(md) {
				display: block;
			}
		}

		@include media-breakpoint-up(md) {
			&:hover {
				background-color: $orange;
	
				.#{$block}__link-icon-image {
					display: none;
	
					&--hover {
						display: block;
					}
				}
	
				.#{$block}__link-title {
					color: white;
				}
	
				.#{$block}__link-arrow {
					color: white;
				}
	
			}
		}

	}
}