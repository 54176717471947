$block: 'mautinformationen';

.#{$block} {

    &--bg-gray {
        background-color: $background-grey;
    }

    &__container {
        @include module-spacing;
    }

    &__title {
        @include format(headline);
        font-weight: bold !important;
        margin-bottom: pixelToRem(40);
    }

    &__close {
        display: none;
        font-family: math;
        float: none;
        font-size: 29px;
        color: $grey-90;
        text-shadow: 0px;
        opacity: 1;
        border: none !important;
        background: transparent;
        position: absolute;
        right: 4px;
        top: 9px;

        &:hover {
            color: $black;
            cursor: pointer;
        }

        &:focus {
            outline: 0;
        }

        @include media-breakpoint-up(lg) {
            display: none;

            &--standalone {
                display: none !important;
            }
        }
    }

    &__info-content {
        overflow: auto;
        padding-top: pixelToRem(53);

        @include media-breakpoint-up(lg) {
            overflow: visible;
        }

        &--standalone {
            padding-top: pixelToRem(0) !important;
        }
    }

    &__region-title {
        font-weight: bold !important;
        font-size: pixelToRem(16);
        line-height: 1.5;
        letter-spacing: 0.4px;
        color: $grey;
        padding-bottom: pixelToRem(47);
    }

    &__content {
        margin-left: 0px !important;
        width: 100% !important;
        font-size: pixelToRem(16);
        line-height: 1.5;
        letter-spacing: 0.4px;
        text-align: left;
        color: $grey !important;

        // Styling for table within a text-teaser
        .text-teaser {
            padding: 0;
            margin: 0;

            &__container{
                margin: 0;
                padding: 0;
                max-width: 100%;
                .row, .col-12.col-xl-8.offset-xl-2 {
                    margin: 0;
                    padding: 0;
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }
            table {
                font-size: pixelToRem(16);
                border-collapse: collapse;
                border-style: hidden;
                border-bottom-style: initial;
                margin-left: 0;
                margin-right: 0;
                width: auto;

                tbody tr:first-child td {
                    background-color: transparent;
                    font-weight: normal;
                    border: 1px solid #979797;
                    color: $grey;
                    padding: pixelToRem(8);
                    padding-right: 25px;
                }
            }

            td {
                color: $grey;
                border: 1px solid #979797;
                padding: pixelToRem(8);

                &[colspan]:not([colspan="1"]) {
                    text-align: center;
                }
            }
        }

        img {
            position: relative;
        }

        tr:first-of-type {
            height: pixelToRem(80);
        }
    }

}