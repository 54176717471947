$block: "hero__ebene-drei";

.#{$block} {
  position: relative;
  
  picture {
    display: block;
    width: 100%;
    height: pixelToRem(240);

    @include media-breakpoint-up(bsXL) {
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    @include media-breakpoint-up(bsXL) {
      grid-template-columns: 1fr 1fr;
      gap: pixelToRem(112);
      min-height: 350px;
    }
  }

  &__content {
    padding: pixelToRem(24) 0 pixelToRem(8) 0;

    @include media-breakpoint-up(md) {
      padding: pixelToRem(40) 0 pixelToRem(24) 0;
    }

    @include media-breakpoint-up(lg) {
      padding: pixelToRem(32) 0 pixelToRem(16) 0;
      margin: 0 auto;
    }

    &__headline {
      @include h1;
      font-size: pixelToRem(24);
      line-height: pixelToRem(30);
      font-weight: 700;
      font-style: normal;

      @include media-breakpoint-up(md) {
        font-size: pixelToRem(24);
        line-height: pixelToRem(30);
      }

      @include media-breakpoint-up(lg) {
        font-size: pixelToRem(24);
        line-height: pixelToRem(30);
      }

      @include media-breakpoint-up(bsXL) {
        font-size: pixelToRem(24);
        line-height: pixelToRem(30);
      }

      &--big {
        @include media-breakpoint-up(md) {
          font-size: pixelToRem(32);
          line-height: pixelToRem(40);
          letter-spacing: pixelToRem(1);
        }

        @include media-breakpoint-up(xl) {
          font-size: pixelToRem(32);
          line-height: pixelToRem(40);
          letter-spacing: pixelToRem(1.2);
        }
      }

      u {
        text-decoration: underline
      }
      
      b {
        font-weight: 1000
      }



      i {
        font-style: italic;
      }
    }

    &__text {
      line-height: 1.5;
      margin-top: pixelToRem(16);
      
      u {
        text-decoration: underline
      }
      
      b {
        font-weight: 800
      }

      em{
        font-weight: 300;
      }
      
      i {
        font-style: italic;
      }
    }

    &__cta-container {
      margin-top: pixelToRem(24);
      flex-wrap: wrap;
      display: flex;

      &__button-container {
    
        @include media-breakpoint-up(md) {
          padding-right: pixelToRem(16);
        }
        margin-bottom: pixelToRem(24);

        a {
          margin-top: 0;
        }
      }

      &__link-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        
        margin-bottom: pixelToRem(24);
        row-gap: pixelToRem(16);

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }

        a {
          margin-top: 0;
          margin-right: pixelToRem(12+16);
          text-decoration: none;
          display: inline-flex;
          align-items: center;
          align-self: center;
          line-height: pixelToRem(24);
          font-size: pixelToRem(16);
          &::after {
            @include font-icons();
            content: $icon-ico_chevron-right;
            margin-right: -17px;
            font-weight: bold;
          }
          &.default-link {
            font-weight: 500;
          }
        }
      }
    }
  }

  &--background-blue {
    background: $blue;

    .#{$block}__content {

      &__headline {
        color: $white;

        em{
          font-weight: 400;
        }
      }

      &__text {
        color: $white;

        em{
          font-weight: 300;
        }

      }

      &__cta-container {

        &__link-container {

          a {
            color: $white;

            &:hover {
              background-image: linear-gradient(transparent calc(100% - 1px), $white 1px);
              background-repeat: no-repeat;
              background-size: calc(100%) 1px;
              background-position: 0% 100%;
            }
          }
        }
      }
    }
  }

  &--background-gray {
    background: $grey-10;

    .#{$block}__content {

      &__headline {
        color: $grey-90;

        em{
          color: #8c8c8c;
          font-weight: 400;
        }
       
      }
      &__text {
        em{
          color: #8c8c8c;
          font-weight: 300;
        }

      }

       &__cta-container {

         &__link-container {

           a {
            &.default-link {
              color: $blue;
        
              &:visited {
                color: $blue;
              }
            }
      //       color: #195E8F;

      //       &:hover {
      //         background-image: linear-gradient(transparent calc(100% - 1px), #195E8F 1px);
      //         background-repeat: no-repeat;
      //         background-size: calc(100%) 1px;
      //         background-position: 0% 100%;
      //       }
           }
             }
           }
    }
  }
}