$block: 'landingpage-shop-testimonials';

.#{$block} {
    margin-top: pixelToRem(40);

    @include media-breakpoint-up(lg) {
        margin-top: pixelToRem(80);
    }

    em {
        font-weight: bold;
    }

    &__wrapper-mobile {
        margin-left: pixelToRem(16);

        @include media-breakpoint-up(md) {
            margin-left: pixelToRem(24);
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__logo-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: pixelToRem(16);
    }

    &__logo {
        width: 128px;
        height: auto;

        img {
            width: 100%;
            height: auto;
        }

        margin-bottom: pixelToRem(24);
    }

    &__avatar {
        height: 72px;
        width: 72px;
        border-radius: 100%;
        overflow: clip;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__carousel-item {
        width: 100%;
        max-width: 287px;
        height: 400px;
        border: 0.5px solid $grey-60;

        padding: pixelToRem(16) pixelToRem(12) pixelToRem(40) pixelToRem(12);
        margin-left: pixelToRem(8);
        margin-right: pixelToRem(8);
    }

    &__item-wrapper {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;

    }

    &__text {
        line-height: pixelToRem(24);
    }

    &__author-name {
        font-weight: bold;
        line-height: pixelToRem(20);
    }

    &__author-role {
        font-size: pixelToRem(12);
        line-height: pixelToRem(20);
        margin-top: pixelToRem(4);
    }

    &__footer-border {
        width: 70px;
        border-top: 3px solid $blue_hover;
        margin-bottom: pixelToRem(12);
    }

    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: pixelToRem(32);
        margin-top: pixelToRem(10);
    }

    &__use-dots &__pageInfo {
        display: none;
    }

    &__dots {
        display: none;
    }

    &__use-dots &__dots {
        display: block;

        width: fit-content;
        margin: 0;

        .slick-dots {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            width: fit-content;
            margin: 0;

            & button {
                @include button-reset();
                color: transparent;
                border: 1px solid $grey-60;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin: $spacer*0.5;
            }

            & .slick-active {
                button {
                    background-color: $grey-60;
                }
            }
        }
    }

    &__pageInfo {
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    &__currentPage {
        align-self: flex-start;
        font-weight: bold;
    }

    &__totalPages {
        align-self: flex-end;
    }

    &__slash {
        height: 22px;
        border-left: 1px solid $grey-90;
        transform: rotate(30deg);
    }

    &__arrow {
        width: min-content !important;
        margin: 0;

        color: $grey-60;

        &--prev {
            margin-top: pixelToRem(6);
        }

        &--next {
            margin-top: pixelToRem(4);
        }
    }

    &__arrow-button {
        @include button-reset();
        font-size: pixelToRem(25);
    }

    .slick-disabled {
        visibility: hidden;
    }

    &__wrapper {
        @include media-breakpoint-down(md) {
            display: none;
        }

        width: 100%;
        max-width: 1200px;

        margin-left: auto;
        margin-right: auto;
        padding-left: pixelToRem(32);
        padding-right: pixelToRem(32);

        .#{$block} {
            &__text {
                font-size: pixelToRem(20);
                line-height: pixelToRem(33);
            }

            &__author-name {
                font-size: pixelToRem(20);
                font-weight: bold;
                line-height: pixelToRem(25);
            }

            &__author-role {
                font-size: pixelToRem(16);
                line-height: pixelToRem(25);
                margin-top: pixelToRem(4);
            }

            &__logo {
                width: auto;
                height: 64px;

                img {
                    width: auto;
                    height: 100%;
                }
            }

            &__avatar {
                height: 88px;
                width: 88px;
                border-radius: 100%;
                overflow: clip;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &__item-wrapper {
                gap: pixelToRem(32);
            }
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: max-content;
        gap: 48px;
    }

    &__grid-item {
        padding: pixelToRem(24) pixelToRem(28);
        border: 0.5px solid $grey-60;
        min-height: 400px;

        @include media-breakpoint-up(xl) {
            padding: pixelToRem(24) pixelToRem(42) pixelToRem(32) pixelToRem(42);
        }
    }
}