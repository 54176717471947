$block: 'price-compare';

.#{$block} {
	@include module-spacing;

  @include media-breakpoint-up(xl) {
    margin: 0 auto;
    width: pixelToRem(1200);
  }
  > .container {
    @include media-breakpoint-up(xl) {
      padding-left: 0;
      padding-right: 0;
    }
  }

	&__intro {
    margin: pixelToRem(12) 0 pixelToRem(24);
	}
  &__headline {
    @include h2;
    margin-bottom: 12px;
    font-size: pixelToRem(24);
    line-height: pixelToRem(44);
    font-weight: 700;
    color: $grey-90;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
      font-size: pixelToRem(32);
      line-height: pixelToRem(44);
    }
  }
  &__subline {
    @include headline;
    font-size: pixelToRem(16);
    line-height: pixelToRem(28);
    font-weight: 400;
    color: $grey-90;
    text-align: center;
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
    box-sizing: border-box;
    margin: 0 0 pixelToRem(24);
    height: pixelToRem(38);
    border: pixelToRem(1) solid $grey-30;
    border-radius: pixelToRem(19);

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  &__tab {
    $_tab: &;

    flex: 1;
    margin: pixelToRem(6) pixelToRem(8) 0 0;

    &:first-child {
      margin-left: pixelToRem(8);
    }

    &-btn {
      display: inline-block;
      padding: 0 pixelToRem(16);
      width: 100%;
      height: pixelToRem(24);
      @include headline;
      font-size: pixelToRem(16);
      line-height: pixelToRem(24);
      font-weight: 400;
      color: $grey-90;
      text-decoration: none;
      text-align: center;
      background: $white;
      border-radius: pixelToRem(12);

      #{$_tab}[data-active="true"] & {
        color: $white;
        background: $blue;
      }
    }
  }

  &__plans {
    margin: 0;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
      align-content: center;
    }
  }
  &__plan {
    padding: 0;
    border: pixelToRem(1) solid $grey-30;
    border-radius: pixelToRem(5);

    @include media-breakpoint-down(md) {
      display: none;
    }
    &[data-active="true"] {
      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    @include media-breakpoint-up(lg) {
      flex: 1;
      align-self: stretch;
      padding-bottom: pixelToRem(96);
      max-width: pixelToRem(386);

      &:not(:first-child) {
        margin-left: pixelToRem(20);
      }
    }
    @include media-breakpoint-down(md) {
      position: relative;
      padding: 0 0 pixelToRem(12);
    }
  }
  &__plan-intro {
    position: relative;
    margin-bottom: pixelToRem(12);
    padding: pixelToRem(16);
    background: $grey-10;

    @include media-breakpoint-up(lg) {
      padding: pixelToRem(32) pixelToRem(16) pixelToRem(24);
    }

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: pixelToRem(-5);
      display: inline-block;
      margin: 0 pixelToRem(20);
      width: calc(100% - (#{pixelToRem(20)} * 2));
      height: pixelToRem(5);
      background: var(--color);
      content: '';

      @include media-breakpoint-up(lg) {
        margin: 0 pixelToRem(40);
        width: calc(100% - (#{pixelToRem(40)} * 2));
      }
    }
  }
  &__plan-headline {
    @include h3;
    font-size: pixelToRem(24) !important;
    line-height: pixelToRem(36) !important;
    font-weight: 700;
    color: $grey-90;
    text-transform: uppercase;
    text-align: center;
  }
  &__plan-subline {
    @include headline;
    font-size: pixelToRem(14);
    line-height: pixelToRem(18);
    font-weight: 700;
    color: $grey-60-alt;
    text-align: center;
  }

  &__price-box {
    padding: pixelToRem(16);

    @include media-breakpoint-up(lg) {
      padding: pixelToRem(16) pixelToRem(20);
    }
  }
  &__price {
    @include headline;
    font-size: pixelToRem(24);
    line-height: pixelToRem(28);
    font-weight: 700;
    color: $grey-90;
    text-align: center;

    &--month {
      font-size: pixelToRem(14);
    }
  }
  &__price-subline {
    @include headline;
    font-size: pixelToRem(14);
    line-height: pixelToRem(24);
    font-weight: 400;
    color: $grey-60-alt;
    text-align: center;
  }

  &__content-show-cta {
    position: relative;
    display: inline-block;
    margin: 0 pixelToRem(16);
    padding: 0;
    width: calc(100% - (#{pixelToRem(16)} * 2));
    @include headline;
    font-size: pixelToRem(16);
    line-height: pixelToRem(32);
    font-weight: 400;
    color: $grey-90;
    text-align: left;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;

    &:after {
      position: absolute;
      right: 0;
      top: pixelToRem(12);
      display: inline-block;
      width: pixelToRem(14);
      height: pixelToRem(8);
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.159721 0.608471C0.190289 0.577479 0.225951 0.551653 0.266708 0.530992C0.317654 0.510331 0.3686 0.5 0.419547 0.5C0.460304 0.5 0.50106 0.510331 0.541817 0.530992C0.592763 0.551653 0.63352 0.577479 0.664088 0.608471L7.05273 7.10124L13.4414 0.608471C13.4719 0.577479 13.5076 0.551653 13.5484 0.530992C13.5993 0.510331 13.6452 0.5 13.6859 0.5C13.7369 0.5 13.7827 0.510331 13.8235 0.530992C13.8744 0.551653 13.9152 0.577479 13.9457 0.608471C13.9763 0.649793 14.0018 0.691116 14.0222 0.732438C14.0425 0.77376 14.0527 0.820248 14.0527 0.871901C14.0527 0.923554 14.0425 0.970041 14.0222 1.01136C14.0018 1.05269 13.9763 1.09401 13.9457 1.13533L7.31256 7.89153C7.2718 7.92252 7.23105 7.94835 7.19029 7.96901C7.14953 7.98967 7.10368 8 7.05273 8C7.00179 8 6.95594 7.98967 6.91518 7.96901C6.87442 7.94835 6.83367 7.92252 6.79291 7.89153L0.159721 1.13533C0.129154 1.10434 0.103681 1.06818 0.0833021 1.02686C0.0629236 0.975207 0.0527344 0.923554 0.0527344 0.871901C0.0527344 0.820248 0.0629236 0.77376 0.0833021 0.732438C0.103681 0.691116 0.129154 0.649793 0.159721 0.608471Z' fill='%23323232'/%3E%3C/svg%3E%0A");
      background-size: cover;
      background-repeat: no-repeat;
      content: '';
    }

    &[data-active="true"] {
      &:after {
        transform: rotate(180deg);
      }
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__plan-content {
    @include media-breakpoint-down(md) {
      display: none;
    }
    &[data-active="true"] {
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
  }
  &__plan-recommended-for {
    padding: pixelToRem(24) pixelToRem(16);
    @include headline;
    font-size: pixelToRem(16);
    line-height: pixelToRem(24);
    font-weight: 400;
    color: $grey-90;
    border-bottom: pixelToRem(1) solid $grey-30;

    @include media-breakpoint-up(lg) {
      padding: pixelToRem(24) pixelToRem(20);
    }

    b {
      font-weight: 700;
    }
  }
  &__plan-details {
    margin-bottom: pixelToRem(16);
    padding: pixelToRem(24) pixelToRem(16);
    border-bottom: pixelToRem(1) solid $grey-30;

    @include media-breakpoint-up(lg) {
      padding: pixelToRem(24) pixelToRem(20);
    }
  }
  &__plan-detail {
    @include headline;
    font-size: pixelToRem(14);
    line-height: pixelToRem(21);
    font-weight: 400;
    color: $grey-90;

    &:not(:first-child) {
      margin-top: pixelToRem(4);
    }

    b {
      font-weight: 700;
    }
  }
  &__plan-feature-headline {
    @include headline;
    font-size: pixelToRem(14);
    line-height: pixelToRem(24);
    font-weight: 500;
    color: $grey-90;
    margin: 0 pixelToRem(16) pixelToRem(8) pixelToRem(48);

    @include media-breakpoint-up(lg) {
      margin: 0 pixelToRem(20) pixelToRem(16) pixelToRem(48);
    }
  }
  &__plan-features {
    /* list-style-type: ''; */
    margin: 0 pixelToRem(16) 0 pixelToRem(24);
  }
  &__plan-feature {
    position: relative;
    padding-left: pixelToRem(24);
    @include headline;
    font-size: pixelToRem(16);
    line-height: pixelToRem(24);
    font-weight: 400;
    color: $grey-90;

    &:before {
      position: absolute;
      left: 0;
      top: pixelToRem(5);
      display: inline-block;
      width: pixelToRem(12);
      height: pixelToRem(12);
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3557 0.576816C13.3985 0.607542 13.4305 0.64851 13.4519 0.699721C13.484 0.750931 13.5 0.802142 13.5 0.853352C13.5 0.904562 13.4893 0.950652 13.4679 0.99162C13.4573 1.02235 13.4412 1.05307 13.4199 1.0838L5.03637 11.3617C5.00432 11.4027 4.96157 11.4385 4.90814 11.4693C4.85471 11.4898 4.80127 11.5 4.74784 11.5C4.69441 11.5 4.64632 11.4949 4.60358 11.4846C4.57152 11.4642 4.53946 11.4437 4.5074 11.4232C4.49671 11.4129 4.48603 11.4027 4.47534 11.3925C4.46465 11.3822 4.45397 11.372 4.44328 11.3617L0.612207 7.2905C0.580148 7.24953 0.553432 7.20857 0.532059 7.1676C0.510686 7.12663 0.5 7.08054 0.5 7.02933C0.5 6.92691 0.537402 6.83985 0.612207 6.76816C0.687012 6.69646 0.777846 6.66061 0.88471 6.66061C0.938142 6.66061 0.991574 6.67598 1.04501 6.7067C1.09844 6.72719 1.14118 6.75791 1.17324 6.79888L4.71578 10.5936L12.8268 0.638268C12.8588 0.5973 12.9016 0.566574 12.955 0.546089C13.0084 0.515363 13.0619 0.5 13.1153 0.5C13.158 0.5 13.2008 0.510242 13.2435 0.530726C13.2863 0.540968 13.3237 0.556331 13.3557 0.576816Z' fill='%23323232'/%3E%3C/svg%3E%0A");
      background-size: cover;
      background-repeat: no-repeat;
      content: '';
    }

    &:not(:first-child) {
      margin-top: pixelToRem(8);

      @include media-breakpoint-up(lg) {
        margin-top: pixelToRem(16);
      }
    }
  }

  &__content-hide-cta {
    display: inline-block;
    margin: 0 pixelToRem(16);
    padding: 0;
    width: calc(100% - (#{pixelToRem(16)} * 2));
    @include headline;
    font-size: pixelToRem(16);
    line-height: pixelToRem(32);
    font-weight: 400;
    color: $grey-90;
    text-align: center;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;

    &:before {
      display: inline-block;
      margin-right: pixelToRem(10);
      width: pixelToRem(12);
      height: pixelToRem(12);
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8906 0.609375C11.9219 0.640625 11.9479 0.682292 11.9688 0.734375C11.9896 0.776042 12 0.817708 12 0.859375C12 0.911458 11.9896 0.963542 11.9688 1.01562C11.9479 1.05729 11.9219 1.09375 11.8906 1.125L6.51562 6.5L11.8906 11.875C11.9219 11.9062 11.9479 11.9479 11.9688 12C11.9896 12.0417 12 12.0885 12 12.1406C12 12.2344 11.9635 12.3177 11.8906 12.3906C11.8177 12.4635 11.7344 12.5 11.6406 12.5C11.5885 12.5 11.5365 12.4896 11.4844 12.4688C11.4427 12.4479 11.4062 12.4219 11.375 12.3906L6 7.01562L0.625 12.3906C0.59375 12.4219 0.552083 12.4479 0.5 12.4688C0.458333 12.4896 0.411458 12.5 0.359375 12.5C0.265625 12.5 0.182292 12.4635 0.109375 12.3906C0.0364583 12.3177 0 12.2344 0 12.1406C0 12.0885 0.0104167 12.0417 0.03125 12C0.0520833 11.9479 0.078125 11.9062 0.109375 11.875L5.48438 6.5L0.109375 1.125C0.078125 1.09375 0.0520833 1.05729 0.03125 1.01562C0.0104167 0.963542 0 0.911458 0 0.859375C0 0.765625 0.0364583 0.682292 0.109375 0.609375C0.182292 0.536458 0.265625 0.5 0.359375 0.5C0.411458 0.5 0.458333 0.510417 0.5 0.53125C0.552083 0.552083 0.59375 0.578125 0.625 0.609375L6 5.98438L11.375 0.609375C11.4062 0.578125 11.4427 0.552083 11.4844 0.53125C11.5365 0.510417 11.5885 0.5 11.6406 0.5C11.6823 0.5 11.724 0.510417 11.7656 0.53125C11.8177 0.552083 11.8594 0.578125 11.8906 0.609375Z' fill='%23323232'/%3E%3C/svg%3E%0A");
      background-size: cover;
      background-repeat: no-repeat;
      content: '';
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
    &[data-active="true"] {
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .cta-button {
    width: calc(100% - (#{pixelToRem(16)} * 2));
    text-align: center;

    @include media-breakpoint-down(md) {
      margin: pixelToRem(24) pixelToRem(16);
    }
    @include media-breakpoint-up(lg) {
      position: absolute;
      left: pixelToRem(20);
      bottom: pixelToRem(32);
      width: calc(100% - (#{pixelToRem(20)} * 2));
    }
  }
}
