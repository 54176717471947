$block: 'breadcrumb';

.#{$block} {
    margin-bottom: pixelToRem(-40);

    @include media-breakpoint-up(md) {
        margin-bottom: pixelToRem(-56);
    }

    &__content {
        margin-top: pixelToRem(8);
        margin-bottom: pixelToRem(8);

        @include media-breakpoint-up(md) {
            margin-top: pixelToRem(16);
            margin-bottom: pixelToRem(16);
        }
    }

    &__link {
        @include h6;
        color: $grey-40;
        font-weight: normal;
        text-decoration: none;

        &:not(:last-of-type) {
            margin-right: pixelToRem(8);
        }

        &--active {
            font-weight: bold;
            color: $blue;
        }
    }

    &--diesel-prices {

        @include media-breakpoint-down(sm) {
            display: none;
        }

        .#{$block}__content {
            margin-bottom: pixelToRem(24);
        }
    }
}
