$block: "text-eingabefeld-link";

.#{$block} {
  @include module-spacing;

  &__headline {
    @include format(headline);
    font-weight: bold!important;
    margin-bottom: pixelToRem(17);
  }

  &__text {
    @include format(text);
  }

  &__form-group {
    margin-bottom: pixelToRem(84);
    padding-top: pixelToRem(39);
  }

  &__select {
    height: pixelToRem(44);
    padding-top: pixelToRem(13);
    font-size: pixelToRem(16);
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: none;
  }
  
  &__item {
    margin-bottom: pixelToRem(24);
    
    &:last-child {
      margin-bottom: pixelToRem(0);
    }
  }
  

  &--bg-gray {
    background-color: $background-grey;
  }

  // OVERWRITE ".nice-select" DEFAULT STYLING
  .nice-select:after {
    border-bottom: 2px solid $grey-90;
    border-right: 2px solid $grey-90;
    height: 10px;
    right: 15px;
    top: 45%;
    width: 10px;
  }

  .nice-select .option {
    cursor: pointer;
    line-height: 30px;
    min-height: 30px;
  }

  .nice-select .list {
    width: 100%;
  }

}
