$block: 'error_404'; 

.#{$block} {
    &__headline {
      @include text;
      // font-family: FrutigerNextLT;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: $grey-90;
      padding-top: 40px;
    }

    &__image {
        max-width: 100%;
    }

    &__button {
      text-align: center;
      padding-top: 64px;
      padding-bottom: 64px;
    }
}
