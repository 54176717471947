$block: "career-kacheln";

.#{$block} {
  overflow: hidden;
  @include module-spacing();

  .container-lg {
    padding: 0;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  &__tile {
    position: relative;

    @include media-breakpoint-down(xs) {
      .container {
        max-width: 100%;
      }
    }

    @include media-breakpoint-up(md) {
      .row {
        margin: 0;

        &:hover img:not(.#{$block}__tile__siegel) {
          transform: scale(1.1);
        }

      }
    }

    &:not(:last-child) {
      margin-bottom: pixelToRem(20);

      @include media-breakpoint-up(lg) {
        margin-bottom: pixelToRem(38);
      }
    }

    &__content {
      padding-top: pixelToRem(40);
      padding-bottom: pixelToRem(40);

      @include media-breakpoint-down(sm) {
        padding-right: #{$grid-gutter-width};
        padding-left: #{$grid-gutter-width};
      }

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: pixelToRem(20) pixelToRem(24) pixelToRem(35) pixelToRem(24);
        box-shadow: 0 0 pixelToRem(20) rgba(0, 0, 0, 0.07);
      }

      h3 {
        @include h2;
        margin-bottom: pixelToRem(17) !important;
        font-weight: 700;

        @include media-breakpoint-up(lg) {
          margin-bottom: pixelToRem(9) !important;
        }
      }

      p {
        @include font;
        color: $font-color;
        font-size: pixelToRem(16);
        letter-spacing: pixelToRem(0.4);
        line-height: 1.9;
      }

      .cta-button {
        margin-top: pixelToRem(20);
      }
    }

    &__image {

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        padding: 0;
      }

      &__wrapper {
        width: calc(100% + #{$grid-gutter-width});
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
        overflow: hidden;

        @include media-breakpoint-up(md) {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          max-width: 100%;
          width: 100%;
          left: 0;
          transform: translateX(0);

          a {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
          }
        }
      }

      picture {
        overflow: hidden;
        min-height: 100%;
        height: pixelToRem(320);

        @include media-breakpoint-up(md) {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          position: absolute;
          top: 50%;
          left:50%;
          transform: translate(-50%,-50%);
          width: 100%;
        }

        @include media-breakpoint-up(lg) {
          width: auto;
        }

        img {
          width: 100%;
          display: block;
          transition: transform .6s ease-in-out;

          @include media-breakpoint-up(md) {
            min-height: 100%;
            height: pixelToRem(320);
            flex: 1 1 auto;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        picture img {
          height: 100%;
          width: auto;
        }
      }
    }

    &__siegel {
      position: absolute;
      z-index: 2;
      left: 0;
      bottom: 0;
      height: 70%;

      @include media-breakpoint-up(md) {
        height: 40%;
      }

      @include media-breakpoint-up(lg) {
        left: pixelToRem(-25);
        bottom: 0;
      }
    }

    &:first-child {
      .#{$block}__tile__content {
        padding-top: 0;
      }
    }

    &:nth-child(2) {
      text-align: center;

      .#{$block}__tile__content {
        background-color: $orange;
      }

      h3,
      p {
        color: #ffffff;
      }
    }

    &:nth-child(3) {

      .#{$block}__tile__content {
        background-color: $blue;
      }

      h3,
      p {
        color: #ffffff;
      }
    }

    @include media-breakpoint-up(md) {
      &:first-child {
        .#{$block}__tile__content {
          background-color: #ffffff;
          padding-top: pixelToRem(20);
          padding-right: pixelToRem(40);
        }
      }
      &:nth-child(2) {
        .#{$block}__tile__image {
          height: pixelToRem(320);
        }
        .#{$block}__tile__content {
          align-items: center;
          background-color: $orange;
        }
      }

      &:nth-child(3) {
        .#{$block}__tile__image {
          order: 1;
        }

        .#{$block}__tile__content {
          order: 2;
          background-color: $blue;
          padding-left: pixelToRem(40);
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        background-color: transparent;
      }
    }

    @include media-breakpoint-up(lg) {

      &:first-child {
        margin-top: pixelToRem(70);
        flex: 0 0 65%;
        max-width: 65%;

        .#{$block}__tile__content {
          padding: pixelToRem(50) pixelToRem(105) pixelToRem(50) pixelToRem(35);
          flex: 0 0 calc(50% + #{pixelToRem(60)});
          max-width: calc(50% + #{pixelToRem(60)});
          min-height: pixelToRem(370);
        }

        .#{$block}__tile__image {
          flex: 0 0 calc(50% - #{pixelToRem(70)});
          max-width: calc(50% - #{pixelToRem(70)});
          margin-left: pixelToRem(-60);

          &__wrapper {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            max-height: 85%;
          }
        }
      }

      &:nth-child(2) {
        flex: 0 0 35%;
        max-width: 35%;

        .#{$block}__tile__image {
          order: 1;
          flex: 0 0 100%;
          max-width: 100%;
          min-height: pixelToRem(443);

          &__wrapper {
            max-height:  pixelToRem(443);
          }

          picture {
            width: 100%;
            overflow: hidden;
          }
        }

        .#{$block}__tile__content {
          flex: 0 0  pixelToRem(312);
          max-width:  pixelToRem(312);
          padding:  pixelToRem(50)  pixelToRem(35)  pixelToRem(50)  pixelToRem(35);
          order: 2;
          position: absolute;
          bottom:  pixelToRem(-130);
          right:  pixelToRem(90);
          z-index: 1;
          min-height:  pixelToRem(250);

          h3 {
            font-size:  pixelToRem(24);
            line-height: 1.2;
          }
        }
      }

      &:nth-child(3) {
        position: relative;
        right:  pixelToRem(128);
        flex: 0 0 80%;
        max-width: 80%;

        .#{$block}__tile__image {
          flex: 0 0 calc(50%);
          max-width: calc(50%);
          margin-right:  pixelToRem(-130);

          &__wrapper {
            max-height:  pixelToRem(390);
            z-index: 1;
          }
        }

        .#{$block}__tile__content {
          margin-top: pixelToRem(130);
          padding: pixelToRem(50) pixelToRem(35) pixelToRem(50) pixelToRem(170);
          min-height: pixelToRem(334);
          flex: 0 0 calc(50% + #{pixelToRem(130)});
          max-width: calc(50% + #{pixelToRem(130)});
        }
      }
    }

    @include media-breakpoint-up(xl) {
      &:first-child {
        flex: 0 0 70%;
        max-width: 70%;
      }

      &:nth-child(2) {
        flex: 0 0 30%;
        max-width: 30%;

        .#{$block}__tile__content {
          right: pixelToRem(140);
        }
      }
    }
  }

  &--bg-blue-light {
    background-color: rgba($blue_10, 0.5);

    .#{$block}__item {
      background-color: $white;
    }
  }

}
