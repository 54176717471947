﻿$block: 'page-divider';

.#{$block} {
  @include module-spacing;

  &__bar {
    border: none;
    border-top: 2px solid $grey-60;
    border-radius: 5px;
  }
}
