$block: 'blog-teaser-list';

.#{$block} {
  @include module-spacing;

  &--bg-blue {
    background: #F4F9FC;
  }

  &--bg-white {
    background: $white;
  }

  &__items-wrapper {
    margin-bottom: pixelToRem(40);

    @include media-breakpoint-up(xl) {
      margin-bottom: pixelToRem(80);
    }
  }

  &__data {
    overflow: hidden;
  }

  &__item {
    position: relative;
    padding-bottom: pixelToRem(20);
    animation: blog-teaser-list-fade-in 2s;

    @include media-breakpoint-down(md) {
      &:nth-child(2) {
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: pixelToRem(40);

      &:nth-child(3),
      &:nth-child(4) {
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-up(xl) {

      &:nth-child(1),
      &:nth-child(3) {
        padding-right: pixelToRem(29);
      }

      &:nth-child(2),
      &:nth-child(4) {
        padding-left: pixelToRem(29);
      }
    }

    .#{$block}__label {
      display: block;
      width: fit-content;
      color: $white;
      padding: pixelToRem(9) pixelToRem(20);
      font-weight: 400;
      font-size: pixelToRem(16);
      line-height: pixelToRem(24);

      @include media-breakpoint-up(xl) {
        display: none;
      }

      &--desktop {
        position: relative;
        z-index: 2;
        margin-bottom: pixelToRem(-42);
        margin-left: pixelToRem(61.2);
      }
    }

    .#{$block}__card {
      height: pixelToRem(274);
      border: pixelToRem(1) solid $grey-20;

      @media screen and (max-width: pixelToRem(349)) {
        height: pixelToRem(295);
      }

      @include media-breakpoint-up(xl) {
        position: relative;
        height: calc(#{pixelToRem(452)} / 2 + #{pixelToRem(71)});
        margin-top: calc(#{pixelToRem(-452)} / 2);
        max-width: pixelToRem(308);
        z-index: 3;
      }

      @keyframes blog-teaser-list-fade-in {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

    .#{$block}__card-inner {
      height: calc(#{pixelToRem(274)} - #{pixelToRem(42)});
      padding: pixelToRem(24);

      @media screen and (max-width: pixelToRem(349)) {
        height: calc(#{pixelToRem(295)} - #{pixelToRem(42)});
        ;
      }

      @include media-breakpoint-up(xl) {
        height: 100%;
      }

      &__headline {
        font-weight: 700;
        font-size: pixelToRem(20);
        line-height: pixelToRem(29);
        letter-spacing: pixelToRem(0.9);
        margin-bottom: pixelToRem(16);

        @include media-breakpoint-up(lg) {
          font-size: pixelToRem(24);
        }
      }

      &__text {
        font-weight: 400;
        font-size: pixelToRem(16);
        line-height: pixelToRem(16);
        letter-spacing: pixelToRem(0.5);
        margin-bottom: pixelToRem(12);

        @include media-breakpoint-up(lg) {
          line-height: pixelToRem(23);
          margin-bottom: pixelToRem(19);
        }
      }

      .#{$block}__cta-show-all {
        width: fit-content;
      }
    }

    .#{$block}__image {
      overflow: hidden;
      max-width: 100%;
      height: pixelToRem(196.17);

      @include media-breakpoint-up(md) {
        height: pixelToRem(265.5);
      }

      @include media-breakpoint-up(xl) {
        padding-left: pixelToRem(61.2);
        height: pixelToRem(452);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__cta-show-all {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &__ui-wrapper {

    .#{$block}__cta-show-all {
      padding-top: pixelToRem(40);
    }
  }
}