﻿$block: 'mobiles-bezahlen';

.#{$block} {
  @include module-spacing;

  &__headline {
    @include h2;
    padding-bottom: pixelToRem(56);
    font-weight: bold;
  }

  &__wrapper {
    color: white;
  }

  &__left-col {
    background-color: $blue;
    height: 655px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 40px;

    .#{$block}__top {
      padding-top: pixelToRem(60);
      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: left;
        padding-left: pixelToRem(80);
      }
    }

    .#{$block}__bot {
      position: relative;
      bottom: -124px;
      width: 90%;
      padding-left: 22px;

      @include media-breakpoint-up(sm) {
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 61px;
        padding-left: 50px;
        width: 90%;
      }
    }

    .#{$block}__counter {
      @include text;
      font-size: 100px;
      font-weight: bold;
      margin-bottom: 8px;
      margin-left: 0;

      @include media-breakpoint-up(md) {
        margin-left: -100px;
      }

      @include media-breakpoint-up(lg) {
        margin-left: -30px;
      }
    }

    .#{$block}__title {
      @include text;
      font-size: 45px;
      font-weight: 300;

      margin-left: 0;

      @include media-breakpoint-up(md) {
        margin-left: -40px;
      }

      @include media-breakpoint-up(lg) {
        margin-left: -30px;
      }

    }

    .#{$block}__image {
      left: 0;
      top: 48px;

      @include media-breakpoint-up(md) {
        left: 20px;
      }

      @include media-breakpoint-up(lg) {
        left: 0;
      }

    }

    .#{$block}__text {
      @include text;
      font-size: 35px;
      font-weight: 300;
      line-height: 1.11;
    }

    @include media-breakpoint-up(md) {
      padding-left: 0;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 30px;
    }


  }

  &__right-col {
    background-color: $orange;
    height: 655px;

    padding-left: 30px;
    padding-right: 30px;

    .#{$block}__top {
      padding-top: pixelToRem(74);
      text-align: center;
      margin-left: 0px;

      @include media-breakpoint-up(lg) {
        text-align: left;
        margin-left: 10px;
      }

    }

    .#{$block}__bot {
      position: absolute;
      bottom: 56px;
      padding-left: 22px;
      padding-right: 22px;
      width: 90%;

      @include media-breakpoint-up(sm) {
        width: 91%;
      }

      @include media-breakpoint-up(md) {
        padding-left: 10px;
        font-size: 24px;
      }

    }

    .#{$block}__counter {
      @include text;
      font-size: 70px;
      font-weight: bold;

      @include media-breakpoint-up(md) {
        font-size: 64px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 70px;
      }
    }

    .#{$block}__title {
      @include text;
      padding-top: pixelToRem(8);
      font-size: 45px;
      font-weight: 300;

      @include media-breakpoint-up(md) {
        font-size: 38px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 45px;
      }
    }

    .#{$block}__image {
      top: 77px;

      @include media-breakpoint-up(lg) {
        top: 64px;
      }
    }

    .#{$block}__text {
      @include text;
      font-size: 28px;
      font-weight: 300;
      line-height: 1.11;
      width: 90%;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        width: 100%;
      }

      @include media-breakpoint-up(lg) {
        font-size: 31.5px;
      }
    }
  }

  &__image {
    position: relative;
    top: 55px;
    height: 175px;
  }

  &--bg-gray {
    background-color: $background-grey;
  }
}