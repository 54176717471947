$block: 'interactive-contact';

.#{$block} {
    &--bg-blue {
        background-color: $blue;
    }
    
    @include module-spacing;

    &__headline {
        @include format(headline);

        &--h2 {
            color: white !important;
            margin-bottom: pixelToRem(16);
            font-weight: bold;
        }


        @include media-breakpoint-up(md) {
            &--h2 {
                margin-top: pixelToRem(46);
                color: white;
                font-weight: bold;
            }

        }

        @include media-breakpoint-up(xl) {
            &--h2 {
                margin-top: pixelToRem(40);
                color: white;
                font-weight: bold;
            }
        }
    }

    &__text {
        @include format(introtext);   
        color: white;
        margin-bottom: pixelToRem(65);
       
        @include media-breakpoint-up(md) {
            font-size: pixelToRem(20)!important;
        }

    }

    &__interaction {
        @include format(text);
        display: none;
        color: white;
        word-break: break-word;

        @include media-breakpoint-up(md) {
            display: block;
        }

        &-item {
            display: none;
        }
    }

    &__form {
        background-color: white;
    }
}