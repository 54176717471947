$block: 'more-topics-teaser';

.#{$block} {
  @include module-spacing();

  &__border {
    border-top: 1px solid #BCBCBC;
    padding-top: pixelToRem(40);

    @include media-breakpoint-down(md) {
      padding: pixelToRem(40) pixelToRem(25) 0 pixelToRem(25);
    }

    @include media-breakpoint-down(sm) {
      padding: pixelToRem(40) 0 0;
    }
  }

  &__container {
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      width: 75%;
    }
  }

  h4,
  &__title {
    font-size:  pixelToRem(24);
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: pixelToRem(25);
    letter-spacing: pixelToRem(1.2);
  }

  h4 {
    text-align: left;
    margin-bottom: pixelToRem(40);

    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }

  &__title {
    @include media-breakpoint-down(md) {
      font-size:  pixelToRem(20);
      margin-bottom: pixelToRem(15);
    }
  }


  .row {
    & > div:not(:last-child) {

      @include media-breakpoint-up(md) {
        border-right: 1px solid #BCBCBC;
      }

      .#{$block}__content {
        @include media-breakpoint-up(lg) {
          padding-right: pixelToRem(10);
        }
        @include media-breakpoint-up(xl) {
          padding-right: pixelToRem(60);
        }
      }

    }

    & > div:not(:first-child) {
      .#{$block}__content {
        @include media-breakpoint-up(lg) {
          padding-left: pixelToRem(10);
        }
        @include media-breakpoint-up(xl) {
          padding-left: pixelToRem(60);
        }
      }
    }
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      height: auto;
      margin-bottom: pixelToRem(30);
    }
  }

  &__link {
    margin-top: auto;
  }
} 
