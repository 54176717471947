$block: 'blog-teaser';

.#{$block} {
    &__grid, &__slider-desktop &__grid {
        display: none;
    }

    &__slider-desktop &__slider {
        display: initial;
    }

    @include media-breakpoint-up(lg) {
        &__grid {
            display: initial;
        }

        &__slider {
            display: none;
        }
    }
}
