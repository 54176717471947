$block: "card-item";

.#{$block} {
    margin-bottom: $spacer;

    &__content {
        padding: $spacer*2.5 $spacer*2.5 $spacer*2.5 $spacer*2.5;
        height: 100%;

        @include media-breakpoint-up(md) {
            padding: $spacer*2 $spacer*2 $spacer*2 $spacer*2;
        }

        @include media-breakpoint-up(lg) {
            padding: $spacer*4 $spacer*2.5 $spacer*5;
        }


        @include media-breakpoint-up(xl) {}
    }

    &__text,
    &__subtext,
    & .count,
    & .count-addition {
        @include format(text);
    }

    &__subtext {

        @include media-breakpoint-up(lg) {
            min-height: 3.5rem;
        }

        p {
            margin-bottom: 0
        }
    }

    & .count,
    & .count-addition {
        font-weight: 500;
        font-size: pixelToRem(50);
        line-height: 0.78;
        display: inline;

        @include media-breakpoint-up(lg) {
            font-size: pixelToRem(64);
        }
    }

    & .count-addition {
        margin-right: 7px;
    }

    &__text * {
        @include font-medium;
        font-size: ($font-size-text*0.75) *$smaller;
        line-height: 1.4;
        letter-spacing: 0.7px;
        margin-bottom: 0;
        font-weight: 500;

        @include media-breakpoint-up(md) {
            font-size: pixelToRem(18);
            line-height: pixelToRem(24);
        }

        @include media-breakpoint-up(lg) {
            font-size: $font-size-text*0.75;
        }
    }

    &__subtext {
        margin-top: $spacer*0.75;

        & * {
            font-size: pixelToRem(18);
            line-height: pixelToRem(26);
            letter-spacing: pixelToRem(0.8);

            @include media-breakpoint-up(md) {
                font-size: pixelToRem(18);
                line-height: pixelToRem(28);
            }

            @include media-breakpoint-up(xl) {
                font-size: pixelToRem(28);
                line-height: pixelToRem(34);
                letter-spacing: pixelToRem(0.8);
            }
        }
    }

    &--orange {
        .#{$block}__content {
            background-color: $orange;

            .#{$block}__text,
            .#{$block}__subtext {
                & * {
                    color: white;
                }
            }

            .count,
            .count-addition {
                color: white;
            }
        }
    }

    &--blue {
        .#{$block}__content {
            background-color: $blue;

            .#{$block}__text,
            .#{$block}__subtext {
                & * {
                    color: white;
                }
            }

            .count,
            .count-addition {
                color: white;
            }
        }
    }

    &--light-blue {
        .#{$block}__content {
            background-color: #ccdbe4;

            .#{$block}__text,
            .#{$block}__subtext {
                & * {
                    color: $blue;
                }
            }

            .count,
            .count-addition {
                color: $blue;
            }
        }
    }

    &__icon {
        width: 100px;
        height: 100px;

        @include media-breakpoint-up (md) {
            width: 125px;
            height: 125px;
        }

        @include media-breakpoint-up (lg) {
            width: 150px;
            height: 150px;
        }

        @include media-breakpoint-up (xl) {
            margin: $spacer*2 0;
            width: 150px;
            height: 150px;
        }

        &-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: $spacer;

            svg {
                width: 125px;
                height: 125px;

                @include media-breakpoint-up (lg) {
                    width: 150px;
                    height: 150px;
                }
            }
        }

        & svg {
            width: 100%;
            height: auto;
        }
    }

    // Style for moving icon to the right side
    &:not(.col-md-6) {
        @include media-breakpoint-up(md) {
            position: relative;
        }

        .#{$block}__icon-container {
            @include media-breakpoint-up(md) {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                left: 50%;
            }
        }

        .#{$block}__text,
        .#{$block}__subtext,
        .count,
        .count-addition {
            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }

        .#{$block}__text {
            margin-top: $spacer;

            @include media-breakpoint-up(md) {
                margin-top: $spacer*2;
            }
        }
    }
}