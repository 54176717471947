$block: 'fleet';

.#{$block} {
    @include module-spacing;

    &__headline {
        @include h2;
        margin-bottom: pixelToRem(61);
        font-weight: bold;
        color: $dark-gray;
    }

    &__items {
        margin: 0;

        @include media-breakpoint-up(xl) {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            row-gap: pixelToRem(64);
        }
    }

    &__item {
        padding: pixelToRem(34.25) 0 pixelToRem(40);
        border-top: pixelToRem(0.5) solid $grey-60;

        @include media-breakpoint-up(xl) {
            width: percentage(1 / 3);
            min-height: pixelToRem(300);
            padding: 0 pixelToRem(40);
            border-top: none;
            border-left: pixelToRem(0.5) solid $grey-60;
        }

        &:first-child {
            border: none;
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__item-icon-wrapper {
        max-width: pixelToRem(55);
        margin-right: pixelToRem(18);
        height: pixelToRem(32);

        img {
            object-fit: contain;
        }
    }

    &__item-title {
        @include h3;
        margin: pixelToRem(27.5) 0 pixelToRem(16);
    }

    &__item-text {
        @include text;
        letter-spacing: pixelToRem(0.4);
        line-height: pixelToRem(24);
        margin: 0 0 pixelToRem(20);
    }
}