$block: 'accordion-item-bild-text-link';

.#{$block} {

    &__item {
        & + & {
            margin-top: pixelToRem(16);

            @include media-breakpoint-up(md) {
                margin-top: pixelToRem(30);
            }

            @include media-breakpoint-up(xl) {
                margin-top: pixelToRem(48);
            }
        }
    }

    &__text-full-width {
        @include format(text);
        margin-top: pixelToRem(4);
        margin-bottom: pixelToRem(8);
        color: #717171!important;

        @include media-breakpoint-up(md) {
            margin-bottom: pixelToRem(16);
            margin-top: pixelToRem(15);
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: pixelToRem(24);
            margin-top: pixelToRem(8);
        }
    }

    &__text {
        @include format(text);

        @include media-breakpoint-up(xl) {
            margin-top: pixelToRem(24);
        }

        & *:last-child {
            margin: 0;
        }

        table {
            margin-top: 0px;
            color: #717171;
            position: unset;
            margin-left: 0;
            margin-right: 0;
            width: 0;
            line-height: 1.5;
            letter-spacing: 0.4px;

            tr:nth-child(even) {
                background-color: transparent;
            }

            tr:nth-child(odd) {
                background-color: transparent;
            }
        }
    }

    &__image {
        display: block;
        max-width: 100%;
    }

    &__button {
        margin-top: 1rem;

        @include media-breakpoint-up(xl) {
            margin-top: pixelToRem(16);
        }

        @include media-breakpoint-up(xl) {
            margin-top: pixelToRem(24);
        }
    }
}