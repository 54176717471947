﻿$block: 'kontakt-aufnehmen';

.#{$block} {

  &__wrapper {
    margin-top: -16px;
  }

  &::before {
    content: "";
    position: absolute;
    top: -88px;
    left: 0;
    height: 88px;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    pointer-events: none !important;
    z-index: 999;
    cursor: default !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(1px);
  }

  &--bg-blue {
    background-color: $blue;
  }

  &__container {
    @include module-spacing;
  }

  // LEFT COLUMN CONTENTS

  &__row {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: pixelToRem(26);

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: pixelToRem(26);
    }

    @include media-breakpoint-up(lg) {
      grid-auto-rows: 1fr;
      grid-column-gap: pixelToRem(18);
      grid-template-columns: repeat(4, 1fr);

    }

    @include media-breakpoint-up(xl) {
      grid-auto-rows: 0fr;
      grid-template-columns: repeat(1, 1fr);
      border-right: solid 1px #979797;
    }
  }

  &__col {
    border-bottom: solid 1px #979797;
    padding-top: pixelToRem(26);

    &:first-child {
      padding-top: 0px;
    }

    @include media-breakpoint-up(md) {
      &:nth-child(-n+2) {
        padding-top: 0px;
      }
    }

    @include media-breakpoint-up(lg) {
      &:nth-child(-n+4) {
        padding-top: 0px;
        padding-right: pixelToRem(17);
        border-bottom: none;
        border-right: solid 1px #979797;
      }

      &:last-child {
        padding-right: 0px;
        border-right: none;
      }
    }

    @include media-breakpoint-up(xl) {
      padding-left: 26px;
      margin-right: pixelToRem(69);

      &:nth-child(-n+4) {
        border-right: none;
        border-bottom: solid 1px #979797;
      }

      &:nth-child(n+2) {
        padding-top: pixelToRem(26);
      }

      &:last-child {
        border-bottom: none;
      }
    }

  }

  &__cardHeadline {
    color: $white;
    font-size: pixelToRem(20);
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.5px;
    padding-bottom: pixelToRem(16);
    padding-top: pixelToRem(26);

    &:first-child {
      padding-top: 0px;
    }
  }

  &__cardText {
    @include text;
    color: $white;
    line-height: 1.5;
    letter-spacing: 0.4px;
    padding-bottom: 10px;
  }

  // RIGHT COLUMN CONTENTS

  &__formwrapper {
    @include media-breakpoint-up(lg) {
      border-top: solid 1px #979797;
      padding-top: pixelToRem(26);
    }

    @include media-breakpoint-up(xl) {
      margin-left: pixelToRem(66);
      border-top: none;
      padding-top: 0px;
    }
  }

  &__headline {
    @include h3;

    &--h3 {
      color: white !important;
      margin-bottom: pixelToRem(16);
      font-weight: bold;
    }


    @include media-breakpoint-up(md) {
      &--h3 {
        margin-top: 0;
        color: white;
        font-weight: bold;
      }

    }

    @include media-breakpoint-up(xl) {
      &--h3 {
        margin-top: pixelToRem(0);
        color: white;
        font-weight: bold;
      }
    }
  }

  &__text {
    @include format(introtext);
    color: white;
    margin-bottom: pixelToRem(65);
    padding-right: pixelToRem(36);

    @include media-breakpoint-up(md) {
      font-size: pixelToRem(20) !important;
    }

  }

  &__interaction {
    @include format(text);
    display: none;
    color: white;
    word-break: break-word;

    @include media-breakpoint-up(md) {
      display: block;
    }

    &-item {
      display: none;
    }
  }

  &__form {
    background-color: white;
  }

  &__rightCol {
    margin-left: 0px;

    @include media-breakpoint-up(xl) {
      margin-left: -26px;
    }
  }

  &__formCol {
    padding: 0px;
    padding-left: 24px;
    right: 12px;

    @include media-breakpoint-up(md) {
      right: 12px;
    }

    @include media-breakpoint-up(xl) {
      right: 0px;
    }
  }

  & .link-styles {
    color: $white!important;

    &--animated {
      &:before {
        background: $white!important;
      }
    }
  }
  
}